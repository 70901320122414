import { useState } from 'react';
import { ActionButton } from '../UIExtensions/Buttons';
import { ConfirmationModal, VerbiageType } from '../Shared/ConfirmationModal';
import { ApplicationEndpoint } from '../../../../api/models';
import { ActionAlert } from '../Shared/ActionAlert';
import { mdiTrashCan } from '@mdi/js';

interface EndpointsDeleteProps {
    applicationId: string;
    data: ApplicationEndpoint;
    disabled: boolean;
    deleteHandler: (applicationId: string, endpointId: string) => Promise<boolean>;
}

export function EndpointsDelete({ applicationId, data, disabled, deleteHandler }: EndpointsDeleteProps) {
    const endpointId = data.id;
    const environment = data.environment;
    const endpoint = data.endpoint;
    const [showDelete, setShowDelete] = useState(false);

    const key = `delete-endpoint-alert-${data.id}`;
    return (
        <>
            <ActionButton
                outlined={false}
                disabled={disabled}
                mdiIcon={mdiTrashCan}
                title={`Delete Endpoint`}
                variant="btn-red"
                onClick={async () => {
                    setShowDelete(true);
                }}
            />
            <ConfirmationModal
                show={showDelete}
                enabled={true}
                title={'Delete Endpoint'}
                verbiageType={VerbiageType.YesNo}
                onCancel={() => setShowDelete(false)}
                onConfirm={async () => {
                    if (await deleteHandler(applicationId, endpointId)) {
                        setShowDelete(false);
                    }
                }}
            >
                <ActionAlert key={key} variant="danger">
                    Are you sure you want to remove&nbsp;<b>{environment}</b>&nbsp;endpoint&nbsp;<b>{endpoint}</b>
                    &nbsp;from application&nbsp;?
                </ActionAlert>
            </ConfirmationModal>
        </>
    );
}
