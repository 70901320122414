import { Table } from 'react-bootstrap';
import { EmptyDataRow } from '../UIExtensions/TableUtilities';
import { ApplicationEndpoint, EnvironmentType } from '../../../../api/models';
import { EndpointsDelete } from './EndpointsDelete';
import { Link } from 'react-router-dom';
import { EndpointsCreateEditModal } from './EndpointsCreateEditModal';

export interface EndpointsTableProps {
    applicationId: string;
    data: ApplicationEndpoint[];
    actionsEnabled: boolean;
    actionsVisible: boolean;
    availableEnvironmentTypes?: Array<EnvironmentType>;
    deleteHandler: (applicationId: string, endpointId: string) => Promise<boolean>;
    updateHandler: (applicationId: string, environment: string, endpoint: string, endpointId?: string) => Promise<boolean>;
}

export const EndpointsTable = ({ applicationId, data, actionsEnabled, actionsVisible, availableEnvironmentTypes, deleteHandler, updateHandler }: EndpointsTableProps) => {
    const columnCount = 3 - (actionsVisible ? 0 : 1);
    return (
        <div className="table-wrapper">
            <Table striped={true} className="table-fixed-header">
                <thead className="thead-white">
                    <tr>
                        <th scope="col" className="col-2">
                            Environment
                        </th>
                        <th scope="col" className="col-9">
                            Endpoint
                        </th>
                        {actionsVisible ? (
                            <th scope="col" className="col-1">
                                Actions
                            </th>
                        ) : (
                            <></>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 ? (
                        data.map(function (endpoint) {
                            const key = `endpoint-${endpoint.id}`;
                            const environment = availableEnvironmentTypes?.find((env) => env.id === endpoint.environment)?.name;
                            return (
                                <tr key={key}>
                                    <td>{environment}</td>
                                    <td>
                                        <Link to={endpoint.endpoint} target="_blank">
                                            {endpoint.endpoint}
                                        </Link>
                                    </td>
                                    {actionsVisible ? (
                                        <td>
                                            <EndpointsCreateEditModal
                                                canPerformActions={actionsEnabled}
                                                applicationId={applicationId}
                                                handler={updateHandler}
                                                applicationEndpoint={endpoint}
                                                availableEnvironmentTypes={availableEnvironmentTypes}
                                            />
                                            <EndpointsDelete disabled={!actionsEnabled} applicationId={applicationId} data={endpoint} deleteHandler={deleteHandler} />
                                        </td>
                                    ) : (
                                        <></>
                                    )}
                                </tr>
                            );
                        })
                    ) : (
                        <EmptyDataRow colSpan={columnCount} />
                    )}
                </tbody>
            </Table>
        </div>
    );
};
