import { useState, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Loading } from '../Shared/Loading';
import { Permission } from '../../../../api/models';
import { Filter } from '../Shared/Filter';
import PermissionsTable from './PermissionsTable';
import PermissionsCreate from './PermissionsCreate';

export interface PermissionsEnhancedTableProps {
    id?: string;
    data: Permission[];
    isLoading: boolean;
    availableRoles?: Array<string>;
    deleteHandler: (id: string, groupId: string, role: string) => Promise<boolean>;
    createHandler: (id: string, groupId: string, role: string) => Promise<boolean>;
}

export function PermissionsEnhancedTable({ id, data, isLoading, availableRoles, deleteHandler, createHandler }: PermissionsEnhancedTableProps) {
    const [searchTerm, setSearchTerm] = useState('');

    const searchedData = useMemo(() => {
        const searchString = searchTerm.trim().toLowerCase();

        if (searchString.length === 0) {
            return data;
        }

        return data.filter((permission: Permission) => {
            return JSON.stringify(permission).toLowerCase().includes(searchString);
        });
    }, [searchTerm, data]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div>
            <Row>
                <Col>{<Filter onUpdate={setSearchTerm} />}</Col>
                <Col className="col-1">
                    <PermissionsCreate id={id!} availableRoles={availableRoles} spinning={false} createHandler={createHandler} />
                </Col>
            </Row>
            {<PermissionsTable id={id!} data={searchedData} availableRoles={availableRoles} deleteHandler={deleteHandler} />}
        </div>
    );
}
