import { PropsWithChildren } from 'react';

export type CheckboxProps = PropsWithChildren<{
    key: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
}>;

export const Checkbox = (props: CheckboxProps) => {
    const key = props.key;
    return (
        <>
            <div key={key}>
                <label htmlFor={key}>
                    <input type="checkbox" checked={props.checked} id={key} onChange={(elem) => props.onChange(elem.target.checked)} />
                    <span style={{ paddingLeft: '10px' }}>{props.children}</span>
                </label>
            </div>
        </>
    );
};
