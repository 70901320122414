import { Card, Container } from 'react-bootstrap';
import BasePage from '../BasePage';
import SystemCreate from './SystemCreate';

export const SystemCreatePage = () => (
    <BasePage name={`Create System`}>
        <Container>
            <Card>
                <Card.Body>
                    <SystemCreate />
                </Card.Body>
            </Card>
        </Container>
    </BasePage>
);
