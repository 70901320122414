export interface EmptyDataRowProps {
    colSpan: number;
    text?: string;
}

export const EmptyDataRow = ({ colSpan, text }: EmptyDataRowProps) => {
    const noData = text ?? 'No data found';
    return (
        <tr>
            <td colSpan={colSpan}>{noData}</td>
        </tr>
    );
};
