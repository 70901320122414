import { Table } from 'react-bootstrap';
import { AppSourceExpanded } from '../../../../api/models';
import { AppSourcesTableRow } from './AppSourcesTableRow';
import { Loading } from '../Shared/Loading';
import { EmptyDataRow } from '../UIExtensions/TableUtilities';

export interface AppSourcesTableProps {
    isLoading: boolean;
    data: AppSourceExpanded[];
}

export const AppSourcesTable = ({ isLoading, data }: AppSourcesTableProps) => {
    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="table-wrapper">
            <Table striped={true} className="table-fixed-header">
                <thead className="thead-white">
                    <tr>
                        <th scope="col" className="col-2">
                            Source
                        </th>
                        <th scope="col" className="col-3">
                            Source Id
                        </th>
                        <th scope="col" className="col-7">
                            Name
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.length > 0 ? (
                        data.map(function (d) {
                            return <AppSourcesTableRow key={'app-source-' + d.id} appSource={d} />;
                        })
                    ) : (
                        <EmptyDataRow colSpan={3} />
                    )}
                </tbody>
            </Table>
        </div>
    );
};
