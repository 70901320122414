import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { ConfirmationModal, VerbiageType } from '../../Shared/ConfirmationModal';
import { ValidatableInput } from '../../Shared/ValidatableInput';
import { AttributeValue } from '../../../../../api/models';
import { nameof } from 'ts-simple-nameof';
import { setField } from '../../../../../helpers/FieldSetter';

interface AttributeValueModalProps {
    attribValue: AttributeValue;
    allValues: AttributeValue[];
    show: boolean;
    apiErrors?: Map<string, string[]> | undefined;
    onHide: () => void;
    onConfirm: (item: AttributeValue) => void;
}
export function AttributeValueModal({ attribValue, allValues, show, apiErrors, onHide, onConfirm }: AttributeValueModalProps) {
    const [attributeValue, setAttributeValue] = useState(attribValue);
    const [fieldsValidity, setFieldsValidity] = useState(new Map<string, boolean>());
    const [isValid, setIsValid] = useState(false);
    const isNew = !attribValue?.id;

    const nameProperty = nameof<AttributeValue>((value) => value.name);
    const valueProperty = nameof<AttributeValue>((value) => value.value);

    const onFieldChanged = function <T>(fieldName: string, value: T) {
        const typeCopy = structuredClone(attributeValue);
        if (setField(typeCopy, fieldName, value)) {
            setAttributeValue(typeCopy);
        }
    };

    const setFieldValidity = function (fieldName: string, valid: boolean) {
        const fieldsValidityCopy = new Map(fieldsValidity);
        fieldsValidityCopy.set(fieldName, valid);
        setFieldsValidity(fieldsValidityCopy);
    };

    useEffect(() => {
        if (!show) {
            setAttributeValue(attribValue);
        }
    }, [show, attribValue]);

    useEffect(() => {
        const valid = Array.from(fieldsValidity.values()).every((valid) => valid);
        setIsValid(valid);
    }, [fieldsValidity]);

    return (
        <>
            <ConfirmationModal
                show={show}
                enabled={isValid}
                title={`${isNew ? 'New' : 'Edit'} Value for ${attributeValue?.type}`}
                verbiageType={VerbiageType.OkCancel}
                onCancel={onHide}
                onConfirm={() => onConfirm(attributeValue!)}
            >
                <Row>
                    <ValidatableInput
                        focus={show}
                        disabled={!isNew}
                        fieldName={'Id'}
                        value={attributeValue?.value ?? ''}
                        placeholder={"Id e.g. 'audit-classification-abc'"}
                        onChange={(value: string) => onFieldChanged(valueProperty, value)}
                        validate={(value: string) => {
                            const errors = [];
                            if (value === undefined || value.length === 0) {
                                errors.push('Id is required.');
                            } else if (isNew && allValues.some((v) => v.value === value)) {
                                errors.push('Id already exists.');
                            }

                            setFieldValidity(valueProperty, errors.length === 0);
                            return errors;
                        }}
                        apiErrors={apiErrors?.get(valueProperty)}
                    />
                    <ValidatableInput
                        fieldName={'Name'}
                        value={attributeValue?.name ?? ''}
                        placeholder={"Name e.g. 'Audit Classification ABC'"}
                        onChange={(value: string) => onFieldChanged(nameProperty, value)}
                        validate={(value: string) => {
                            const errors = [];
                            if (value === undefined || value.length === 0) {
                                errors.push('Name is required.');
                            } else if (allValues.some((v) => v.id !== attribValue?.id && v.name === value)) {
                                errors.push('Name already exists.');
                            }

                            setFieldValidity(nameProperty, errors.length === 0);
                            return errors;
                        }}
                        apiErrors={apiErrors?.get(nameProperty)}
                    />
                </Row>
            </ConfirmationModal>
        </>
    );
}
