interface FilterProps {
    onUpdate: (value: string) => void;
}

export function Filter({ onUpdate }: FilterProps) {
    return (
        <div>
            <input className="form-control mb-2" placeholder="Search" onChange={(e) => onUpdate(e.target.value)} />
        </div>
    );
}
