import { useState, useEffect, useCallback } from 'react';
import { System, SystemPermission } from '../../../../api/models';
import appRegistryClient from '../../../../api/AppRegistryClient';
import { PermissionsEnhancedTable } from './PermissionsEnhancedTable';
import { UserPrincipal } from '../Types/UserPrincipal';
import { tryCallApi } from '../../../../api/Helpers';
import { PermissionResource } from './PermissionResource';

interface SystemPermissionsProps {
    system?: System;
    user: UserPrincipal;
    isSelected: boolean;
}
export function SystemPermissionsTab({ system, user, isSelected }: SystemPermissionsProps) {
    const [dataIsLoading, setDataIsLoading] = useState(true);
    const [permissions, setPermissions] = useState(Array<SystemPermission>());
    const [availableRoles, setAvailableRoles] = useState<Array<string>>();

    const loadPermissions = useCallback(async () => {
        let permissionResult = new Array<SystemPermission>();
        const success = await tryCallApi(async () => {
            permissionResult = await appRegistryClient.systemsPermissions.get(system!.id);
        });
        if (success) {
            setPermissions(permissionResult);
        }
    }, [system]);

    const loadAvailableRoles = useCallback(async () => {
        let roles = {} as { [key: string]: string };
        const success = await tryCallApi(async () => {
            roles = await appRegistryClient.systemRoles.get();
        });
        if (success) {
            const options = Object.values(roles).filter((role: string) => user.hasPermissionForRole(PermissionResource.System, role));
            setAvailableRoles(options);
        }
    }, [user]);

    const loadData = useCallback(async () => {
        await loadPermissions();
        await loadAvailableRoles();
        setDataIsLoading(false);
    }, [loadPermissions, loadAvailableRoles]);

    useEffect(() => {
        if (isSelected && dataIsLoading && system?.id !== undefined) {
            loadData();
        }
    }, [isSelected, dataIsLoading, system?.id, loadData]);

    const changeHandler = async () => {
        setDataIsLoading(true);
        await loadPermissions();
        setDataIsLoading(false);
    };

    const createHandler = async (id: string, groupId: string, role: string): Promise<boolean> => {
        const success = await tryCallApi(async () => {
            await appRegistryClient.systemsPermissions.post(id, groupId, role);
        });
        changeHandler();
        return success;
    };

    const deleteHandler = async (id: string, groupId: string, role: string): Promise<boolean> => {
        const success = await tryCallApi(async () => {
            await appRegistryClient.systemsPermissions.delete(id, groupId, role);
        });
        changeHandler();
        return success;
    };

    return (
        <PermissionsEnhancedTable
            id={system?.id}
            data={permissions}
            isLoading={dataIsLoading}
            availableRoles={availableRoles}
            deleteHandler={deleteHandler}
            createHandler={createHandler}
        />
    );
}
