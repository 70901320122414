import { AttributeType } from '../../../../../api/models';

export class DefaultAttributeType implements AttributeType {
    id: string;
    name: string;
    isActive: boolean;
    allowMultiple: boolean;
    valueType: string;

    constructor() {
        this.id = '';
        this.name = '';
        this.isActive = true;
        this.allowMultiple = false;
        this.valueType = '';
    }
}
