import { mdiCancel, mdiCheck } from '@mdi/js';
import { CodeType } from '../../../../api/models';
import { ActionButton } from '../UIExtensions/Buttons';
import { ConfirmationModal, VerbiageType } from '../Shared/ConfirmationModal';
import { ActionAlert } from '../Shared/ActionAlert';
import { useState } from 'react';

interface CodeTypeActiveChangeProps {
    disabled: boolean;
    codeName: string;
    codeType: CodeType;
    onActiveChanged: (type: CodeType) => Promise<Map<string, string[]> | undefined>;
}

export const CodeTypeActiveChange = ({ disabled, codeName, codeType, onActiveChanged }: CodeTypeActiveChangeProps) => {
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const disableLabel = 'Disable';
    const enableLabel = 'Enable';
    const key = `change-${codeName}-active-alert-${codeType.id}`;
    return (
        <>
            <ActionButton
                disabled={disabled}
                mdiIcon={codeType?.isActive ? mdiCancel : mdiCheck}
                variant={codeType?.isActive ? 'btn-red' : 'btn-green'}
                title={codeType.isActive ? disableLabel : enableLabel}
                onClick={() => {
                    setShowConfirmationDialog(true);
                }}
            />
            <ConfirmationModal
                show={showConfirmationDialog}
                enabled={true}
                title={'Active Change'}
                verbiageType={VerbiageType.YesNo}
                onCancel={() => setShowConfirmationDialog(false)}
                onConfirm={async () => {
                    const attributeTypeCopy = structuredClone(codeType);
                    attributeTypeCopy.isActive = !codeType.isActive;
                    const errors = await onActiveChanged(attributeTypeCopy);
                    if (!errors) {
                        setShowConfirmationDialog(false);
                    }
                }}
            >
                <ActionAlert key={key} variant="danger">
                    Are you sure that you want to &nbsp;
                    <b>
                        {codeType.isActive ? disableLabel.toLocaleLowerCase() : enableLabel.toLocaleLowerCase()} {codeType.name}
                    </b>
                    &nbsp;?
                </ActionAlert>
            </ConfirmationModal>
        </>
    );
};
