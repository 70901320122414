import { forwardRef, useImperativeHandle, useRef } from 'react';
import { ConsolidationStepProps } from '../ConsolidationStepProps';
import { BasicInfoEdit } from './BasicInfoEdit';
import { ConsolidationWizardModel } from '../../ConsolidationWizard';

interface BasicInfoStepProps extends ConsolidationStepProps {
    appUniqueName: string;
}

export const BasicInfoStep = forwardRef<(() => Promise<boolean>) | undefined, BasicInfoStepProps>(
    ({ formModel, setFormModel, setCurrentStatus, appUniqueName }: BasicInfoStepProps, ref) => {
        const childRef = useRef<() => Promise<boolean>>();

        const setForm = (formValue: Record<string, any>) => {
            const subRequestValue = formValue as ConsolidationWizardModel;
            setFormModel(subRequestValue);
        };

        const validate = async () => {
            let isValid = false;
            if (childRef?.current) {
                isValid = await childRef.current();
            }
            if (!isValid) {
                setCurrentStatus('error');
            }
            return isValid;
        };

        useImperativeHandle(ref, () => validate);

        return <BasicInfoEdit formModel={formModel} appUniqueName={appUniqueName} setFormModel={setForm} ref={childRef} />;
    }
);
