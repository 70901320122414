import { ReactNode } from 'react';
import { PageContent, PageFooter, PageFooterLegal, PageFooterVersion, PageHeading } from '@quad/bootstrap-react';
import { useAppSelector } from '../../../hooks/useAppSelector';
import * as themeSelectors from '../../../store/theme/selectors';

interface BasePageProps {
    name: string;
    children?: ReactNode;
}

function BasePage({ name, children }: BasePageProps) {
    const applicationInfo = useAppSelector(themeSelectors.selectorApplicationInfo);

    return (
        <PageContent
            scrollType="ScrollContentAndFooter"
            footer={<PageFooter pageFooterLegal={<PageFooterLegal />} version={<PageFooterVersion version={applicationInfo?.version} />} />}
        >
            <PageHeading>
                <div className="col">
                    <h1>{name}</h1>
                </div>
            </PageHeading>
            <div className="container-fluid">{children}</div>
        </PageContent>
    );
}

export default BasePage;
