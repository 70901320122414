import { useCallback, useEffect, useState } from 'react';
import { Application, AttributeType, AttributeValue } from '../../../../../api/models';
import { ApplicationAttributesEdit } from './ApplicationAttributesEdit';
import { ApplicationAttributes } from './ApplicationAttributes';
import AppRegistryClient from '../../../../../api/AppRegistryClient';
import _ from 'lodash';
import { AttributeValueTypes } from '../../Attributes/Types/AttributeValueTypes';

interface ApplicationAttributesWrapperProps {
    application?: Application;
    canEdit: boolean;
}

export const ApplicationAttributesWrapper = ({ application, canEdit }: ApplicationAttributesWrapperProps) => {
    const [attributeTypes, setAttributeTypes] = useState<AttributeType[]>([]);
    const [attributeValues, setAttributeValues] = useState<AttributeValue[]>([]);
    const [editableAttributeTypes, setEditableAttributeTypes] = useState<string[]>([]);
    const [applicationAttributeValues, setApplicationAttributeValues] = useState(new Map<AttributeType, AttributeValue[]>());
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [editMode, setEditMode] = useState(false);

    const getAttributes = useCallback(async () => {
        try {
            const attributeTypes = await AppRegistryClient.attributeTypes.getAll();
            setAttributeTypes(attributeTypes);

            const attributeValues = await AppRegistryClient.attributeValues.getAll();
            setAttributeValues(attributeValues);

            if (application) {
                const editableAttributeTypes = (await AppRegistryClient.userPermissions.getApplicationAttributesPermissions(application.id)).map((attribute) => attribute.id);
                setEditableAttributeTypes(editableAttributeTypes);
            }
        } finally {
            setIsLoading(false);
        }
    }, [application]);

    useEffect(() => {
        const applicationAttributesMap = new Map<AttributeType, AttributeValue[]>();
        _.forEach(attributeTypes, (attributeType) => {
            const applicationAttributesForType = application?.attributes.filter((applicationAttribute) => applicationAttribute.type === attributeType.id);
            const creatable = attributeType.valueType === AttributeValueTypes.FreeformText;
            let applicationAttributesValuesForType = null;
            if (creatable) {
                applicationAttributesValuesForType = applicationAttributesForType!.map((applicationAttribute) => {
                    return {
                        id: applicationAttribute.id,
                        isActive: true,
                        type: attributeType.id,
                        name: applicationAttribute.value,
                        value: applicationAttribute.value,
                    } as AttributeValue;
                });
            } else {
                applicationAttributesValuesForType = new Array<AttributeValue>();
                _.forEach(applicationAttributesForType, function (applicationAttribute, attributeKey) {
                    _.forEach(attributeValues, function (attributeValue, attributeValueKey) {
                        if (applicationAttribute.type === attributeValue.type && applicationAttribute.value === attributeValue.value) {
                            applicationAttributesValuesForType.push(attributeValue);
                        }
                    });
                });
            }

            applicationAttributesMap.set(attributeType, applicationAttributesValuesForType);
        });

        setApplicationAttributeValues(applicationAttributesMap);
    }, [application, attributeTypes, attributeValues]);

    useEffect(() => {
        getAttributes();
    }, [getAttributes]);

    return (
        <>
            {editMode ? (
                <ApplicationAttributesEdit
                    applicationId={application!.id}
                    applicationAttributes={applicationAttributeValues}
                    attributeTypes={attributeTypes}
                    attributeValues={attributeValues}
                    editableAttributeTypes={editableAttributeTypes}
                    isLoading={isLoading}
                    onClose={() => setEditMode(false)}
                    onSave={(attributeValues: Map<AttributeType, AttributeValue[]>) => {
                        setApplicationAttributeValues(attributeValues);
                        setEditMode(false);
                    }}
                />
            ) : (
                <ApplicationAttributes
                    applicationAttributes={applicationAttributeValues}
                    attributeTypes={attributeTypes}
                    setEditMode={setEditMode}
                    canEdit={canEdit && editableAttributeTypes.length > 0}
                />
            )}
        </>
    );
};
