import Icon from '@mdi/react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface InfoLinkProps {
    to: string;
    disabled?: boolean;
    title: string;
    mdiIcon: string;
    size?: number;
}
export const InfoLink = ({ to, disabled, title, mdiIcon, size }: InfoLinkProps) => {
    const className = classNames({ disabled });
    const icon = mdiIcon;
    const iconSize = size ?? 1.0;
    return (
        <Link to={to} className={className} title={title}>
            <Icon path={icon} size={iconSize} />
        </Link>
    );
};
