import { Navigation, NavigationItem } from '../Navigation/Navigation';
import appRegistryClient from '../../../../api/AppRegistryClient';
import { Application } from '../../../../api/models';
import { useEffect, useState } from 'react';
import { mdiAccountSupervisor, mdiClock, mdiCreditCardSearch, mdiInformation, mdiRegisteredTrademark, mdiWeb } from '@mdi/js';
import { Permission } from '../Permissions/permission';
import { ApplicationInfoWrapper } from './ApplicationInfoWrapper';
import { AppRegistrationsEnhancedTable } from '../AppRegistrations/AppRegistrationsEnhancedTable';
import { UserPrincipal } from '../Types/UserPrincipal';
import { ApplicationEventLog } from '../EventLog/ApplicationEventLog';
import { AppSourcesTab } from '../AppSources/AppSourcesTab';
import { NavigationItemLabels } from './NavigationItemLabels';
import { AppPermissionsTab } from '../Permissions/AppPermissionsTab';
import { Loading } from '../Shared/Loading';
import { EndpointsTab } from '../Endpoints/EndpointsTab';

export interface ApplicationDetailsInnerProps {
    app?: Application;
    isLoading: boolean;
    isReadOnly: boolean;
}

export const ApplicationDetailsInner = ({ app, isLoading, isReadOnly }: ApplicationDetailsInnerProps) => {
    const [selectedItem, setSelectedItem] = useState<String | null>();
    const [canEdit, setCanEdit] = useState(false);
    const [user, setUser] = useState<UserPrincipal>(new UserPrincipal());
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        const getUserPermissions = async () => {
            if (app) {
                var tempUserPermissions = await appRegistryClient.userPermissions.getApplicationPermissions(app.id);
                var user = new UserPrincipal(tempUserPermissions);
                setUser(user);
            }
        };
        if (!isReadOnly) {
            getUserPermissions();
        }
    }, [app, isReadOnly]);

    useEffect(() => {
        const checkEditPermission = () => {
            const canEdit = user.hasPermission(Permission.Application_Update);
            setCanEdit(canEdit);
        };
        if (isReadOnly) {
            setCanEdit(false);
        } else {
            checkEditPermission();
        }
    }, [isReadOnly, user]);

    const navigationItems = [
        {
            label: NavigationItemLabels.BasicInfo,
            mdiIcon: mdiInformation,
            targetElement: (
                <ApplicationInfoWrapper
                    isLoading={isLoading}
                    application={app}
                    canEdit={canEdit}
                    isEditMode={editMode}
                    canEditAttributes={!isReadOnly}
                    setEditCallback={(isEdit: boolean) => setEditMode(isEdit)}
                />
            ),
            active: true,
        },
        {
            label: NavigationItemLabels.AppRegistrations,
            mdiIcon: mdiRegisteredTrademark,
            targetElement: (
                <AppRegistrationsEnhancedTable
                    isLoading={isLoading}
                    applicationName={app?.displayName}
                    data={app?.appRegistrations}
                    applicationId={app?.id}
                    canPerformActions={canEdit}
                />
            ),
            active: true,
        },
        {
            label: NavigationItemLabels.Permissions,
            mdiIcon: mdiAccountSupervisor,
            targetElement: <AppPermissionsTab app={app} user={user} isSelected={selectedItem === NavigationItemLabels.Permissions} />,
            active: true,
        },
        {
            label: NavigationItemLabels.DiscoveryInfo,
            mdiIcon: mdiCreditCardSearch,
            targetElement: <AppSourcesTab app={app} isSelected={selectedItem === NavigationItemLabels.DiscoveryInfo} />,
            active: true,
        },
        {
            label: NavigationItemLabels.Endpoints,
            mdiIcon: mdiWeb,
            targetElement: <EndpointsTab app={app} isSelected={selectedItem === NavigationItemLabels.Endpoints} canPerformActions={canEdit} />,
            active: true,
        },
        {
            label: NavigationItemLabels.Events,
            mdiIcon: mdiClock,
            targetElement: <ApplicationEventLog app={app}></ApplicationEventLog>,
            active: true,
        },
    ] as NavigationItem[];

    if (isLoading) {
        return <Loading text="Application is loading"></Loading>;
    }

    return <Navigation items={navigationItems} onSelectedItemChanged={setSelectedItem} />;
};
