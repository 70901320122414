import { Table } from 'react-bootstrap';
import { AttributeType, AttributeValue, CodeType } from '../../../../../api/models';
import { Loading } from '../../Shared/Loading';
import { AttributeValuesTableRow } from './AttributeValuesTableRow';
import { mdiTagPlusOutline } from '@mdi/js';
import { AttributeValueEdit } from './AttributeValueEdit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { tryCallApiCatchErrors } from '../../../../../api/Helpers';
import { CodeTypeSort } from '../CodeTypeSort';
import AppRegistryClient from '../../../../../api/AppRegistryClient';
import _ from 'lodash';
import { UserPrincipal } from '../../Types/UserPrincipal';
import { Permission } from '../../Permissions/permission';
import { Filter } from '../../Shared/Filter';
import { CodeTypePermissions } from '../../CodeTypes/CodeTypePermissions';

export interface AttributeValuesTableProps {
    userPrincipal: UserPrincipal;
    selectedType: AttributeType;
}

export const AttributeValuesTable = ({ userPrincipal, selectedType }: AttributeValuesTableProps) => {
    const [attributeValues, setAttributeValues] = useState<AttributeValue[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState('');

    const findExistingAttributeValue = (value: CodeType): AttributeValue | undefined => {
        return _.find(attributeValues, function (attributeValue) {
            return attributeValue.id.toLowerCase() === value.id.toLowerCase();
        });
    };

    const addAttributeValue = async (value: AttributeValue): Promise<Map<string, string[]> | undefined> => {
        const existingValue = findExistingAttributeValue(value);
        if (existingValue) {
            return undefined;
        }

        const errors = await tryCallApiCatchErrors(
            async () => await AppRegistryClient.attributeValues.post(value),
            (addedValue) => {
                value.id = addedValue!.id;

                const attributeValuesCopy = [...attributeValues];
                attributeValuesCopy.push(value);
                setAttributeValues(attributeValuesCopy);
            }
        );

        return errors;
    };

    const changeAttributeValueActivate = async (value: CodeType): Promise<Map<string, string[]> | undefined> => {
        const existingValue = findExistingAttributeValue(value);
        if (!existingValue) {
            return undefined;
        }

        const errors = await tryCallApiCatchErrors(
            async () => await AppRegistryClient.attributeValues.setActive(value),
            () => {
                existingValue.isActive = value.isActive;

                const attributeValuesCopy = [...attributeValues];
                setAttributeValues(attributeValuesCopy);
            }
        );

        return errors;
    };

    const editAttributeValue = async (value: AttributeValue): Promise<Map<string, string[]> | undefined> => {
        const existingValue = findExistingAttributeValue(value);
        if (!existingValue) {
            return undefined;
        }

        const errors = await tryCallApiCatchErrors(
            async () => await AppRegistryClient.attributeValues.put(value),
            () => {
                existingValue.name = value.name;

                const attributeValuesCopy = [...attributeValues];
                setAttributeValues(attributeValuesCopy);
            }
        );

        return errors;
    };

    const deleteAttributeValue = async (value: CodeType): Promise<Map<string, string[]> | undefined> => {
        const existingValue = findExistingAttributeValue(value);
        if (!existingValue) {
            return undefined;
        }

        const errors = await tryCallApiCatchErrors(
            async () => await await AppRegistryClient.attributeValues.delete(value.id),
            () => {
                const attributeValuesCopy = [...attributeValues];
                attributeValuesCopy.splice(attributeValuesCopy.indexOf(existingValue), 1);

                setAttributeValues(attributeValuesCopy);
            }
        );

        return errors;
    };

    const getAttributeValues = useCallback(async () => {
        try {
            const result = await AppRegistryClient.attributeValues.getAll();
            setAttributeValues(result);
        } catch {
            setAttributeValues([]);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        getAttributeValues();
    }, [getAttributeValues]);

    const filteredAttributeValues = useMemo(() => {
        return attributeValues
            .filter((attributeValue) => attributeValue.type === selectedType.id && attributeValue.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))
            .sort(CodeTypeSort);
    }, [attributeValues, filter, selectedType.id]);

    if (isLoading) {
        return <Loading />;
    }

    const canCreate = userPrincipal.hasPermission(CodeTypePermissions.canCreate(Permission.AttributeValuesPermissionPrefix));
    return (
        <>
            <Filter onUpdate={setFilter} />
            <div className="table-wrapper">
                <Table striped={true} className="table-fixed-header">
                    <thead className="thead-white">
                        <tr>
                            <th scope="col" className="col-9">
                                Values
                                <AttributeValueEdit
                                    allValues={filteredAttributeValues}
                                    disabled={!(selectedType.isActive && canCreate)}
                                    type={selectedType.id}
                                    mdiIcon={mdiTagPlusOutline}
                                    variant="btn-dark-blue"
                                    title="Add"
                                    onConfirm={addAttributeValue}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredAttributeValues?.map(function (d) {
                            return (
                                <AttributeValuesTableRow
                                    allValues={filteredAttributeValues}
                                    key={'attribute-value-' + d.id}
                                    userPrincipal={userPrincipal}
                                    permissionPrefix={Permission.AttributeValuesPermissionPrefix}
                                    attributeValue={d}
                                    onEdited={editAttributeValue}
                                    onActiveChanged={changeAttributeValueActivate}
                                    onDelete={deleteAttributeValue}
                                />
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </>
    );
};
