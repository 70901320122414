import { UserInfo } from '../store/theme/models';
import { ApiRequest } from './models';
import MsalClientBase from './MsalClientBase';

class MicrosoftGraphClient extends MsalClientBase {
    constructor() {
        super('https://graph.microsoft.com', ['User.Read']);
    }

    getMyPhoto = async () => {
        const request: ApiRequest = {
            method: 'GET',
            url: '/v1.0/me/photo/$value',
        };

        return this.fetch(request);
    };

    getUserInfo = async () => {
        const request: ApiRequest = {
            method: 'GET',
            url: `/v1.0/me`,
        };

        return this.fetchJson<UserInfo>(request);
    };
}

export default MicrosoftGraphClient;
