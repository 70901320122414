import { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { GroupSearch } from '../Shared/Search/GroupSearch';
import { SelectOptionString } from '../Types/SelectOption';
import { Application, CreateApplicationRequest } from '../../../../api/models';
import { ValidatableInput } from '../Shared/ValidatableInput';
import { nameof } from 'ts-simple-nameof';
import { ValidatableSelect } from '../Shared/ValidatableSelect';
import { setField } from '../../../../helpers/FieldSetter';

interface ApplicationEditProps {
    isCreateMode: boolean;
    app: Application;
    saveHandler: (app: Application, ownerId: string) => void;
    onCancel: () => void;
    apiErrors?: Map<string, string[]> | undefined;
    appTypes?: SelectOptionString[];
    appStates?: SelectOptionString[];
    teams?: SelectOptionString[];
    systems?: SelectOptionString[];
}

function ApplicationEdit({ isCreateMode, app, saveHandler, onCancel, apiErrors, appTypes, appStates, teams, systems }: ApplicationEditProps) {
    const [ownerGroupId, setOwnerGroupId] = useState<string>('');
    const [application, setApplication] = useState<Application>(app);

    const [enabled, setEnabled] = useState(false);

    const [fieldsValidityState, setFieldsValidityState] = useState(new Map<string, boolean>());
    const fieldsValidity = useRef(new Map<string, boolean>());

    const onFieldChanged = function <T>(fieldName: string, value: T) {
        const copy = structuredClone(application);
        if (setField(copy, fieldName, value)) {
            setApplication(copy);
        }
    };

    const setFieldValidity = function (fieldName: string, valid: boolean) {
        fieldsValidity.current.set(fieldName, valid);
        const fieldsValidityCopy = new Map(fieldsValidity.current);
        setFieldsValidityState(fieldsValidityCopy);
    };

    useEffect(() => {
        const valid = Array.from(fieldsValidityState.values()).every((valid) => valid);
        setEnabled(valid);
    }, [fieldsValidityState]);

    const uniqueNamePropertyName = nameof<Application>((r) => r.uniqueName);
    const displayNamePropertyName = nameof<Application>((r) => r.displayName);
    const descriptionPropertyName = nameof<Application>((r) => r.description);
    const typePropertyName = nameof<Application>((r) => r.type);
    const statePropertyName = nameof<Application>((r) => r.state);
    const ownerGroupIdPropertyName = nameof<CreateApplicationRequest>((r) => r.ownerGroupId);
    const deliveryTeamIdPropertyName = nameof<Application>((r) => r.deliveryTeamId);
    const systemIdPropertyName = nameof<Application>((r) => r.systemId);

    return (
        <>
            <Row>
                <ValidatableInput
                    disabled={!isCreateMode}
                    fieldName={'Application Unique Name*'}
                    value={application.uniqueName}
                    placeholder={"Unique Name e.g. 'Application.Unique.Name'"}
                    onChange={(value: string) => {
                        onFieldChanged(uniqueNamePropertyName, value);
                    }}
                    apiErrors={apiErrors?.get(uniqueNamePropertyName)}
                    validate={(value: string) => {
                        let errors = [];
                        if (value === undefined || value.length === 0) {
                            errors.push('Unique Name is a required field.');
                        }
                        setFieldValidity(uniqueNamePropertyName, errors.length === 0);
                        return errors;
                    }}
                />
            </Row>
            <Row>
                <ValidatableInput
                    fieldName={'Display Name*'}
                    disabled={false}
                    value={application.displayName}
                    placeholder={"Name e.g. 'Application Name'"}
                    onChange={(value: string) => {
                        onFieldChanged(displayNamePropertyName, value);
                    }}
                    validate={(value: string) => {
                        let errors = [];
                        if (value === undefined || value.length === 0) {
                            errors.push('Display name is a required field.');
                        }
                        setFieldValidity(displayNamePropertyName, errors.length === 0);
                        return errors;
                    }}
                />
            </Row>
            <Row>
                <ValidatableInput
                    fieldName={'Description'}
                    disabled={false}
                    value={application.description}
                    placeholder={"Description e.g. 'The longer description of the application'"}
                    onChange={(value: string) => {
                        onFieldChanged(descriptionPropertyName, value);
                    }}
                    validate={(value: string) => {
                        const errors = new Array<string>();
                        return errors;
                    }}
                />
            </Row>
            <Row>
                <ValidatableSelect
                    label={'Type*'}
                    options={appTypes!}
                    disabled={false}
                    onChange={(value) => {
                        onFieldChanged(typePropertyName, value);
                    }}
                    externalErrors={apiErrors?.get(typePropertyName)}
                    validate={(value) => {
                        let errors = [];
                        if (!value || value.length === 0) {
                            errors.push('Type must be selected.');
                        }
                        setFieldValidity(typePropertyName, errors.length === 0);
                        return errors;
                    }}
                    selectedValue={application.type}
                />
                <ValidatableSelect
                    label={'State*'}
                    options={appStates!}
                    disabled={isCreateMode}
                    onChange={(value) => {
                        onFieldChanged(statePropertyName, value);
                    }}
                    externalErrors={apiErrors?.get(statePropertyName)}
                    validate={(value) => {
                        let errors = [];
                        if (!value || value.length === 0) {
                            errors.push('State must be selected.');
                        }
                        setFieldValidity(statePropertyName, errors.length === 0);
                        return errors;
                    }}
                    selectedValue={application.state}
                />
                {isCreateMode ? (
                    <div className="form-group">
                        <label>Owner Group*</label>
                        <GroupSearch
                            focus={false}
                            onChange={(groupName: string, groupId: string) => {
                                setOwnerGroupId(groupId);
                            }}
                            validate={(groupName: string, groupId: string) => {
                                let errors = [];
                                const isValid = !(groupId === undefined || groupId.length === 0);
                                setFieldValidity(ownerGroupIdPropertyName, isValid);
                                if (!isValid) {
                                    errors.push('Owner Group is required');
                                }
                                return errors;
                            }}
                            apiErrors={apiErrors?.get(ownerGroupIdPropertyName)}
                        />
                    </div>
                ) : (
                    ''
                )}
            </Row>
            <Row>
                <ValidatableSelect
                    label={'Delivery Team'}
                    options={teams!}
                    disabled={false}
                    onChange={(value) => {
                        onFieldChanged(deliveryTeamIdPropertyName, value);
                    }}
                    externalErrors={apiErrors?.get(deliveryTeamIdPropertyName)}
                    validate={(value) => {
                        let errors = new Array<string>();
                        return errors;
                    }}
                    selectedValue={application.deliveryTeamId}
                />
            </Row>
            <Row>
                <ValidatableSelect
                    label={'System'}
                    options={systems!}
                    disabled={false}
                    onChange={(value) => {
                        onFieldChanged(systemIdPropertyName, value);
                    }}
                    externalErrors={apiErrors?.get(systemIdPropertyName)}
                    validate={(value) => {
                        let errors = new Array<string>();
                        return errors;
                    }}
                    selectedValue={application.systemId}
                />
            </Row>
            <Row>
                <Col md={2}>
                    <Button
                        variant="light"
                        onClick={() => {
                            onCancel();
                        }}
                    >
                        Cancel
                    </Button>
                </Col>
                <Col>
                    <Button
                        variant="primary"
                        disabled={!enabled}
                        onClick={() => {
                            saveHandler(application, ownerGroupId);
                        }}
                    >
                        Save Changes
                    </Button>
                </Col>
            </Row>
        </>
    );
}
export default ApplicationEdit;
