import { Col, Row } from 'react-bootstrap';
import { AttributeTypesTable } from './Types/AttributeTypesTable';
import { AttributeValuesTable } from './Values/AttributeValuesTable';
import { useEffect, useState } from 'react';
import { AttributeType } from '../../../../api/models';
import { UserPrincipal } from '../Types/UserPrincipal';
import AppRegistryClient from '../../../../api/AppRegistryClient';

interface AttributesDetailsProps {}

export const AttributesDetails = (props: AttributesDetailsProps) => {
    const [userPrincipal, setUserPrincipal] = useState<UserPrincipal>(new UserPrincipal());
    const [selectedType, setSelectedType] = useState({} as AttributeType);

    const getUserPermissions = async () => {
        var tempUserPermissions = await AppRegistryClient.userPermissions.getRootPermissions();
        var user = new UserPrincipal(tempUserPermissions);
        setUserPrincipal(user);
    };

    useEffect(() => {
        getUserPermissions();
    }, []);

    return (
        <Row>
            <Col>
                <AttributeTypesTable userPrincipal={userPrincipal} onSelectedChanged={setSelectedType} />
            </Col>
            <Col>
                <AttributeValuesTable userPrincipal={userPrincipal} selectedType={selectedType} />
            </Col>
        </Row>
    );
};
