import { PageHeading } from '@quad/bootstrap-react';
import { Container } from 'react-bootstrap';
import ScrollPageContent from './components/Shared/ScrollPageContent';
import { AttributesDetails } from './components/Attributes/AttributesDetails';
import Icon from '@mdi/react';

interface AttributesProps {
    title: string;
    mdiIcon: string;
}

export const Attributes = ({ title, mdiIcon }: AttributesProps) => {
    return (
        <ScrollPageContent>
            <PageHeading>
                <div className="col">
                    <h1>
                        <Icon path={mdiIcon} size={1.8} /> {title}
                    </h1>
                </div>
            </PageHeading>

            <Container>
                <AttributesDetails />
            </Container>
        </ScrollPageContent>
    );
};
