import { System, Application, UserInfo } from '../../../../api/models';

let defaultOwner: UserInfo = {
    id: '',
    employeeId: 0,
    displayName: '',
    upn: '',
    firstName: '',
    lastName: '',
    email: '',
    createdTimestamp: new Date(),
    lastLoginTimestamp: new Date(),
    primarySid: '',
};

export class DefaultSystem implements System {
    id: string;
    displayName: string;
    description: string;
    itOwnerId: string;
    itOwner: UserInfo;
    applications: Application[];
    constructor() {
        this.id = '';
        this.displayName = '';
        this.description = '';
        this.itOwnerId = '';
        this.itOwner = defaultOwner;
        this.applications = [];
    }
}
