import { useEffect, useState } from 'react';
import { ActionButton } from '../UIExtensions/Buttons';
import { ConfirmationModal, VerbiageType } from '../Shared/ConfirmationModal';
import { Row } from 'react-bootstrap';
import { ValidatableSelect } from '../Shared/ValidatableSelect';
import { GroupSearch } from '../Shared/Search/GroupSearch';
import { SelectOptionString } from '../Types/SelectOption';
import { mdiPlus } from '@mdi/js';

interface PermissionsCreateProps {
    id: string;
    availableRoles?: Array<string>;
    spinning: boolean;
    createHandler: (id: string, groupId: string, role: string) => Promise<boolean>;
}

function PermissionsCreate({ id, availableRoles, spinning, createHandler }: PermissionsCreateProps) {
    const [roleNameOptions, setRoleNameOptions] = useState(new Array<SelectOptionString>());
    const [roleValid, setRoleValid] = useState(false);
    const [groupValid, setGroupValid] = useState(false);
    const [showCreate, setShowCreate] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [roleName, setRoleName] = useState('');
    const [groupId, setGroupId] = useState('');

    useEffect(() => {
        if (availableRoles) {
            const options = availableRoles?.map((role: string) => new SelectOptionString(role, role));
            setRoleNameOptions(options);

            setEnabled(options.length > 0);
        }
    }, [availableRoles]);

    const canConfirm = roleValid && groupValid;
    return (
        <div>
            <ActionButton
                disabled={!enabled}
                spin={spinning}
                outlined={true}
                mdiIcon={mdiPlus}
                size={1}
                variant="btn-green container-fluid"
                title="Add new permission"
                onClick={async () => setShowCreate(true)}
            />
            <ConfirmationModal
                show={showCreate}
                enabled={canConfirm}
                title="Add new permission"
                verbiageType={VerbiageType.OkCancel}
                onCancel={() => setShowCreate(false)}
                onConfirm={async () => {
                    if (await createHandler(id, groupId, roleName)) {
                        setShowCreate(false);
                    }
                }}
            >
                <Row>
                    <ValidatableSelect
                        label={'Role Name*'}
                        options={roleNameOptions}
                        disabled={false}
                        onChange={(value) => {
                            setRoleName(value ?? '');
                        }}
                        validate={(value) => {
                            let errors = [];
                            if (!value || value.length === 0) {
                                errors.push('Role must be selected.');
                            }
                            setRoleValid(errors.length === 0);
                            return errors;
                        }}
                        selectedValue={roleName}
                    />
                    <div className="form-group">
                        <label>Group*</label>
                        <GroupSearch
                            focus={false}
                            onChange={(groupName: string, groupId: string) => {
                                setGroupId(groupId);
                            }}
                            validate={(groupName: string, groupId: string) => {
                                let errors = [];
                                const isValid = !(groupId === undefined || groupId.length === 0);
                                setGroupValid(isValid);
                                if (!isValid) {
                                    errors.push('Group is required');
                                }
                                return errors;
                            }}
                        />
                    </div>
                </Row>
            </ConfirmationModal>
        </div>
    );
}

export default PermissionsCreate;
