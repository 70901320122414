import { msGraphClient } from '../../api';
import appConfig from '../../appConfig';
import { Action } from '../shared/models';
import { ApplicationInfo, UserInfo } from './models';
import types from './types';
import packageJson from '../../../package.json';

export const loadUserInfo = async () => {
    const userInfo = await msGraphClient.getUserInfo();

    const action: Action<UserInfo> = {
        type: types.USER_INFO_LOADED,
        payload: userInfo,
    };

    return action;
};

export const loadApplicationInfo = async () => {
    //const applicationInfo = await load<ApplicationInfo>('api/_app/v1.0/application-info');

    const applicationInfo = {
        version: packageJson.version,
        displayName: 'App Registry',
        helpdeskPortalUrl: '',
        passwordChangeUrl: '',
        signInUrl: '',
        signOutUrl: '',
        environment: appConfig.appEnvironment,
    };

    const action: Action<ApplicationInfo> = {
        type: types.APPLICATION_INFO_LOADED,
        payload: applicationInfo,
    };

    return action;
};
