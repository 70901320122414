import { Column } from '@tanstack/react-table';
import { DebouncedInput } from '../DebouncedInput';
import { useMemo } from 'react';

export function Filter({ column }: { column: Column<any, unknown> }) {
    const { filterVariant } = column.columnDef.meta ?? {};

    const columnFilterValue = column.getFilterValue();

    const sortedUniqueValues = useMemo(
        () => (filterVariant === 'select' || filterVariant === 'text-autocomplete' ? Array.from(column.getFacetedUniqueValues().keys()).sort().slice(0, 5000) : []),
        [column.getFacetedUniqueValues(), filterVariant]
    );

    return filterVariant === 'range' ? (
        <div>
            <div className="flex space-x-2">
                <DebouncedInput
                    type="number"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue as [number, number])?.[0] ?? ''}
                    onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
                    placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] !== undefined ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''}`}
                    className="table-filter-input border rounded"
                />
                <DebouncedInput
                    type="number"
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue as [number, number])?.[1] ?? ''}
                    onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
                    placeholder={`Max ${column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''}`}
                    className="table-filter-input border rounded"
                />
            </div>
            <div className="h-1" />
        </div>
    ) : filterVariant === 'select' ? (
        <select onChange={(e) => column.setFilterValue(e.target.value)} value={columnFilterValue?.toString()} className="table-filter-input border rounded">
            <option value="">All</option>
            {sortedUniqueValues.map((value) => (
                <option value={value} key={value}>
                    {value}
                </option>
            ))}
        </select>
    ) : filterVariant === 'text-autocomplete' ? (
        <>
            <datalist id={column.id + 'list'}>
                {sortedUniqueValues.map((value: any) => (
                    <option value={value} key={value} />
                ))}
            </datalist>
            <DebouncedInput
                type="text"
                value={(columnFilterValue ?? '') as string}
                onChange={(value) => column.setFilterValue(value)}
                placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
                list={column.id + 'list'}
                className="table-filter-input border rounded"
            />
            <div className="h-1" />
        </>
    ) : filterVariant === 'text' ? (
        <DebouncedInput
            onChange={(value) => column.setFilterValue(value)}
            placeholder={`Search...`}
            type="text"
            value={(columnFilterValue ?? '') as string}
            className="table-filter-input border rounded"
        />
    ) : filterVariant === 'none' ? (
        <DebouncedInput
            onChange={(value) => column.setFilterValue(value)}
            placeholder={`Search...`}
            type="text"
            value={(columnFilterValue ?? '') as string}
            className="table-filter-input border rounded"
            style={{ visibility: 'hidden' }}
        />
    ) : (
        <></>
    );
}
