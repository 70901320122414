import { Col, Form } from 'react-bootstrap';

interface DisplayCheckboxProps {
    labelSize: number;
    label: string;
    checked: boolean;
}

export const DisplayCheckbox = ({ labelSize, label, checked }: DisplayCheckboxProps) => {
    return (
        <>
            <Col md={labelSize} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                {label}
            </Col>
            <Col>
                <Form.Check checked={checked} disabled />
            </Col>
        </>
    );
};
