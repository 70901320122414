import { Table } from 'react-bootstrap';
import { AttributeValue, CodeType, CodeTypeSettings } from '../../../../../api/models';
import { Loading } from '../../Shared/Loading';
import { mdiPlus } from '@mdi/js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { tryCallApiCatchErrors } from '../../../../../api/Helpers';
import AppRegistryClient from '../../../../../api/AppRegistryClient';
import _ from 'lodash';
import { UserPrincipal } from '../../Types/UserPrincipal';
import { Filter } from '../../Shared/Filter';
import { CodeTypeSort } from '../../Attributes/CodeTypeSort';
import { AttributeValueEdit } from '../../Attributes/Values/AttributeValueEdit';
import { AttributeValuesTableRow } from '../../Attributes/Values/AttributeValuesTableRow';
import { CodeTypePermissions } from '../CodeTypePermissions';
import { nameof } from 'ts-simple-nameof';

export interface CodeValuesTableProps {
    userPrincipal: UserPrincipal;
    selectedType: CodeTypeSettings;
}

export const CodeValuesTable = ({ userPrincipal, selectedType }: CodeValuesTableProps) => {
    const [codeValues, setCodeValues] = useState(new Map<CodeTypeSettings, AttributeValue[]>());
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState('');

    const idProperty = nameof<AttributeValue>((value) => value.id);
    const valueProperty = nameof<AttributeValue>((value) => value.value);

    const findExistingCodeValue = (value: CodeType): AttributeValue | undefined => {
        return _.find(codeValues.get(selectedType), function (codeValue) {
            return codeValue.id.toLowerCase() === value.id.toLowerCase();
        });
    };

    const wrapErrors = async (errors: Map<string, string[]> | undefined): Promise<Map<string, string[]> | undefined> => {
        const idErrors = errors?.get(idProperty);
        if (idErrors) {
            // we reusing Attributes view therefore we need to set value errors = id errors
            errors?.delete(idProperty);
            errors?.set(valueProperty, idErrors);
        }
        return errors;
    };

    const addCodeValue = async (value: AttributeValue): Promise<Map<string, string[]> | undefined> => {
        value.id = value.value;
        const existingValue = findExistingCodeValue(value);
        if (existingValue) {
            return undefined;
        }

        const errors = await tryCallApiCatchErrors(
            async () => await AppRegistryClient.codeValues.post(selectedType.id, value),
            () => {
                const codeValuesCopy = new Map(codeValues);
                codeValuesCopy.get(selectedType)?.push(value);
                setCodeValues(codeValuesCopy);
            }
        );

        return wrapErrors(errors);
    };

    const changeCodeValueActivate = async (value: CodeType): Promise<Map<string, string[]> | undefined> => {
        const existingValue = findExistingCodeValue(value);
        if (!existingValue) {
            return undefined;
        }

        const errors = await tryCallApiCatchErrors(
            async () => await AppRegistryClient.codeValues.setActive(selectedType.id, value),
            () => {
                existingValue.isActive = value.isActive;

                const codeValuesCopy = new Map(codeValues);
                setCodeValues(codeValuesCopy);
            }
        );

        return wrapErrors(errors);
    };

    const editCodeValue = async (value: CodeType): Promise<Map<string, string[]> | undefined> => {
        const existingValue = findExistingCodeValue(value);
        if (!existingValue) {
            return undefined;
        }

        const errors = await tryCallApiCatchErrors(
            async () => await AppRegistryClient.codeValues.put(selectedType.id, value),
            () => {
                existingValue.name = value.name;

                const codeValuesCopy = new Map(codeValues);
                setCodeValues(codeValuesCopy);
            }
        );

        return wrapErrors(errors);
    };

    const deleteCodeValue = async (value: CodeType): Promise<Map<string, string[]> | undefined> => {
        const existingValue = findExistingCodeValue(value);
        if (!existingValue) {
            return undefined;
        }

        const errors = await tryCallApiCatchErrors(
            async () => await AppRegistryClient.codeValues.delete(selectedType.id, value.id),
            () => {
                const codeValuesCopy = new Map(codeValues);
                const codeValuesCopyForType = codeValuesCopy.get(selectedType);
                codeValuesCopyForType?.splice(codeValuesCopyForType?.indexOf(existingValue), 1);

                setCodeValues(codeValuesCopy);
            }
        );

        return wrapErrors(errors);
    };

    const getCodeValues = useCallback(async () => {
        if (!codeValues.get(selectedType)) {
            setIsLoading(true);
            try {
                const result = await AppRegistryClient.codeValues.getAll(selectedType.id);
                var codeValuesForType = result.map((codeValue) => {
                    return { ...codeValue, value: codeValue.id, type: selectedType.id } as AttributeValue;
                });

                const codeValuesCopy = new Map(codeValues);
                codeValuesCopy.set(selectedType, codeValuesForType);
                setCodeValues(codeValuesCopy);
            } finally {
                setIsLoading(false);
            }
        }
    }, [codeValues, selectedType]);

    useEffect(() => {
        getCodeValues();
    }, [getCodeValues]);

    const filteredCodeValues = useMemo(() => {
        const values = codeValues.get(selectedType);
        if (values) {
            return values.filter((codeValue) => codeValue.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())).sort(CodeTypeSort);
        }
        return [];
    }, [codeValues, filter, selectedType]);

    if (isLoading) {
        return <Loading />;
    }

    const canCreate = userPrincipal.hasPermission(CodeTypePermissions.canCreate(selectedType.permissionPrefix));

    return (
        <>
            <Filter onUpdate={setFilter} />
            <div className="table-wrapper">
                <Table striped={true} className="table-fixed-header">
                    <thead className="thead-white">
                        <tr>
                            <th scope="col" className="col-9">
                                Values
                                <AttributeValueEdit
                                    allValues={filteredCodeValues}
                                    disabled={!(selectedType.isActive && canCreate)}
                                    type={selectedType.id}
                                    mdiIcon={mdiPlus}
                                    variant="btn-green"
                                    title="Add"
                                    onConfirm={addCodeValue}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCodeValues?.map(function (d) {
                            return (
                                <AttributeValuesTableRow
                                    allValues={filteredCodeValues}
                                    key={'attribute-value-' + d.id}
                                    userPrincipal={userPrincipal}
                                    permissionPrefix={selectedType.permissionPrefix}
                                    attributeValue={d}
                                    onEdited={editCodeValue}
                                    onActiveChanged={changeCodeValueActivate}
                                    onDelete={deleteCodeValue}
                                />
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </>
    );
};
