import { Table } from 'react-bootstrap';
import { AttributeType, CodeType } from '../../../../../api/models';
import { AttributeTypesTableRow } from './AttributeTypesTableRow';
import { Loading } from '../../Shared/Loading';
import { mdiTagPlusOutline } from '@mdi/js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import AppRegistryClient from '../../../../../api/AppRegistryClient';
import { AttributeTypeEdit } from './AttributeTypeEdit';
import { CodeTypeSort } from '../CodeTypeSort';
import { tryCallApiCatchErrors } from '../../../../../api/Helpers';
import { UserPrincipal } from '../../Types/UserPrincipal';
import { Permission } from '../../Permissions/permission';
import { Filter } from '../../Shared/Filter';
import { CodeTypePermissions } from '../../CodeTypes/CodeTypePermissions';
import { CodeTypeId } from '../../CodeTypes/CodeTypeId';

export interface AttributeTypesTableProps {
    userPrincipal: UserPrincipal;
    onSelectedChanged: (type: AttributeType) => void;
}

export const AttributeTypesTable = ({ onSelectedChanged, userPrincipal }: AttributeTypesTableProps) => {
    const [attributeTypes, setAttributeTypes] = useState<AttributeType[]>([]);
    const [attributeValueTypes, setAttributeValueTypes] = useState<CodeType[]>([]);
    const [selectedType, setSelectedType] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState('');

    const findExistingAttributeType = (type: CodeType): AttributeType | undefined => {
        return _.find(attributeTypes, function (attributeType) {
            return attributeType.id.toLowerCase() === type.id.toLowerCase();
        });
    };

    const addAttributeType = async (type: AttributeType): Promise<Map<string, string[]> | undefined> => {
        const existing = findExistingAttributeType(type);
        if (existing) {
            return undefined;
        }

        const errors = await tryCallApiCatchErrors(
            async () => await AppRegistryClient.attributeTypes.post(type),
            () => {
                const attributeTypesCopy = [...attributeTypes];
                attributeTypesCopy.push(type);
                setAttributeTypes(attributeTypesCopy);

                onSelectedTypeChanged(type);
            }
        );

        return errors;
    };

    const changeAttributeTypeActivate = async (type: CodeType): Promise<Map<string, string[]> | undefined> => {
        const existing = findExistingAttributeType(type);
        if (!existing) {
            return undefined;
        }

        var callback = () => {
            existing.isActive = type.isActive;

            const attributeTypesCopy = [...attributeTypes];
            setAttributeTypes(attributeTypesCopy);
            if (selectedType === existing.id) {
                const typeCopy = structuredClone(existing);
                onSelectedTypeChanged(typeCopy);
            }
        };

        if (existing.isActive) {
            return await tryCallApiCatchErrors(async () => await AppRegistryClient.attributeTypes.disable(type.id), callback);
        } else {
            return await tryCallApiCatchErrors(async () => await AppRegistryClient.attributeTypes.enable(type.id), callback);
        }
    };

    const editAttributeType = async (type: AttributeType): Promise<Map<string, string[]> | undefined> => {
        const existing = findExistingAttributeType(type);
        if (!existing) {
            return undefined;
        }

        const errors = await tryCallApiCatchErrors(
            async () => await AppRegistryClient.attributeTypes.put(type),
            () => {
                existing.name = type.name;
                existing.allowMultiple = type.allowMultiple;
                existing.requiredRight = type.requiredRight;
                existing.valueType = type.valueType;

                const attributeTypesCopy = [...attributeTypes];
                setAttributeTypes(attributeTypesCopy);
            }
        );

        return errors;
    };

    const deleteAttributeType = async (type: CodeType): Promise<Map<string, string[]> | undefined> => {
        const existing = findExistingAttributeType(type);
        if (!existing) {
            return undefined;
        }

        const errors = await tryCallApiCatchErrors(
            async () => await AppRegistryClient.attributeTypes.delete(type.id),
            () => {
                const attributeTypesCopy = [...attributeTypes];
                attributeTypesCopy.splice(attributeTypesCopy.indexOf(existing), 1);

                setAttributeTypes(attributeTypesCopy);
                if (selectedType === existing.id) {
                    onSelectedTypeChanged(attributeTypesCopy[0]);
                }
            }
        );

        return errors;
    };

    const onSelectedTypeChanged = useCallback(
        (selectedType: AttributeType) => {
            onSelectedChanged(selectedType);
            setSelectedType(selectedType.id);
        },
        [onSelectedChanged]
    );

    const getAttributeTypes = useCallback(async () => {
        try {
            const result = await AppRegistryClient.attributeTypes.getAll();
            setAttributeTypes(result);
            if (result.length > 0) {
                onSelectedTypeChanged(result[0]);
            }
        } catch {
            setAttributeTypes([]);
        }
    }, [onSelectedTypeChanged]);

    const getAttributeValueTypes = useCallback(async () => {
        try {
            const result = await AppRegistryClient.codeValues.getAll(CodeTypeId.AttributeValueType);
            setAttributeValueTypes(result);
        } catch {
            setAttributeValueTypes([]);
        }
    }, []);

    useEffect(() => {
        const getData = async () => {
            await getAttributeTypes();
            await getAttributeValueTypes();
            setIsLoading(false);
        };
        getData();
    }, [getAttributeTypes, getAttributeValueTypes]);

    const sortedAttributeTypes = useMemo(() => {
        return attributeTypes.filter((attributeType) => attributeType.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())).sort(CodeTypeSort);
    }, [attributeTypes, filter]);

    if (isLoading) {
        return <Loading />;
    }

    const canCrete = userPrincipal.hasPermission(CodeTypePermissions.canCreate(Permission.AttributeTypesPermissionPrefix));
    return (
        <>
            <Filter onUpdate={setFilter} />
            <div className="table-wrapper">
                <Table striped={true} className="table-fixed-header table-selectable">
                    <thead className="thead-white">
                        <tr>
                            <th scope="col" className="col-9">
                                Types
                                <AttributeTypeEdit
                                    allTypes={attributeTypes}
                                    attributeValueTypes={attributeValueTypes}
                                    disabled={!canCrete}
                                    mdiIcon={mdiTagPlusOutline}
                                    variant="btn-dark-blue"
                                    title="Add"
                                    onConfirm={addAttributeType}
                                />
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedAttributeTypes?.map(function (d) {
                            return (
                                <AttributeTypesTableRow
                                    key={'attribute-type-' + d.id}
                                    allTypes={attributeTypes}
                                    attributeValueTypes={attributeValueTypes}
                                    userPrincipal={userPrincipal}
                                    attributeType={d}
                                    onClick={() => onSelectedTypeChanged(d)}
                                    selected={d.id === selectedType}
                                    onEdited={editAttributeType}
                                    onActiveChanged={changeAttributeTypeActivate}
                                    onDelete={deleteAttributeType}
                                />
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </>
    );
};
