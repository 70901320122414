import { Application } from '../../../../../api/models';
import SystemApplicationsTable from './SystemApplicationsTable';

export interface SystemApplicationsEnhancedTableProps {
    apps?: Application[];
}

export function SystemApplicationsEnhancedTable({ apps }: SystemApplicationsEnhancedTableProps) {
    return (
        <div>
            <SystemApplicationsTable applications={apps} />
        </div>
    );
}
