import { useEffect, useState, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import { ValidationErrors } from '../ValidationErrors';
import { SearchResult } from '../../../../../api/models';
import { SelectOptionString } from '../../Types/SelectOption';
import { tryCallApi } from '../../../../../api/Helpers';
import { SearchProps } from './SearchProps';

interface SearchBaseProps extends SearchProps {
    search: (searchTerm: string) => Promise<SearchResult[]>;
}
export function SearchBase({ value, onChange, validate, search, focus, apiErrors }: SearchBaseProps) {
    const [getOptionsCall, setGetOptionsCall] = useState<any>('');
    const [errors, setErrors]: [string[], Function] = useState([]);
    const [selected, setSelected] = useState<SelectOptionString>(value ?? new SelectOptionString('', ''));
    const select: any = useRef(null);

    const onValueChanged = async (name: string, id: string) => {
        setSelected(new SelectOptionString(name, id));
        const tempErrors = validate(name, id);
        setErrors(tempErrors);
        onChange(name, id);
    };

    useEffect(() => {
        if (focus) {
            select.current.focus();
        }
    }, [focus]);

    useEffect(() => {
        onValueChanged(selected?.label ?? '', selected?.value ?? '');
    }, []);

    useEffect(() => {
        let errors = validate(selected.label, selected.value);
        if (apiErrors) {
            errors = errors.concat(apiErrors);
            setErrors(errors);
        }
    }, [apiErrors]);

    const apiGetOptions = async (inputValue: string): Promise<Array<SelectOptionString> | undefined> => {
        let apiResults = new Array<SearchResult>();
        if (
            !inputValue ||
            (await tryCallApi(async () => {
                apiResults = await search(inputValue!);
            }))
        ) {
            const options = apiResults.map((apiResult) => new SelectOptionString(apiResult.displayName, apiResult.id));
            return options;
        }
        return undefined;
    };
    const promiseOptions = (searchString: string) =>
        new Promise<any>((resolve) => {
            clearTimeout(getOptionsCall);
            const call = setTimeout(() => {
                resolve(apiGetOptions(searchString));
            }, 500);
            setGetOptionsCall(call);
        });

    return (
        <>
            <AsyncSelect
                className={`required ${errors.length === 0 ? '' : 'validation-error'}`}
                ref={select}
                defaultOptions
                loadOptions={promiseOptions}
                onChange={(newValue: any) => {
                    onValueChanged(newValue.label, newValue.value);
                }}
                value={selected}
            />
            <ValidationErrors errors={errors} />
        </>
    );
}
