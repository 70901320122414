import { useState } from 'react';
import { AppRegistration, AppRegistrationExpanded } from '../../../../api/models';
import { Dropdown } from 'react-bootstrap';
import { AppRegistrationUnlink } from './AppRegistrationUnlink';
import { AppRegistrationDelete } from './AppRegistrationDelete';
import { ConfirmationModal, VerbiageType } from '../Shared/ConfirmationModal';
import { Loading } from '../Shared/Loading';
import Icon from '@mdi/react';
import { mdiMenu } from '@mdi/js';

interface AppRegistrationActionItem {
    text: string;
    title: string;
    inProgress: string;
    element: JSX.Element;
    enableByDefault: boolean;
    action: (appRegistration: AppRegistration) => Promise<Map<string, string[]> | undefined>;
}

interface AppRegistrationActionProps {
    disabled: boolean;
    appRegistration: AppRegistration;
    onDelete: (appRegistration: AppRegistrationExpanded) => Promise<Map<string, string[]> | undefined>;
    onUnlink: (appRegistration: AppRegistrationExpanded) => Promise<Map<string, string[]> | undefined>;
}

export function AppRegistrationAction({ disabled, appRegistration, onDelete, onUnlink }: AppRegistrationActionProps) {
    const [showModal, setShowModal] = useState(false);
    const [item, setItem] = useState<AppRegistrationActionItem>();
    const [isActionInProgress, setIsActionInProgress] = useState(false);
    const [enable, setEnable] = useState(false);

    const items: AppRegistrationActionItem[] = [
        {
            text: 'Unlink',
            title: 'Unlink',
            inProgress: 'Unlinking',
            action: onUnlink,
            enableByDefault: true,
            element: <AppRegistrationUnlink appRegistration={appRegistration} setEnable={setEnable} />,
        },
        {
            text: 'Delete',
            title: 'Delete',
            inProgress: 'Deleting',
            action: onDelete,
            enableByDefault: false,
            element: <AppRegistrationDelete appRegistration={appRegistration} setEnable={setEnable} />,
        },
    ];

    function popupModal(item: AppRegistrationActionItem) {
        setShowModal(true);
        setEnable(item.enableByDefault);
        setItem(item);
    }

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle disabled={disabled} variant="outline-primary">
                    <Icon path={mdiMenu} size={1} />
                </Dropdown.Toggle>
                <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} renderOnMount>
                    {items.map((item) => (
                        <Dropdown.Item onClick={() => popupModal(item)} key={`appreg-${item.text}`}>
                            {item.text}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            <ConfirmationModal
                show={showModal}
                enabled={enable}
                title={item?.title ?? ''}
                verbiageType={VerbiageType.YesNo}
                onCancel={() => setShowModal(false)}
                onConfirm={async () => {
                    setIsActionInProgress(true);
                    if (item) {
                        await item.action(appRegistration);
                    }
                    setIsActionInProgress(false);
                    setShowModal(false);
                }}
            >
                {isActionInProgress ? <Loading text={item?.inProgress}></Loading> : ''}
                {item?.element}
            </ConfirmationModal>
        </>
    );
}
