export function setField(obj: any, fieldName: string, value: any): boolean {
    let objectKeys = Object.getOwnPropertyNames(obj).concat(Object.getOwnPropertyNames(Object.getPrototypeOf(obj)));
    for (let index = 0; index < objectKeys.length; index++) {
        if (objectKeys[index] === fieldName) {
            if (obj[objectKeys[index]] !== value) {
                obj[objectKeys[index]] = value;
                return true;
            } else {
                return false;
            }
        }
    }
    obj[fieldName] = value;
    return true;
}
