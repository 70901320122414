import Icon from '@mdi/react';
import { StringUtils } from '../Shared/StringUtils';
import { mdiHelp } from '@mdi/js';

export const TextWithIcon = (props: ITextWithIcon) => {
    const text = props.shouldCapitalizeFirstLetter ? StringUtils.capitalizeFirstLetter(props.text) : props.text;
    const size = props.size ?? 1;
    return (
        <>
            <span className={props.className ?? ''}>
                <Icon path={props.icon ?? mdiHelp} size={size} title={props.title} className={props.iconClass} /> {text}
            </span>
        </>
    );
};

export const StyledText = (props: IStyledText) => {
    const text = props.shouldCapitalizeFirstLetter ? StringUtils.capitalizeFirstLetter(props.text) : props.text;
    return (
        <>
            <span className={props.className ?? ''}>{text}</span>
        </>
    );
};

export interface ITextWithIcon {
    text: string;
    icon?: string | null;
    iconClass?: string;
    className?: string | null;
    title?: string;
    size?: number;
    shouldCapitalizeFirstLetter?: boolean | null;
}

export interface IStyledText {
    text: string;
    className?: string | null;
    shouldCapitalizeFirstLetter?: boolean | null;
}
