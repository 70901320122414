import { useEffect, useState } from 'react';
import { ConfirmationModal, VerbiageType } from '../Shared/ConfirmationModal';
import { Row } from 'react-bootstrap';
import { ValidatableSelect } from '../Shared/ValidatableSelect';
import { SelectOptionString } from '../Types/SelectOption';
import { ApplicationEndpoint, EnvironmentType } from '../../../../api/models';
import { ValidatableInput } from '../Shared/ValidatableInput';
import { Loading } from '../Shared/Loading';
import { ActionButton } from '../UIExtensions/Buttons';
import { mdiPencil, mdiPlus } from '@mdi/js';

export interface EndpointsCreateEditModalProps {
    applicationId: string;
    applicationEndpoint?: ApplicationEndpoint;
    availableEnvironmentTypes?: Array<EnvironmentType>;
    canPerformActions: boolean;
    handler: (applicationId: string, environment: string, endpoint: string, endpointId?: string) => Promise<boolean>;
}

export function EndpointsCreateEditModal({ applicationId, applicationEndpoint, availableEnvironmentTypes, canPerformActions, handler }: EndpointsCreateEditModalProps) {
    const [environmentOptions, setEnvironmentOptions] = useState(new Array<SelectOptionString>());
    const [environment, setEnvironment] = useState(applicationEndpoint?.environment ?? '');
    const [environmentValid, setEnvironmentValid] = useState(false);
    const [endpoint, setEndpoint] = useState(applicationEndpoint?.endpoint ?? '');
    const [endpointValid, setEndpointValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showCreate, setShowCreate] = useState(false);
    const isEdit = applicationEndpoint !== undefined;

    useEffect(() => {
        if (availableEnvironmentTypes) {
            const options = availableEnvironmentTypes?.map((environemntType: EnvironmentType) => new SelectOptionString(environemntType.name, environemntType.id));
            setEnvironmentOptions(options);
            setIsLoading(false);
        }
    }, [availableEnvironmentTypes]);

    const canConfirm = environmentValid && endpointValid;

    return (
        <>
            {isEdit ? (
                <ActionButton disabled={!canPerformActions} mdiIcon={mdiPencil} variant="btn-dark-blue" title="Edit endpoint" onClick={async () => setShowCreate(true)} />
            ) : (
                <ActionButton
                    disabled={!canPerformActions}
                    outlined={true}
                    mdiIcon={mdiPlus}
                    size={1}
                    variant="btn-green container-fluid"
                    title="Add new endpoint"
                    onClick={async () => setShowCreate(true)}
                />
            )}
            <ConfirmationModal
                show={showCreate}
                enabled={canConfirm}
                title="Endpoint"
                verbiageType={VerbiageType.OkCancel}
                onCancel={() => setShowCreate(false)}
                onConfirm={async () => {
                    if (await handler(applicationId, environment, endpoint, applicationEndpoint?.id)) {
                        setShowCreate(false);
                    }
                }}
            >
                {isLoading ? (
                    <Loading />
                ) : (
                    <Row>
                        <ValidatableSelect
                            label={'Environment*'}
                            options={environmentOptions}
                            disabled={false}
                            onChange={(value) => {
                                setEnvironment(value ?? '');
                            }}
                            validate={(value) => {
                                let errors = [];
                                if (!value || value.length === 0) {
                                    errors.push('Environment must be selected.');
                                }
                                setEnvironmentValid(errors.length === 0);
                                return errors;
                            }}
                            selectedValue={environment}
                        />
                        <ValidatableInput
                            fieldName={'Endpoint*'}
                            disabled={false}
                            value={endpoint}
                            placeholder={'https://dev.testapp.quad.com'}
                            onChange={(value: string) => {
                                setEndpoint(value);
                            }}
                            validate={(value: string) => {
                                let errors = [];
                                if (value === undefined || value.length === 0) {
                                    errors.push('Endpoint is a required field.');
                                }
                                setEndpointValid(errors.length === 0);
                                return errors;
                            }}
                        />
                    </Row>
                )}
            </ConfirmationModal>
        </>
    );
}
