import { Col, Row } from 'react-bootstrap';
import { ActionButton } from '../../UIExtensions/Buttons';
import { AttributeType, AttributeValue } from '../../../../../api/models';
import { useMemo } from 'react';
import { CodeTypeSort } from '../../Attributes/CodeTypeSort';
import { mdiPencilBox } from '@mdi/js';

interface ApplicationAttributesProps {
    applicationAttributes: Map<AttributeType, AttributeValue[]>;
    attributeTypes: AttributeType[];
    canEdit: boolean;
    setEditMode: (editMode: boolean) => void;
}

export const ApplicationAttributes = ({ applicationAttributes, attributeTypes, canEdit, setEditMode }: ApplicationAttributesProps) => {
    const sortedAttributeTypes = useMemo(() => {
        return attributeTypes.sort(CodeTypeSort);
    }, [attributeTypes]);
    return (
        <>
            <Row>
                <Col md={11}>
                    {sortedAttributeTypes.map((attributeType) => {
                        const applicationAttributesForType = applicationAttributes.get(attributeType) ?? new Array<AttributeValue>();
                        const atttibuteNames = applicationAttributesForType.map((attributeValue) => attributeValue.name).join(', ');
                        return (
                            <Row key={`attribute-type-${attributeType.name}`}>
                                <Col sm={2}>{attributeType.name}</Col>
                                <Col>{atttibuteNames}</Col>
                            </Row>
                        );
                    })}
                </Col>
                {canEdit ? (
                    <Col style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'flex-start' }}>
                        <ActionButton
                            title={'Edit Attributes'}
                            mdiIcon={mdiPencilBox}
                            size={2}
                            outlined={true}
                            variant={'primary btn-white'}
                            onClick={async () => setEditMode(true)}
                        />
                    </Col>
                ) : (
                    <></>
                )}
            </Row>
        </>
    );
};
