import { Button, ButtonGroup, Panel, Steps } from 'rsuite';
import ReviewIcon from '@rsuite/icons/Review';
import React, { useMemo, useRef, useState } from 'react';
import NextIcon from '@rsuite/icons/PageNext';
import PreviousIcon from '@rsuite/icons/PagePrevious';
import 'rsuite/dist/rsuite.min.css';

import {
    Application,
    AppType,
    ConsolidateApplicationError,
    ConsolidateApplicationRequest,
    ConsolidateApplicationResponse,
    ConsolidatePrimaryApplicationRequest,
    System,
    Team,
} from '../../../../api/models';
import { StepState } from './Steps/StepState';
import { useParams } from 'react-router';
import { BasicInfoStep } from './Steps/BasicInfo/BasicInfoStep';
import { DuplicateAppsStep } from './Steps/DuplicateApps/DuplicateAppsStep';
import { ReviewStep } from './Steps/Review/ReviewStep';
import ScrollPageContent from '../Shared/ScrollPageContent';

interface ConsolidationWizardProps {}

enum Step {
    BasicInfoStep,
    DuplicateAppsStep,
    ReviewStep,
}

export interface ConsolidationWizardModel {
    consolidateRequest: ConsolidateApplicationRequest;
    consolidateResponse: ConsolidateApplicationResponse;
    primaryApplication: Application;
    duplicateApplications: Application[] | undefined;
    appTypes: AppType[];
    deliveryTeams: Team[];
    systems: System[];
}

export const ConsolidationWizard = ({ ...props }: ConsolidationWizardProps) => {
    const appUniqueName = useParams<'uniqueName'>().uniqueName!;
    const stepRef = useRef<() => Promise<boolean>>();
    const [currentStep, setStep] = useState<Step>(Step.BasicInfoStep);
    const [currentStatus, setCurrentStatus] = useState<StepState>('process');

    const [formModel, setFormModel] = useState<ConsolidationWizardModel>({
        consolidateRequest: {
            primaryApp: {} as ConsolidatePrimaryApplicationRequest,
            whatIf: true,
            duplicateAppIds: [],
        } as ConsolidateApplicationRequest,
        consolidateResponse: {
            errors: [] as ConsolidateApplicationError[],
        } as ConsolidateApplicationResponse,
        primaryApplication: {} as Application,
        duplicateApplications: undefined,
        appTypes: [] as AppType[],
        deliveryTeams: [] as Team[],
        systems: [] as System[],
    });

    const pageMap: Record<Step, React.JSX.Element> = useMemo(() => {
        return {
            0: <BasicInfoStep ref={stepRef} setCurrentStatus={setCurrentStatus} formModel={formModel} setFormModel={setFormModel} appUniqueName={appUniqueName} />,
            1: <DuplicateAppsStep ref={stepRef} setCurrentStatus={setCurrentStatus} formModel={formModel} setFormModel={setFormModel} />,
            2: <ReviewStep ref={stepRef} setCurrentStatus={setCurrentStatus} formModel={formModel} setFormModel={setFormModel} />,
        };
    }, [formModel, appUniqueName]);

    const onNext = async () => {
        let valid = false;
        if (stepRef?.current) {
            valid = await stepRef?.current();
        }

        if (valid) {
            setCurrentStatus('process');
            setStep(currentStep + 1);
        }
    };
    const onPrevious = () => {
        setCurrentStatus('process');
        setStep(currentStep - 1);
    };

    return (
        <ScrollPageContent>
            <Panel shaded style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
                <h3>{`${appUniqueName} Consolidation Wizard`}</h3>
                <Panel header={`${'Step'}: ${currentStep + 1}`} style={{ overflow: 'visible' }}>
                    {pageMap[currentStep]}
                    <hr />
                    <Steps current={currentStep} currentStatus={currentStatus}>
                        <Steps.Item title={'Edit Basic Info'} />
                        <Steps.Item title={'Select Apps to Consolidate'} />
                        <Steps.Item title={'Review'} icon={<ReviewIcon />} />
                    </Steps>
                    <hr />
                    <ButtonGroup style={{ float: 'right' }}>
                        <Button onClick={onPrevious} disabled={currentStep === Step.BasicInfoStep}>
                            <PreviousIcon className="me-2" />
                            {'Previous'}
                        </Button>
                        {currentStep !== Step.ReviewStep ? (
                            <Button onClick={onNext} appearance="primary">
                                {'Next'}
                                <NextIcon className="ms-2" />
                            </Button>
                        ) : (
                            <Button onClick={onNext} appearance="primary">
                                {'Finish'}
                                <ReviewIcon className="ms-2" />
                            </Button>
                        )}
                    </ButtonGroup>
                </Panel>
            </Panel>
        </ScrollPageContent>
    );
};
