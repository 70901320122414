import { Col, Row } from 'react-bootstrap';
import { System } from '../../../../api/models';
import { ActionButton } from '../UIExtensions/Buttons';
import { mdiPencilBox } from '@mdi/js';

interface SystemBasicInfoProps {
    system?: System;
    canEdit: boolean;
    showEditCallback: () => void;
}

export const SystemsBasicInfo = ({ system, canEdit, showEditCallback }: SystemBasicInfoProps) => {
    return (
        <>
            <Row>
                <Col md={7}>
                    <Row>
                        <Col sm={4}>Id:</Col>
                        <Col>{system?.id}</Col>
                    </Row>
                    <Row>
                        <Col sm={4}>Display name:</Col>
                        <Col>{system?.displayName}</Col>
                    </Row>
                    <Row>
                        <Col sm={4}>IT Owner Display Name:</Col>
                        <Col>{system?.itOwner.displayName}</Col>
                    </Row>
                    <Row>
                        <Col sm={4}>Description:</Col>
                        <Col>{system?.description}</Col>
                    </Row>
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'flex-start' }}>
                    <ActionButton
                        disabled={!canEdit}
                        title={'Edit Basic info'}
                        mdiIcon={mdiPencilBox}
                        size={2}
                        outlined={true}
                        variant={'primary btn-white'}
                        onClick={async () => showEditCallback()}
                    />
                </Col>
            </Row>
        </>
    );
};
