import { Application, ApplicationAttribute, AppSource, AppRegistration, DeliveryTeam, System, ApplicationDeployment } from '../../../../api/models';

export class DefaultApplication implements Application {
    id: string;
    uniqueName: string;
    displayName: string;
    description: string;
    state: string;
    type: string;
    deliveryTeamId?: string;
    deliveryTeam?: DeliveryTeam;
    appRegistrations: AppRegistration[];
    sources: AppSource[];
    attributes: ApplicationAttribute[];
    systemId?: string;
    system?: System;
    deployments: ApplicationDeployment[];

    constructor() {
        this.id = '';
        this.uniqueName = '';
        this.displayName = '';
        this.description = '';
        this.state = 'active';
        this.type = '';
        this.deliveryTeamId = undefined;
        this.deliveryTeam = undefined;
        this.appRegistrations = new Array<AppRegistration>();
        this.sources = new Array<AppSource>();
        this.attributes = new Array<ApplicationAttribute>();
        this.systemId = undefined;
        this.system = undefined;
        this.deployments = new Array<ApplicationDeployment>();
    }
}
