import { PropsWithChildren } from 'react';
import { Alert } from 'react-bootstrap';
import { AlertVariant } from '../Types/AlertVariant';
import Icon from '@mdi/react';
import { mdiAlertCircleOutline, mdiCheckCircleOutline, mdiCloseCircleOutline, mdiInformationOutline } from '@mdi/js';

export type ActionAlertProps = PropsWithChildren<{
    variant: AlertVariant;
    key: string;
}>;

function GetIcon(variant: AlertVariant): JSX.Element {
    switch (variant) {
        case 'info':
            return <Icon path={mdiInformationOutline} size={1}></Icon>;
        case 'warning':
            return <Icon path={mdiAlertCircleOutline} size={1}></Icon>;
        case 'danger':
            return <Icon path={mdiCloseCircleOutline} size={1}></Icon>;
        default:
            return <Icon path={mdiCheckCircleOutline} size={1}></Icon>;
    }
}

export function ActionAlert(props: ActionAlertProps) {
    const variant = props.variant;
    const icon = GetIcon(variant);

    return (
        <Alert variant={variant} style={{ marginBottom: '0px' }}>
            {icon}
            &nbsp; &nbsp;
            {props.children}
        </Alert>
    );
}
