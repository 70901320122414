import { useEffect, useMemo, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ValidationErrors } from './ValidationErrors';
import { SelectOption } from '../Types/SelectOption';

interface ValidatableSelectProps<T> {
    disabled: boolean;
    label: string;
    options: SelectOption<T>[];
    selectedValue: T | undefined;
    externalErrors?: string[];
    clearable?: boolean;
    creatable?: boolean;
    validate?: (value: T | undefined) => string[];
    onChange: (value: T | undefined) => void;
}
export function ValidatableSelect<T>({ disabled, label, options, selectedValue, externalErrors, clearable, creatable, validate, onChange }: ValidatableSelectProps<T>) {
    const [errors, setErrors] = useState(new Array<string>());

    const onChangeInternal = async function (value: T | undefined) {
        if (validate) {
            setErrors(validate(value));
        }
        onChange(value);
    };

    const selectedOption = useMemo(() => {
        const result = options?.find((option: SelectOption<T>) => selectedValue === option.value);
        return result;
    }, [options, selectedValue]);

    useEffect(() => {
        if (externalErrors) {
            setErrors(externalErrors);
        }
    }, [externalErrors]);

    useEffect(() => {
        if (validate) {
            setErrors(validate(selectedValue!));
        }
    }, [selectedValue, validate]);

    const className = `required ${errors.length === 0 ? '' : 'validation-error'}`;
    const SelectComponent = creatable ? CreatableSelect : Select;
    return (
        <>
            <div className="form-group">
                <label>{label}</label>
                <SelectComponent
                    isClearable={clearable}
                    isDisabled={disabled}
                    className={className}
                    options={options}
                    onChange={async (value: SingleValue<SelectOption<T>>) => {
                        await onChangeInternal(value?.value);
                    }}
                    value={selectedOption}
                />
                <ValidationErrors errors={errors} />
            </div>
        </>
    );
}
