import { useEffect, useState } from 'react';

import {
    ColumnDef,
    ColumnFiltersState,
    RowData,
    SortingState,
    VisibilityState,
    flexRender,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';

import { Pagination } from './Pagination';
import { Filter } from './Filter';
import { Table } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiSortAscending, mdiSortDescending } from '@mdi/js';

declare module '@tanstack/table-core' {
    interface ColumnMeta<TData extends RowData, TValue> {
        filterVariant?: 'text' | 'text-autocomplete' | 'range' | 'select' | 'none';
        className?: string;
    }
}

interface ReactTableProps<T> {
    columns: ColumnDef<T, any>[];
    columnsVisibility?: VisibilityState;
    initialSorting?: SortingState;
    usePagination?: boolean;
    tableData: T[];
}

export function ReactTable<T>({ columns, columnsVisibility, initialSorting, usePagination, tableData }: ReactTableProps<T>) {
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [columnVisibility] = useState<VisibilityState>(columnsVisibility ?? {});
    const [sorting] = useState<SortingState>(initialSorting ?? []);
    const [pagination] = useState({
        pageIndex: 0,
        pageSize: usePagination ? 100 : Number.MAX_VALUE,
    });

    const [data, setData] = useState<T[]>(() => tableData);

    useEffect(() => {
        setData(tableData);
    }, [tableData]);

    const table = useReactTable({
        data,
        columns,
        filterFns: {},
        initialState: {
            sorting,
            pagination,
            columnVisibility,
        },
        state: {
            columnFilters,
        },
        onColumnFiltersChange: setColumnFilters,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(), // client-side faceting
        getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
        getFacetedMinMaxValues: getFacetedMinMaxValues(), // generate min/max values for range filter
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    });

    return (
        <>
            <div className="table-wrapper">
                <Table striped={true} className="table-fixed-header">
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th key={header.id} colSpan={header.colSpan} scope="col" className={header.column.columnDef.meta?.className ?? ''}>
                                            {header.isPlaceholder ? null : (
                                                <>
                                                    <div
                                                        {...{
                                                            className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                                                            onClick: header.column.getToggleSortingHandler(),
                                                        }}
                                                    >
                                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                                        {{
                                                            asc: (
                                                                <>
                                                                    {' '}
                                                                    <Icon path={mdiSortAscending} size={0.8} className="icon-dark-blue" />
                                                                </>
                                                            ),
                                                            desc: (
                                                                <>
                                                                    {' '}
                                                                    <Icon path={mdiSortDescending} size={0.8} className="icon-dark-blue" />
                                                                </>
                                                            ),
                                                        }[header.column.getIsSorted() as string] ?? null}
                                                    </div>
                                                    {header.column.getCanFilter() ? (
                                                        <div>
                                                            <Filter column={header.column} />
                                                        </div>
                                                    ) : null}
                                                </>
                                            )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </div>
            {usePagination ? <Pagination table={table} /> : <></>}
        </>
    );
}
