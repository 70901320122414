import { PropsWithChildren } from 'react';
import { Button, CloseButton, Modal } from 'react-bootstrap';

export enum VerbiageType {
    YesNo = 'YesNo',
    OkCancel = 'OkCancel',
    SaveCancel = 'SaveCancel',
}

export type ConfirmationModalProps = PropsWithChildren<{
    show: boolean;
    enabled: boolean;
    title: String;
    onCancel: () => void;
    onConfirm: () => void;
    onEntered?: () => void;
    verbiageType: VerbiageType;
}>;

export function ConfirmationModal(props: ConfirmationModalProps) {
    const setVerbiage = (isConfirmOperation: boolean) => {
        switch (props.verbiageType) {
            case VerbiageType.SaveCancel: {
                return isConfirmOperation ? 'Save' : 'Cancel';
            }
            case VerbiageType.YesNo: {
                return isConfirmOperation ? 'Yes' : 'No';
            }
            default: {
                return isConfirmOperation ? 'Ok' : 'Cancel';
            }
        }
    };

    return (
        <>
            <Modal size="lg" centered show={props.show} onEntered={props.onEntered}>
                <Modal.Header>
                    <Modal.Title>{props.title}</Modal.Title>
                    <CloseButton onClick={() => props.onCancel()} />
                </Modal.Header>
                <Modal.Body>{props.children}</Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => props.onCancel()}>
                        {setVerbiage(false)}
                    </Button>
                    <Button variant="primary" className={`${props.enabled ? '' : 'disabled'}`} onClick={() => props.onConfirm()}>
                        {setVerbiage(true)}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
