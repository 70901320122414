import { Application, AppType, AppState } from '../../../../../api/models';
import { useEffect, useState, useCallback } from 'react';
import AppRegistryClient from '../../../../../api/AppRegistryClient';
import { Link } from 'react-router-dom';
import { Loading } from '../../Shared/Loading';
import { mdiTrashCan } from '@mdi/js';
import { ActionButton } from '../../UIExtensions/Buttons';
import { ApplicationPreview } from '../../Applications/ApplicationPreview';
import { Table } from 'react-bootstrap';
import { EmptyDataRow } from '../../UIExtensions/TableUtilities';
import { ConsolidationDependencies } from './ConsolidationDependencies';

export interface ConsolidationTableProps {
    isLoading: boolean;
    data: Application[];
    onRowDelete?: (app: Application | undefined) => void;
}

interface ConsolidationExpanded extends Application {
    appType?: AppType;
    appState?: AppState;
}

export const ConsolidationTable = ({ isLoading, data, onRowDelete }: ConsolidationTableProps) => {
    const [dataIsLoading, setDataIsLoading] = useState(true);
    const [appTypes, setAppTypes] = useState<AppType[]>([]);
    const [applications, setApplications] = useState<ConsolidationExpanded[]>([]);

    const getAppTypes = useCallback(async () => {
        try {
            const result = await AppRegistryClient.getAllAppTypes();
            setAppTypes(result);
        } catch {
            setAppTypes([]);
        }
    }, []);

    const loadData = useCallback(async () => {
        await getAppTypes();
        setDataIsLoading(false);
    }, [getAppTypes]);

    const setExtendedProperties = useCallback(
        function (applications: Application[]): ConsolidationExpanded[] {
            return applications.map((application) => {
                const appType = appTypes.find((appType) => appType.id === application.type);
                return {
                    ...application,
                    appType: appType,
                };
            });
        },
        [appTypes]
    );

    useEffect(() => {
        if (dataIsLoading) {
            loadData();
        }
    }, [dataIsLoading, loadData]);

    useEffect(() => {
        if (!isLoading && !dataIsLoading) {
            setApplications(setExtendedProperties(data));
        }
    }, [isLoading, dataIsLoading, setExtendedProperties, data]);

    if (isLoading || dataIsLoading) {
        return <Loading />;
    }
    return (
        <div className="table-wrapper" style={{ marginBottom: '10px' }}>
            <Table striped={true}>
                <thead className="thead-white">
                    <tr>
                        <th scope="col" className="col-2">
                            Name
                        </th>
                        <th scope="col" className="col-2">
                            Display Name
                        </th>
                        <th scope="col" className="col-2">
                            Type
                        </th>
                        <th scope="col" className="col-2">
                            Delivery Team
                        </th>
                        <th scope="col" className="col-2">
                            System
                        </th>
                        <th scope="col" className="col-1">
                            Dependencies
                        </th>
                        <th scope="col" className="col-1">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {applications.length > 0 ? (
                        applications.map((a) => {
                            return (
                                <tr key={`candidateKey-${a.id}`}>
                                    <td>
                                        <Link to={`/applications/${a.uniqueName}`}>{a.uniqueName}</Link>
                                    </td>
                                    <td>{a.displayName}</td>
                                    <td>{a.appType?.name}</td>
                                    <td>{a.deliveryTeam?.name}</td>
                                    <td>{a.system?.displayName}</td>
                                    <td>
                                        <ConsolidationDependencies application={a} />
                                    </td>
                                    <td>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <ApplicationPreview application={a} />
                                            {onRowDelete ? (
                                                <ActionButton
                                                    disabled={false}
                                                    title={'Remove from candlidate list'}
                                                    outlined={false}
                                                    size={1}
                                                    mdiIcon={mdiTrashCan}
                                                    variant={'btn-red container-fluid'}
                                                    onClick={async () => {
                                                        if (onRowDelete) {
                                                            onRowDelete(a);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <EmptyDataRow colSpan={12} text="No candidates selected" />
                    )}
                </tbody>
            </Table>
        </div>
    );
};
