import { mdiTrashCan } from '@mdi/js';
import { CodeType } from '../../../../api/models';
import { ActionButton } from '../UIExtensions/Buttons';
import { ConfirmationModal, VerbiageType } from '../Shared/ConfirmationModal';
import { useState } from 'react';
import { ActionAlert } from '../Shared/ActionAlert';

interface CodeTypeDeleteProps<T extends CodeType> {
    disabled: boolean;
    codeName: string;
    codeType: T;
    onDelete: (type: T) => Promise<Map<string, string[]> | undefined>;
}

export function CodeTypeDelete<T extends CodeType>({ disabled, codeName, codeType, onDelete }: CodeTypeDeleteProps<T>) {
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const key = `delete-${codeName}-alert-${codeType.id}`;
    return (
        <>
            <ActionButton
                disabled={disabled}
                mdiIcon={mdiTrashCan}
                variant="btn-red"
                title="Delete"
                onClick={() => {
                    setShowConfirmationDialog(true);
                }}
            />
            <ConfirmationModal
                show={showConfirmationDialog}
                enabled={true}
                title={'Delete'}
                verbiageType={VerbiageType.YesNo}
                onCancel={() => setShowConfirmationDialog(false)}
                onConfirm={async () => {
                    if (await onDelete(codeType)) {
                        setShowConfirmationDialog(false);
                    }
                }}
            >
                <ActionAlert key={key} variant="danger">
                    Are you sure that you want to &nbsp;
                    <b>remove {codeType.name}</b>
                    &nbsp;?
                </ActionAlert>
            </ConfirmationModal>
        </>
    );
}
