import { PageHeading } from '@quad/bootstrap-react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import ScrollPageContent from './components/Shared/ScrollPageContent';
import AppRegistryClient from '../../api/AppRegistryClient';
import { useState, useEffect, useCallback } from 'react';
import { Application, ODataQuery } from '../../api/models';
import { NavBar, NavBarItem } from './components/Shared/NavBar';
import { UserPrincipal } from './components/Types/UserPrincipal';
import Icon from '@mdi/react';
import { ActionButton } from './components/UIExtensions/Buttons';
import { Permission } from './components/Permissions/permission';
import { mdiPlus } from '@mdi/js';
import { useNavigate } from 'react-router';
import { ApplicationsTable } from './components/Applications/ApplicationsTable';

enum NavBarItems {
    MyApps = 'my-apps',
    AllApps = 'all-apps',
}

interface ApplicationsProps {
    type?: string;
    title: string;
    mdiIcon: string;
}

export function Applications(props: ApplicationsProps) {
    const title = props.title;
    const icon = props.mdiIcon;
    const maxAppsPerPage = 1000;
    const selectCriteria = 'id, uniqueName, displayName, state, type, deliveryTeamId';

    const [allApplications, setAllApplications] = useState(Array<Application>());
    const [allAppsAreLoading, setAllAppsAreLoading] = useState(true);
    const [myApplications, setMyApplications] = useState(Array<Application>());
    const [myAppsAreLoading, setMyAppsAreLoading] = useState(true);
    const [userPrincipal, setUserPrincipal] = useState<UserPrincipal>(new UserPrincipal());
    const [selectedTab, setSelectedTab] = useState(NavBarItems.MyApps.toString());

    const loadAllApps = useCallback(async () => {
        try {
            const apps = await loadApps(AppRegistryClient.applications.get);
            setAllApplications(apps);
        } finally {
            setAllAppsAreLoading(false);
        }
    }, []);

    const loadUserApps = useCallback(async () => {
        try {
            const apps = await loadApps(AppRegistryClient.myApplications.get);
            setMyApplications(apps);
        } finally {
            setMyAppsAreLoading(false);
        }
    }, []);

    const loadApps = async (getApps: (criteria: ODataQuery) => Promise<Application[]>) => {
        let apps: Application[] = [];
        let skipIndex = 0;
        let hasMoreApps = true;

        while (hasMoreApps) {
            const newApps = await getApps({
                select: selectCriteria,
                top: maxAppsPerPage,
                skip: skipIndex,
                expand: `deliveryTeam($select=name)`,
                orderBy: 'displayName,id',
            });
            apps = apps.concat(newApps);
            skipIndex += maxAppsPerPage;

            if (newApps.length !== maxAppsPerPage) {
                hasMoreApps = false;
            }
        }

        return apps;
    };

    useEffect(() => {
        if (allAppsAreLoading && selectedTab === NavBarItems.AllApps) {
            loadAllApps();
        }
    }, [allAppsAreLoading, loadAllApps, selectedTab]);

    useEffect(() => {
        if (myAppsAreLoading && selectedTab === NavBarItems.MyApps) {
            loadUserApps();
        }
    }, [loadUserApps, myAppsAreLoading, selectedTab]);

    const getUserPermissions = async () => {
        var tempUserPermissions = await AppRegistryClient.userPermissions.getRootPermissions();
        var user = new UserPrincipal(tempUserPermissions);
        setUserPrincipal(user);
    };

    const createEnabled = userPrincipal?.hasPermission(Permission.Application_Create);

    const navigate = useNavigate();

    useEffect(() => {
        getUserPermissions();
    }, []);

    return (
        <ScrollPageContent>
            <PageHeading>
                <div className="col">
                    <h1>
                        <Icon path={icon} size={1.8} /> {title}
                    </h1>
                </div>
            </PageHeading>

            <Container>
                <Row>
                    <Col className="col-11">
                        <NavBar>
                            <NavBarItem target={NavBarItems.MyApps.toString()} active={true} onClick={setSelectedTab}>
                                <i className="fa fa-star"></i>
                                <span> My Applications </span>
                                <Badge className="row-count">{myAppsAreLoading ? '?' : myApplications.length}</Badge>
                            </NavBarItem>
                            <NavBarItem target={NavBarItems.AllApps.toString()} onClick={setSelectedTab}>
                                <span> All Applications </span>
                                <Badge className="row-count">{allAppsAreLoading ? '?' : allApplications.length}</Badge>
                            </NavBarItem>
                        </NavBar>
                    </Col>
                    <Col className="col-1 center">
                        <Container>
                            <ActionButton
                                disabled={!createEnabled}
                                title={'Add application'}
                                outlined={true}
                                size={1.25}
                                mdiIcon={mdiPlus}
                                variant={'btn-green container-fluid'}
                                onClick={async () => {
                                    const url = `/applications/new`;
                                    navigate(url);
                                }}
                            />
                        </Container>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="tab-pane fade show active container"
                                id={NavBarItems.MyApps.toString()}
                                role="tabpanel"
                                aria-labelledby={`${NavBarItems.MyApps.toString()}-tab`}
                            >
                                <ApplicationsTable isLoading={myAppsAreLoading} data={myApplications} consolidation={true} />
                            </div>
                            <div className="tab-pane fade container" id={NavBarItems.AllApps.toString()} role="tabpanel" aria-labelledby={`${NavBarItems.AllApps.toString()}-tab`}>
                                <ApplicationsTable isLoading={allAppsAreLoading} data={allApplications} consolidation={false} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </ScrollPageContent>
    );
}
