import { Col, Row } from 'react-bootstrap';
import { useState } from 'react';
import { Department } from '../../../../api/models';
import { DepartmentsTable } from './DepartmentsTable';
import { DepartmentTeamsTable } from './DepartmentTeamsTable';

export const DepartmentDetails = () => {
    const [selectedItem, setSelectedItem] = useState({} as Department);
    return (
        <Row>
            <Col>
                <DepartmentsTable onSelectedChanged={setSelectedItem} />
            </Col>
            <Col>
                <DepartmentTeamsTable selectedDepartment={selectedItem} />
            </Col>
        </Row>
    );
};
