import { useState, useEffect, useCallback } from 'react';
import { Application, ApplicationPermission } from '../../../../api/models';
import appRegistryClient from '../../../../api/AppRegistryClient';
import { PermissionsEnhancedTable } from './PermissionsEnhancedTable';
import { UserPrincipal } from '../Types/UserPrincipal';
import { tryCallApi } from '../../../../api/Helpers';
import { PermissionResource } from './PermissionResource';

interface AppPermissionsProps {
    app?: Application;
    user: UserPrincipal;
    isSelected: boolean;
}
export function AppPermissionsTab({ app, user, isSelected }: AppPermissionsProps) {
    const [dataIsLoading, setDataIsLoading] = useState(true);
    const [permissions, setPermissions] = useState(Array<ApplicationPermission>());
    const [availableRoles, setAvailableRoles] = useState<Array<string>>();

    const loadPermissions = useCallback(async () => {
        let permissionResult = new Array<ApplicationPermission>();
        const success = await tryCallApi(async () => {
            permissionResult = await appRegistryClient.applicationsPermissions.get(app!.id);
        });
        if (success) {
            setPermissions(permissionResult);
        }
    }, [app]);

    const loadAvailableRoles = useCallback(async () => {
        let roles = {} as { [key: string]: string };
        const success = await tryCallApi(async () => {
            roles = await appRegistryClient.applicationRoles.get();
        });
        if (success) {
            const options = Object.values(roles).filter((role: string) => user.hasPermissionForRole(PermissionResource.Application, role));
            setAvailableRoles(options);
        }
    }, [user]);

    const loadData = useCallback(async () => {
        await loadPermissions();
        await loadAvailableRoles();
        setDataIsLoading(false);
    }, [loadPermissions, loadAvailableRoles]);

    useEffect(() => {
        if (isSelected && dataIsLoading && app?.id !== undefined) {
            loadData();
        }
    }, [isSelected, dataIsLoading, app?.id, loadData]);

    const changeHandler = async () => {
        setDataIsLoading(true);
        await loadPermissions();
        setDataIsLoading(false);
    };

    const createHandler = async (id: string, groupId: string, role: string): Promise<boolean> => {
        const success = await tryCallApi(async () => {
            await appRegistryClient.applicationsPermissions.post(id, groupId, role);
        });
        changeHandler();
        return success;
    };

    const deleteHandler = async (id: string, groupId: string, role: string): Promise<boolean> => {
        const success = await tryCallApi(async () => {
            await appRegistryClient.applicationsPermissions.delete(id, groupId, role);
        });
        changeHandler();
        return success;
    };

    return (
        <PermissionsEnhancedTable
            id={app?.id}
            data={permissions}
            isLoading={dataIsLoading}
            availableRoles={availableRoles}
            deleteHandler={deleteHandler}
            createHandler={createHandler}
        />
    );
}
