import ClientBase from './ClientBase';
import { ApiRequest } from './models';
import { getAccessToken } from '.';

class MsalClientBase extends ClientBase {
    constructor(protected baseUrl: string, protected scopes: string[]) {
        super(baseUrl);
    }

    protected fetchJsonNullable = async <T>(request: ApiRequest): Promise<T | undefined> => {
        const rewrote = { ...request, accessToken: await getAccessToken(this.scopes) };

        return await this.appFetchJsonNullable<T>(rewrote);
    };

    protected fetchJson = async <T>(request: ApiRequest): Promise<T> => {
        const rewrote = { ...request, accessToken: await getAccessToken(this.scopes) };

        return await this.appFetchJson<T>(rewrote);
    };

    protected fetch = async (request: ApiRequest) => {
        const rewrote = { ...request, accessToken: await getAccessToken(this.scopes) };

        return await this.appFetch(rewrote);
    };
}

export default MsalClientBase;
