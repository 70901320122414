import { Department } from '../../../../api/models';

interface DepartmentsTableRowProps {
    department: Department;
    allDepartments: Department[];
    selected: boolean;
    onClick: () => void;
}

export const DepartmentsTableRow = ({ department, selected, onClick }: DepartmentsTableRowProps) => {
    const className = `${selected ? 'selected' : ''} ${department.isActive ? '' : 'line-through'}`;
    return (
        <tr className={className} onClick={() => onClick()}>
            <td>{department.name}</td>
            <td>{department.teams?.length}</td>
        </tr>
    );
};
