import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { Filter } from '../Shared/Filter';
import { useMemo, useState } from 'react';
import Icon from '@mdi/react';

interface NavigationProps {
    items: NavigationItem[];
    onSelectedItemChanged: (label: string | null) => void;
}

export interface NavigationItem {
    label: string;
    mdiIcon: string;
    targetElement: JSX.Element;
    active: boolean;
}

export const Navigation = ({ items, onSelectedItemChanged }: NavigationProps) => {
    const [searchValue, setSearchValue] = useState('');

    const filteredItems = useMemo(() => {
        const searchString = searchValue.trim().toLowerCase();
        if (searchString.length > 0) {
            return items.filter((item: NavigationItem) => item.label.toLowerCase().includes(searchString));
        }
        return items;
    }, [items, searchValue]);

    return (
        <>
            <Tab.Container defaultActiveKey={items[0].label} onSelect={onSelectedItemChanged}>
                <Row>
                    <Col sm={2}>
                        <Filter onUpdate={(value: string) => setSearchValue(value)}></Filter>
                        <Nav variant="pills" className="flex-column">
                            {filteredItems.map((item, index) => {
                                return (
                                    <Nav.Item key={`nav-item-${index}`}>
                                        <Nav.Link eventKey={`${item.label}`} className={item.active ? '' : 'disabled'}>
                                            <Row>
                                                <Col sm={2}>
                                                    <Icon path={item.mdiIcon} size={1} />
                                                </Col>
                                                <Col style={{ paddingTop: '1px' }}>{item.label}</Col>
                                            </Row>
                                        </Nav.Link>
                                    </Nav.Item>
                                );
                            })}
                        </Nav>
                    </Col>
                    <Col sm={10}>
                        <Tab.Content>
                            {items.map((item, index) => {
                                return (
                                    <Tab.Pane key={`tab-pane-${index}`} eventKey={`${item.label}`}>
                                        <Card>
                                            <Card.Body>{item.targetElement}</Card.Body>
                                        </Card>
                                    </Tab.Pane>
                                );
                            })}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </>
    );
};
