import { Card, Container } from 'react-bootstrap';
import { Navigation, NavigationItem } from '../Navigation/Navigation';
import BasePage from '../BasePage';
import { useParams } from 'react-router';
import appRegistryClient from '../../../../api/AppRegistryClient';
import { System } from '../../../../api/models';
import { useEffect, useState } from 'react';
import { mdiAccountSupervisor, mdiApplication, mdiClock, mdiInformation } from '@mdi/js';
import { SystemInfoWrapper } from './SystemInfoWrapper';
import { UserPrincipal } from '../Types/UserPrincipal';
import { NavigationItemLabels } from './NavigationItemLabels';

import { SystemApplicationsEnhancedTable } from './SystemApplications/SystemApplicationsEnhancedTable';
import { SystemPermissionsTab } from '../Permissions/SystemPermissionsTab';
import { SystemEventLog } from '../EventLog/SystemEventLog';
import { Permission } from '../Permissions/permission';

export const SystemDetails = () => {
    const sysId = useParams<'id'>().id!;
    const [sys, setSys] = useState<System>();
    const [isLoading, setIsLoading] = useState(true);
    const [canEdit, setCanEdit] = useState(false);
    const [user, setUser] = useState<UserPrincipal>(new UserPrincipal());
    const [editMode, setEditMode] = useState(false);
    const [selectedItem, setSelectedItem] = useState<String | null>();

    var navigationItems = [
        {
            label: NavigationItemLabels.BasicInfo,
            mdiIcon: mdiInformation,
            targetElement: (
                <SystemInfoWrapper isLoading={isLoading} system={sys} canEdit={canEdit} isEditMode={editMode} setEditCallback={(isEdit: boolean) => setEditMode(isEdit)} />
            ),
            active: true,
        },
        {
            label: NavigationItemLabels.Permissions,
            mdiIcon: mdiAccountSupervisor,
            targetElement: <SystemPermissionsTab system={sys} user={user} isSelected={selectedItem === NavigationItemLabels.Permissions} />,
            active: true,
        },
        {
            label: NavigationItemLabels.Events,
            mdiIcon: mdiClock,
            targetElement: <SystemEventLog sys={sys}></SystemEventLog>,
            active: true,
        },
        {
            label: NavigationItemLabels.Applications,
            mdiIcon: mdiApplication,
            targetElement: <SystemApplicationsEnhancedTable apps={sys?.applications} />,
            active: true,
        },
    ] as NavigationItem[];

    useEffect(() => {
        const getUserPermissions = async () => {
            if (sys) {
                var tempUserPermissions = await appRegistryClient.userPermissions.getSystemPermissions(sys.id);
                var user = new UserPrincipal(tempUserPermissions);
                setUser(user);
            }
        };

        getUserPermissions();
    }, [sys]);

    useEffect(() => {
        const checkEditPermission = () => {
            const canEdit = user.hasPermission(Permission.System_Update);
            setCanEdit(canEdit);
        };

        checkEditPermission();
    }, [user]);

    useEffect(() => {
        const getSys = async (id: string) => {
            try {
                const sysResult = await appRegistryClient.systems.get({
                    filter: `id eq '${id}'`,
                    top: 1,
                    expand: `itOwner($select=id,displayName),applications($select=uniqueName,displayName,state,type)`,
                });
                if (sysResult && sysResult.length !== 0) {
                    const system = sysResult[0];
                    setSys(system);
                }
            } finally {
                setIsLoading(false);
            }
        };
        getSys(sysId);
    }, [sysId]);

    return (
        <BasePage name={`${sys?.displayName} System Details`}>
            <Container fluid={true}>
                <Card>
                    <Card.Body>
                        <Navigation items={navigationItems} onSelectedItemChanged={setSelectedItem} />
                    </Card.Body>
                </Card>
            </Container>
        </BasePage>
    );
};
