export enum Permission {
    Application_Create = 'Application_Create',
    Application_Update = 'Application_Update',
    Application_Delete = 'Application_Delete',
    Deployment_Record = 'Deployment_Record',
    // Attribute Types
    AttributeTypesPermissionPrefix = 'AttributeType',
    // Attribute Values
    AttributeValuesPermissionPrefix = 'AttributeValue',
    System_Create = 'System_Create',
    System_Update = 'System_Update',
}
