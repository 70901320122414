import { useEffect, useState } from 'react';
import { AppRegConfigFormProps } from './AppRegTypes';
import appRegistryClient from '../../../../../api/AppRegistryClient';
import { ValidatableInput } from '../../Shared/ValidatableInput';

export function SpaConfigForm({ setCallback, setIsValid }: AppRegConfigFormProps) {
    const [redirectUris, setRedirectUris] = useState('');

    useEffect(() => {
        setCallback(() => (appId: string, appRegId: string) => {
            return appRegistryClient.appRegistrationConfigurations.toSpa(appId, appRegId, redirectUris.split('\n'));
        });
    }, [setCallback, redirectUris]);

    return (
        <>
            <ValidatableInput
                type="textarea"
                fieldName="Redirect URLs:"
                tooltipText="Enter redirect URLs on separate lines"
                rows={5}
                value={redirectUris}
                id="identifierUri"
                placeholder="Example:&#10;https://beta.myapp.quad.com&#10;https://localhost:3002"
                validate={(v) => {
                    const errors = [];
                    if (!v) {
                        errors.push('At least one URL is required');
                        setIsValid(false);
                    } else {
                        setIsValid(true);
                    }
                    return errors;
                }}
                onChange={(v) => {
                    setRedirectUris(v);
                }}
            />
            Click <strong>Assign</strong> to configure this app registration as a <strong>single-page application (SPA)</strong>.
        </>
    );
}
