import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Application } from '../../../../../../api/models';
import { Card, Col, Row } from 'react-bootstrap';
import { ConsolidationWizardModel } from '../../ConsolidationWizard';
import { ConsolidationTable } from '../../Shared/ConsolidationTable';
import { DuplicatesSearchModal } from './DuplicatesSearchModal';
import AppRegistryClient from '../../../../../../api/AppRegistryClient';
import { TextWithIcon } from '../../../UIExtensions/Text';
import { mdiWrenchCheck } from '@mdi/js';
import { ConsolidationValidationResult } from './ConsolidationValidationResult';
import { findConsolidationCandidates } from './FindConsolidationCandidates';
import { Loading } from '../../../Shared/Loading';

interface DuplicateAppsEditProps {
    formModel: ConsolidationWizardModel;
    setFormModel: React.Dispatch<React.SetStateAction<ConsolidationWizardModel>>;
}

export const DuplicateAppsEdit = forwardRef<(() => Promise<boolean>) | undefined, DuplicateAppsEditProps>(({ formModel, setFormModel }: DuplicateAppsEditProps, ref) => {
    const [dataIsLoading, setDataIsLoading] = useState(true);
    const [validationInProgress, setValidationInProgress] = useState(false);
    const [duplicateApplications, setDuplicateApplications] = useState<Application[]>(formModel.duplicateApplications ?? []);

    useImperativeHandle(ref, () => validate);
    const validate = async () => {
        return duplicateApplications.length > 0 && (await tryConsolidate());
    };

    const setDuplicates = useCallback(
        (apps: Application[]) => {
            const updatedModel = structuredClone(formModel);
            updatedModel.duplicateApplications = apps;
            updatedModel.consolidateRequest.duplicateAppIds = apps.map((app) => app.id);
            updatedModel.consolidateResponse.errors = [];
            setFormModel(updatedModel);

            setDuplicateApplications(apps);
        },
        [formModel, setFormModel]
    );

    const removeCandidate = async (application: Application | undefined) => {
        if (application !== undefined) {
            let tmpDuplicates = Array.from(duplicateApplications).filter((d) => d.id !== application?.id);
            setDuplicates(tmpDuplicates);
        }
    };

    const tryConsolidate = async () => {
        setValidationInProgress(true);
        let valid = false;
        try {
            const consolidateRequest = structuredClone(formModel.consolidateRequest);
            consolidateRequest.whatIf = true;
            const response = await AppRegistryClient.applications.consolidation(consolidateRequest);
            valid = response.errors === undefined || response.errors.length === 0;

            const updatedModel = structuredClone(formModel);
            updatedModel.consolidateResponse = response;
            setFormModel(updatedModel);
        } catch (e) {}
        setValidationInProgress(false);

        return valid;
    };

    const loadData = useCallback(async () => {
        try {
            if (formModel.duplicateApplications === undefined) {
                const candidates = await findConsolidationCandidates([formModel.primaryApplication.id], formModel.primaryApplication.displayName);
                setDuplicates(candidates ?? []);
            }
        } finally {
            setDataIsLoading(false);
        }
    }, [formModel.duplicateApplications, formModel.primaryApplication.id, formModel.primaryApplication.displayName, setDuplicates]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    if (dataIsLoading) {
        return <Loading />;
    }

    const validationErrors = formModel.consolidateResponse?.errors;
    const showValidationPanel = validationInProgress || validationErrors?.length > 0;
    return (
        <>
            <Card>
                <Card.Header>Apps to consolidate</Card.Header>
                <Card.Body>
                    <Row>
                        <Col className="col-2 center">
                            <DuplicatesSearchModal
                                app={formModel.primaryApplication}
                                duplicateAppIds={formModel.consolidateRequest.duplicateAppIds}
                                duplicatesSelected={(apps) => {
                                    const concated = Array.from(duplicateApplications).concat(apps);
                                    setDuplicates(concated);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ConsolidationTable isLoading={false} data={duplicateApplications} onRowDelete={removeCandidate} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <br />
            {showValidationPanel ? (
                <Card>
                    <Card.Header>
                        <TextWithIcon text="Validation Results" icon={mdiWrenchCheck} />
                    </Card.Header>
                    <Card.Body>
                        <ConsolidationValidationResult inProgress={validationInProgress} errors={validationErrors} />
                    </Card.Body>
                </Card>
            ) : (
                <></>
            )}
        </>
    );
});
