import Icon from '@mdi/react';
import { Button } from 'react-bootstrap';

interface ButtonWithIconProps {
    disabled?: boolean;
    spinning?: boolean;
    variant: string;
    onClick: Function;
    icon: string;
    text?: string;
}
export const ButtonWithIcon = (props: ButtonWithIconProps) => {
    let disabled = props.spinning || props.disabled;
    let icon = 'fa ' + (props.spinning ? 'fa-spinner fa-spin fa-fw' : props.icon);
    return (
        <Button disabled={disabled} variant={props.variant ?? 'secondary'} onClick={() => props.onClick()}>
            <i className={icon}></i>
            <>{props.text}</>
        </Button>
    );
};

interface ActionButtonProps {
    title: string;
    mdiIcon: string;
    variant: string;
    disabled?: boolean;
    onClick: () => void;
    outlined?: boolean;
    size?: number | string;
    spin?: boolean;
    text?: string;
}
export const ActionButton = ({ title, mdiIcon, variant, disabled, onClick, outlined, size, spin, text }: ActionButtonProps) => {
    const s = size ?? 0.9;
    const actionClass = outlined ? 'action-button-outlined' : 'action-button';
    const btnClass = `mdi-btn btn ${actionClass} ${variant}`;
    const shouldSpin = spin ?? false;
    return (
        <button disabled={disabled} className={btnClass} title={title} onClick={() => onClick()}>
            <Icon data-testid="action-button-icon" path={mdiIcon} size={s} spin={shouldSpin} /> {text}
        </button>
    );
};
