import { mdiAlertOctagon, mdiCheckboxMarkedCircle } from '@mdi/js';
import { Loading } from '../../../Shared/Loading';
import { TextWithIcon } from '../../../UIExtensions/Text';
import { ConsolidateApplicationError } from '../../../../../../api/models';

interface ConsolidationValidationResultProps {
    inProgress: boolean;
    errors: ConsolidateApplicationError[];
}

export const ConsolidationValidationResult = ({ inProgress, errors }: ConsolidationValidationResultProps) => {
    if (inProgress) {
        return <Loading text="validation in progress" />;
    }
    const success = errors.length === 0;
    if (success) {
        return <TextWithIcon iconClass={'icon-green'} text="Successful" icon={mdiCheckboxMarkedCircle} size={1} />;
    }
    return (
        <>
            {errors.map((error) => {
                return (
                    <span>
                        <TextWithIcon iconClass={'icon-red'} text={error.message} icon={mdiAlertOctagon} size={1} />
                        <br />
                    </span>
                );
            })}
        </>
    );
};
