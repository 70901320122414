import { mdiPlus } from '@mdi/js';
import { Dropdown, Row } from 'react-bootstrap';
import { useState } from 'react';
import { ConfirmationModal, VerbiageType } from '../Shared/ConfirmationModal';
import { ValidatableSelect } from '../Shared/ValidatableSelect';
import { SelectOptionString } from '../Types/SelectOption';
import { AppRegistration, EnvironmentType } from '../../../../api/models';
import { Loading } from '../Shared/Loading';
import Icon from '@mdi/react';
import { AppRegistrationLink } from './AppRegistrationLink';
import { ValidatableInput } from '../Shared/ValidatableInput';

interface AppRegistrationCreateProps {
    disabled: boolean;
    applicationName: string;
    environmentTypes: EnvironmentType[];
    onCreate: (environment: string, applicationName: string) => Promise<Map<string, string[]> | undefined>;
    onLink: (clientId: string, environment: string, objectId: string) => Promise<Map<string, string[]> | undefined>;
}

export function AppRegistrationCreate({ disabled, applicationName, environmentTypes, onCreate, onLink }: AppRegistrationCreateProps) {
    const [showCreate, setShowCreate] = useState(false);
    const [showLink, setShowLink] = useState(false);
    const [loading, setLoading] = useState(false);
    const [environment, setEnvironment] = useState<string>('');
    const [appName, setAppName] = useState(applicationName);

    const linkAppRegistration = async (appRegistration: AppRegistration, environment: string) => {
        await onLink(appRegistration.clientId, environment, appRegistration.providerUniqueId);
        setShowLink(false);
    };

    const environmentOptions = environmentTypes.map((type: EnvironmentType) => new SelectOptionString(type.name, type.id));
    const enabled = !disabled && environmentOptions?.length > 0;
    return (
        <>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="create-appreg" disabled={!enabled}>
                    <Icon path={mdiPlus} size={1} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item
                        onClick={async () => {
                            setEnvironment('');
                            setAppName(applicationName);
                            setShowCreate(true);
                        }}
                    >
                        Add Basic App Registration
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={async () => {
                            setEnvironment('');
                            setShowLink(true);
                        }}
                    >
                        Link Registration
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <ConfirmationModal
                show={showCreate}
                enabled={environment !== '' && appName !== ''}
                title="Add Basic App Registration"
                verbiageType={VerbiageType.OkCancel}
                onCancel={() => setShowCreate(false)}
                onConfirm={async () => {
                    setLoading(true);
                    await onCreate(environment, appName);
                    setLoading(false);
                    setShowCreate(false);
                }}
            >
                <Row>{loading ? <Loading text={'Saving...'}></Loading> : <></>}</Row>
                <Row>
                    <ValidatableSelect
                        disabled={!enabled}
                        label={'Environment*'}
                        options={environmentOptions}
                        onChange={(value) => setEnvironment(value || '')}
                        validate={(value) => {
                            let errors = [];
                            if (!value || value.length === 0) {
                                errors.push('Environment must be selected.');
                            }
                            return errors;
                        }}
                        selectedValue={environment}
                    />
                </Row>
                <Row>
                    <ValidatableInput
                        fieldName={'Azure application name*'}
                        disabled={false}
                        value={appName}
                        placeholder={'Azure application name, without environment prefix'}
                        onChange={(value: string) => {
                            setAppName(value);
                        }}
                        validate={(value: string) => {
                            let errors = [];
                            if (value === undefined || value.length === 0) {
                                errors.push('Azure application name is a required field.');
                            }
                            return errors;
                        }}
                    />
                </Row>
            </ConfirmationModal>
            <AppRegistrationLink showLink={showLink} environmentTypes={environmentTypes} closeModal={() => setShowLink(false)} onLink={linkAppRegistration} />
        </>
    );
}
