export class CodeTypePermissions {
    public static canCreate(permissionPrefix: string): string {
        return `${permissionPrefix}_Create`;
    }

    public static canDelete(permissionPrefix: string): string {
        return `${permissionPrefix}_Delete`;
    }

    public static canUpdate(permissionPrefix: string): string {
        return `${permissionPrefix}_Update`;
    }

    public static canDisable(permissionPrefix: string): string {
        return `${permissionPrefix}_Disable`;
    }

    public static canEnable(permissionPrefix: string): string {
        return `${permissionPrefix}_Enable`;
    }
}
