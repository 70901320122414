import { AppRegistration } from '../../../../api/models';

export class SelectOption<T> {
    constructor(label: string, value: T) {
        this.label = label;
        this.value = value;
    }

    public label: string;

    public value: T;
}

export class SelectOptionString extends SelectOption<string> {}

export class SelectOptionAppRegistration extends SelectOption<AppRegistration> {}
