import { useMemo, useState } from 'react';
import AppRegistryClient from '../../../../../api/AppRegistryClient';
import { AttributeKey, AttributeType, AttributeValue } from '../../../../../api/models';
import { Loading } from '../../Shared/Loading';
import { ApplicationAttributeRow } from './ApplicationAttributeRow';
import { CodeTypeSort } from '../../Attributes/CodeTypeSort';
import { Button, Col, Row } from 'react-bootstrap';
import { ValidationErrors } from '../../Shared/ValidationErrors';
import { tryCallApiCatchErrors } from '../../../../../api/Helpers';

interface ApplicationAttributesEditProps {
    applicationId: string;
    applicationAttributes: Map<AttributeType, AttributeValue[]>;
    attributeTypes: AttributeType[];
    attributeValues: AttributeValue[];
    editableAttributeTypes: string[];
    isLoading: boolean;
    onClose: () => void;
    onSave: (savedAttributes: Map<AttributeType, AttributeValue[]>) => void;
}

export const ApplicationAttributesEdit = ({
    applicationId,
    applicationAttributes,
    attributeTypes,
    attributeValues,
    editableAttributeTypes,
    isLoading,
    onClose,
    onSave,
}: ApplicationAttributesEditProps) => {
    const [applicationAttributeValues, setApplicationAttributeValues] = useState(applicationAttributes);
    const [validationErrors, setValidationErrors] = useState(new Array<string>());

    const onChange = async (attributeType: AttributeType, values: AttributeValue[]) => {
        const applicationAttributeValuesCopy = new Map<AttributeType, AttributeValue[]>(applicationAttributeValues);
        applicationAttributeValuesCopy.set(attributeType, values);
        setApplicationAttributeValues(applicationAttributeValuesCopy);
    };

    const onSaveInternal = async () => {
        const applicationAttributes = Array.from(applicationAttributeValues.values()).flat();
        const applicationAttributeKeys = applicationAttributes.map((attributeValue) => {
            return { type: attributeValue.type, value: attributeValue.value } as AttributeKey;
        });

        var errors = await tryCallApiCatchErrors(
            async () => await AppRegistryClient.applicationAttributes.post(applicationId, applicationAttributeKeys),
            () => {
                onSave(applicationAttributeValues);
            }
        );
        if (errors) {
            setValidationErrors(Array.from(errors.values()).flat());
        }
    };

    const sortedAttributeTypes = useMemo(() => {
        return attributeTypes.sort(CodeTypeSort);
    }, [attributeTypes]);

    const sortedAttributeValues = useMemo(() => {
        return attributeValues.filter((attributeValue) => attributeValue.isActive).sort(CodeTypeSort);
    }, [attributeValues]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <ValidationErrors errors={validationErrors} />
            {sortedAttributeTypes.map((attributeType) => {
                const attributeValuesForType = sortedAttributeValues.filter((value) => value.type === attributeType.id);
                const applicationAttributesForType = applicationAttributeValues.get(attributeType) ?? new Array<AttributeValue>();
                const canEdit = editableAttributeTypes.includes(attributeType.id);
                return (
                    <ApplicationAttributeRow
                        key={`attribute-type-${attributeType.id}`}
                        canEdit={attributeType.isActive && canEdit}
                        attributeType={attributeType}
                        attributeValues={attributeValuesForType}
                        applicationAttributes={applicationAttributesForType}
                        onChange={onChange}
                    />
                );
            })}
            <Row>
                <Col md={1}>
                    <Button variant="light" onClick={onClose}>
                        Cancel
                    </Button>
                </Col>
                <Col>
                    <Button variant="primary" onClick={onSaveInternal}>
                        Save Changes
                    </Button>
                </Col>
            </Row>
        </>
    );
};
