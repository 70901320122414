import { Badge, Button, CloseButton, Col, Modal, Row, Table } from 'react-bootstrap';
import { AppRegistrationResponse, StepResult } from '../../../../api/models';
import { EntraIdAppRegistrationLink } from './EntraIdAppRegistrationLink';

export type AppRegistrationResultsResponse = {};

export type AppRegistrationStepResultsProps = {
    results: AppRegistrationResponse | undefined;
    show: boolean;
    onClose: () => void;
};

export function AppRegistrationStepResults({ results, show, onClose }: AppRegistrationStepResultsProps) {
    const getOverallStatus = (response: AppRegistrationResponse | undefined): React.ReactElement => {
        if (response?.failed === false) {
            return <Badge bg="success">Success</Badge>;
        }
        return <Badge bg="danger">Failed</Badge>;
    };
    const getStepName = (step: StepResult): string => {
        return step.name.substring(0, step.name.indexOf(' '));
    };
    const getStepStatus = (step: StepResult): React.ReactElement => {
        if (step.failed) {
            return <Badge bg="danger">Failed</Badge>;
        }
        if (!step.started) {
            return <Badge bg="warning">Not Started</Badge>;
        }
        if (step.skipped) {
            return <Badge bg="info">Skipped</Badge>;
        }
        if (step.applied) {
            return <Badge bg="success">Success</Badge>;
        }
        return <Badge bg="danger">Unknown</Badge>;
    };

    return (
        <>
            <Modal size="xl" centered show={show} onHide={() => onClose()}>
                <Modal.Header>
                    <Modal.Title>App Registrations Operation Results</Modal.Title>
                    <CloseButton onClick={() => onClose()} />
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={7}>
                            <Row>
                                <Col sm={3}>Overall Status:</Col>
                                <Col>{getOverallStatus(results)}</Col>
                            </Row>
                            <Row>
                                <Col sm={3}>Client Id:</Col>
                                <Col className="font-monospace">{results?.azureApp?.appId}</Col>
                            </Row>
                            <Row>
                                <Col sm={3}>Object Id:</Col>
                                <Col className="font-monospace">{results?.azureApp?.id}</Col>
                            </Row>
                            <Row>
                                <Col sm={3}>Display name:</Col>
                                <Col>
                                    <EntraIdAppRegistrationLink clientId={results?.azureApp?.appId} displayName={results?.azureApp?.displayName} />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>Description:</Col>
                                <Col>{results?.azureApp?.description}</Col>
                            </Row>
                        </Col>
                        <Col md={4}></Col>
                    </Row>
                    <Row>
                        <div className="table-wrapper">
                            <Table striped={false} bordered={true} size="sm">
                                <thead className="thead-white">
                                    <tr>
                                        <th scope="col" className="col-3">
                                            Step
                                        </th>
                                        <th scope="col" className="col-1">
                                            Status
                                        </th>
                                        <th scope="col" className="col-8">
                                            Message
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {results?.stepResults?.map(function (d) {
                                        return (
                                            <tr>
                                                <td>{getStepName(d)}</td>
                                                <td>{getStepStatus(d)}</td>
                                                <td className="small">{d.message}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => onClose()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
