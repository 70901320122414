import { Table } from 'react-bootstrap';
import { useMemo, useState } from 'react';
import { Filter } from '../Shared/Filter';
import { DeliveryTeam, Department } from '../../../../api/models';
import { TeamsTableRow } from './TeamsTableRow';

export interface DepartmentTeamsTableProps {
    selectedDepartment: Department;
}

export const DepartmentTeamsTable = ({ selectedDepartment }: DepartmentTeamsTableProps) => {
    const [filter, setFilter] = useState('');

    const nameSort = (x: DeliveryTeam, y: DeliveryTeam) => {
        return x.name.localeCompare(y.name);
    };
    const filteredTeams = useMemo(() => {
        return selectedDepartment.teams?.filter((team) => team.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())).sort(nameSort);
    }, [selectedDepartment.teams, filter]);

    return (
        <>
            <Filter onUpdate={setFilter} />
            <div className="table-wrapper">
                <Table striped={true} className="table-fixed-header">
                    <thead className="thead-white">
                        <tr>
                            <th scope="col" className="col-12">
                                Team
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredTeams?.map(function (team) {
                            return <TeamsTableRow key={'team-' + team.id} team={team} />;
                        })}
                    </tbody>
                </Table>
            </div>
        </>
    );
};
