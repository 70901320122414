import { Col, Row } from 'react-bootstrap';
import { AppState, AppType, Application } from '../../../../api/models';
import { ActionButton } from '../UIExtensions/Buttons';
import { mdiPencilBox, mdiSetMerge } from '@mdi/js';
import { useNavigate } from 'react-router';

interface ApplicationBasicInfoProps {
    application?: Application;
    appTypes?: AppType[];
    appStates?: AppState[];
    canEdit: boolean;
    showEditCallback: () => void;
}

export const ApplicationBasicInfo = ({ application, appTypes, appStates, canEdit, showEditCallback }: ApplicationBasicInfoProps) => {
    const typeName = appTypes?.find((t: AppType) => t.id === application?.type)?.name ?? 'Unknown type';
    const stateName = appStates?.find((t: AppState) => t.id === application?.state)?.name ?? 'Unknown state';
    const navigate = useNavigate();

    return (
        <>
            <Row>
                <Col md={7}>
                    <Row>
                        <Col sm={3}>Id:</Col>
                        <Col>{application?.id}</Col>
                    </Row>
                    <Row>
                        <Col sm={3}>Display name:</Col>
                        <Col>{application?.displayName}</Col>
                    </Row>
                    <Row>
                        <Col sm={3}>Unique name:</Col>
                        <Col>{application?.uniqueName}</Col>
                    </Row>
                    <Row>
                        <Col sm={3}>Description:</Col>
                        <Col>{application?.description}</Col>
                    </Row>
                </Col>
                <Col md={4}>
                    <Row>
                        <Col sm={4}>Type:</Col>
                        <Col>{typeName}</Col>
                    </Row>
                    <Row>
                        <Col sm={4}>State:</Col>
                        <Col>{stateName}</Col>
                    </Row>
                    <Row>
                        <Col sm={4}>Delivery team:</Col>
                        <Col>{application?.deliveryTeam?.name}</Col>
                    </Row>
                    <Row>
                        <Col sm={4}>System:</Col>
                        <Col>{application?.system?.displayName}</Col>
                    </Row>
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'flex-start' }}>
                    <ActionButton
                        disabled={!canEdit}
                        title={'Edit Basic info'}
                        mdiIcon={mdiPencilBox}
                        size={2}
                        outlined={true}
                        variant={'primary btn-white'}
                        onClick={async () => showEditCallback()}
                    />
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'flex-start' }}>
                    <ActionButton
                        title={'Start consolidation'}
                        disabled={!canEdit}
                        mdiIcon={mdiSetMerge}
                        size={2}
                        outlined={true}
                        variant={'secondary btn-white'}
                        onClick={async () => {
                            const url = `/consolidation/${application?.uniqueName}`;
                            navigate(url);
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};
