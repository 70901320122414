import { useEffect, useState } from 'react';
import { AppState, AppType, Application, DeliveryTeam, System, Team } from '../../../../api/models';
import { ApplicationBasicInfo } from './ApplicationBasicInfo';
import { ApplicationEditInfo } from './ApplicationsEditInfo';
import { tryCallApiCatchErrors } from '../../../../api/Helpers';
import AppRegistryClient from '../../../../api/AppRegistryClient';
import { ApplicationAttributesWrapper } from './Attributes/ApplicationAttributesWrapper';
import { Loading } from '../Shared/Loading';

interface ApplicationInfoWrapperProps {
    isLoading: boolean;
    application?: Application;
    canEdit: boolean;
    canEditAttributes: boolean;
    isEditMode: boolean;
    setEditCallback: (isEdit: boolean) => void;
}

export const ApplicationInfoWrapper = ({ isLoading, application, canEdit, canEditAttributes, isEditMode, setEditCallback }: ApplicationInfoWrapperProps) => {
    const [appTypes, setAppTypes] = useState<AppType[]>();
    const [appStates, setAppStates] = useState<AppState[]>();
    const [teams, setTeams] = useState<Team[]>();
    const [systems, setSystems] = useState<System[]>();

    const [app, setApp] = useState(application);

    const getAppTypes = async () => {
        await tryCallApiCatchErrors(
            async () => await AppRegistryClient.getAllAppTypes(),
            (result) => setAppTypes(result)
        );
    };

    const getAppStates = async () => {
        await tryCallApiCatchErrors(
            async () => await AppRegistryClient.getAllAppStates(),
            (result) => setAppStates(result)
        );
    };
    const getDeliveryTeams = async () => {
        await tryCallApiCatchErrors(
            async () => await AppRegistryClient.getAllTeams(),
            (result) => setTeams(result)
        );
    };

    const getSystems = async () => {
        await tryCallApiCatchErrors(
            async () => await AppRegistryClient.systems.get({ select: 'id, displayName' }),
            (result) => setSystems(result)
        );
    };

    useEffect(() => {
        getAppTypes();
        getAppStates();
        getDeliveryTeams();
        getSystems();
    }, []);

    useEffect(() => {
        setApp(application);
    }, [application]);

    if (isLoading) {
        return <Loading />;
    }
    return (
        <>
            {isEditMode ? (
                <>
                    <ApplicationEditInfo
                        appTypes={appTypes}
                        appStates={appStates}
                        teams={teams}
                        application={app!}
                        systems={systems}
                        clearEditCallback={(appInput) => {
                            if (appInput) {
                                const deliveryTeam = {
                                    id: appInput.deliveryTeamId,
                                    name: teams?.find((t: Team) => t.id === appInput.deliveryTeamId)?.name,
                                } as DeliveryTeam;
                                appInput.deliveryTeam = deliveryTeam;

                                const system = {
                                    id: appInput.systemId,
                                    displayName: systems?.find((system) => system.id === appInput.systemId)?.displayName,
                                } as System;
                                appInput.system = system;

                                setApp(appInput);
                            }
                            setEditCallback(false);
                        }}
                    />
                </>
            ) : (
                <>
                    <ApplicationBasicInfo application={app} appTypes={appTypes} appStates={appStates} canEdit={canEdit} showEditCallback={() => setEditCallback(true)} />
                </>
            )}
            <hr />
            <ApplicationAttributesWrapper application={app} canEdit={canEditAttributes} />
        </>
    );
};
