import { useMemo } from 'react';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from '../store';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppSelectorWithCheck = <TSelected, TP extends any[]>(
    selector: (rs: RootState, ...params: TP) => TSelected, //
    equalityFn?: (left: TSelected, right: TSelected) => boolean,
    ...params: TP
) => {
    const selected = useSelector((rs: RootState) => selector(rs, ...params), equalityFn);

    return selected;
};

/**
 * Creates a selector for the supplied selector factory, memoizes it, and runs it
 * @param selectorFactory The factory to create a selector to memorize (*assumes no dependecies beyond the selectorFactory itself!)
 * @param params Optional, the parameters to be supplied to the selector (beyond RootState)
 * @returns The result of the selector
 */
export const useAppMakeSelector = <TSelected, TP extends any[]>(
    selectorFactory: () => (rs: RootState, ...params: TP) => TSelected, //
    ...params: TP
) => useAppMakeSelectorWithCheck(selectorFactory, undefined, ...params);

/**
 * Creates a selector for the supplied selector factory, memoizes it, and runs it
 * @param selectorFactory The factory to create a selector to memorize (*assumes no dependecies beyond the selectorFactory itself!)
 * @param equalityFn the function that will be used to determine equality
 * @param params The parameters to be supplied to the selector (beyond RootState)
 * @returns The result of the selector
 */
export const useAppMakeSelectorWithCheck = <TSelected, TP extends any[]>(
    selectorFactory: () => (rs: RootState, ...params: TP) => TSelected, //
    equalityFn?: (left: TSelected, right: TSelected) => boolean,
    ...params: TP
) => {
    const selector = useMemo(() => selectorFactory(), [selectorFactory]);

    const selected = useAppSelectorWithCheck((rs) => selector(rs, ...params), equalityFn);

    return selected;
};
