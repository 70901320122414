import { Row } from 'react-bootstrap';
import { AttributeType, AttributeValue } from '../../../../../api/models';
import { ValidatableSelect } from '../../Shared/ValidatableSelect';
import { useCallback, useEffect, useState } from 'react';
import { SelectOption } from '../../Types/SelectOption';
import { ValidatableMultiSelect } from '../../Shared/ValidatableMultiSelect';
import { AttributeValueTypes } from '../../Attributes/Types/AttributeValueTypes';

interface ApplicationAttributeRowProps {
    attributeType: AttributeType;
    attributeValues: AttributeValue[];
    applicationAttributes: AttributeValue[];
    canEdit: boolean;
    onChange: (attributeType: AttributeType, values: AttributeValue[]) => void;
}

export const ApplicationAttributeRow = ({ attributeType, attributeValues, applicationAttributes, canEdit, onChange }: ApplicationAttributeRowProps) => {
    const [options, setOptions] = useState(new Array<SelectOption<string>>());
    const [selectedValues, setSelectedValues] = useState(new Array<string>());
    const creatable = attributeType.valueType === AttributeValueTypes.FreeformText;

    const onChangeInternal = (items: string[]) => {
        let values = null;
        if (creatable) {
            values = items.map((item) => {
                return { type: attributeType.id, value: item, name: item } as AttributeValue;
            });
        } else {
            values = attributeValues.filter((attributeValue) => items.indexOf(attributeValue.value) >= 0);
        }
        onChange(attributeType, values);
    };

    const getSelectOptions = useCallback(async () => {
        let selectOptions = null;
        if (creatable) {
            selectOptions = applicationAttributes.map((attributeValue) => {
                return { label: attributeValue.value, value: attributeValue.value } as SelectOption<string>;
            });
        } else {
            selectOptions = attributeValues.map((attributeValue) => {
                return { label: attributeValue.name, value: attributeValue.value } as SelectOption<string>;
            });
        }
        setOptions(selectOptions);
    }, [applicationAttributes, attributeValues, creatable]);

    useEffect(() => {
        getSelectOptions();
    }, [getSelectOptions]);

    const getSelectedValues = useCallback(async () => {
        const values = applicationAttributes.map((attributeValue) => {
            return attributeValue.value;
        });
        setSelectedValues(values);
    }, [applicationAttributes]);

    useEffect(() => {
        getSelectedValues();
    }, [getSelectedValues]);

    return (
        <Row>
            {attributeType.allowMultiple ? (
                <ValidatableMultiSelect
                    disabled={!canEdit}
                    label={attributeType.name}
                    options={options}
                    selectedValues={selectedValues}
                    onChange={onChangeInternal}
                    creatable={creatable}
                />
            ) : (
                <ValidatableSelect
                    disabled={!canEdit}
                    clearable={true}
                    creatable={creatable}
                    label={attributeType.name}
                    options={options}
                    onChange={(value) => onChangeInternal(value ? [value] : [])}
                    selectedValue={selectedValues.length > 0 ? selectedValues[0] : undefined}
                />
            )}
        </Row>
    );
};
