import { PropsWithChildren } from 'react';

export type NavBarProps = PropsWithChildren<{}>;
export const NavBar = (props: NavBarProps) => {
    const children = props.children;
    return (
        <nav className="navbar">
            <div className="container justify-content-center">
                <ul className="nav nav-pills nav-justified w-100" id="pills-tab" role="tablist">
                    {children}
                </ul>
            </div>
        </nav>
    );
};

export type NavBarItemProps = PropsWithChildren<{
    target: string;
    active?: boolean;
    onClick?: (target: string) => void;
}>;
export const NavBarItem = ({ target, active, onClick, children }: NavBarItemProps) => {
    const isActive = active ? true : false;
    return (
        <li className="nav-item" role="presentation">
            <button
                className={`nav-link ${isActive ? 'active' : ''}`}
                id={`${target}-tab`}
                data-bs-toggle="pill"
                data-bs-target={`#${target}`}
                type="button"
                role="tab"
                aria-controls={target}
                aria-selected={active}
                onClick={() => {
                    if (onClick) onClick(target);
                }}
            >
                {children}
            </button>
        </li>
    );
};
