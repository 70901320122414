import { PermissionResource } from '../Permissions/PermissionResource';

export class UserPrincipal {
    private permissions: Array<string> = Array<string>();

    constructor(permissions?: string[]) {
        this.permissions = permissions ?? new Array<string>();
    }

    public hasPermission(permission: string): boolean {
        return this.permissions.some((userPermission) => userPermission === permission);
    }

    public hasPermissionForRole(resource: PermissionResource, role: string): boolean {
        var right = this.roleToPermission(resource, role);
        return this.hasPermission(right);
    }

    private roleToPermission(resource: PermissionResource, role: string): string {
        return `${resource}_Change${role}s`;
    }
}
