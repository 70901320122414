import { Table } from '@tanstack/react-table';
import { Col, Row } from 'react-bootstrap';

interface PaginationProps<T> {
    table: Table<T>;
}

export function Pagination<T>({ table }: PaginationProps<T>) {
    const pageStart = table.getState().pagination.pageIndex * table.getState().pagination.pageSize;
    const pageEnd = Math.min(table.getPrePaginationRowModel().rows.length, pageStart + table.getState().pagination.pageSize);
    return (
        <>
            <div>
                <Row>
                    <Col>{`Showing ${1 + pageStart} to ${pageEnd} of ${table.getPrePaginationRowModel().rows.length} entries`}</Col>
                    <Col className="center">
                        <select
                            className="border rounded p-1"
                            value={table.getState().pagination.pageSize}
                            onChange={(e) => {
                                table.setPageSize(Number(e.target.value));
                            }}
                        >
                            {[10, 50, 100, 500, 1000].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </Col>
                    <Col className="right">
                        <button className="border table-pagination p-1" onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}>
                            {'<<'}
                        </button>
                        <button className="border table-pagination p-1" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                            {'<'}
                        </button>
                        <input
                            style={{ width: '50px' }}
                            type="number"
                            value={table.getState().pagination.pageIndex + 1}
                            onChange={(e) => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                table.setPageIndex(page);
                            }}
                            className="border p-1 col-1"
                        />
                        <button className="border table-pagination p-1" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                            {'>'}
                        </button>
                        <button className="border table-pagination p-1" onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}>
                            {'>>'}
                        </button>
                    </Col>
                </Row>
            </div>
        </>
    );
}
