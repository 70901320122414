export module StringUtils {
    export function isNullOrEmpty(s: string): boolean {
        return !s;
    }
    export function substringBeforeFirstSeparator(separator: string, text: string): string {
        if (isNullOrEmpty(text)) {
            return text;
        }
        return text.substring(0, text.indexOf(separator) + separator.length - 1);
    }
    export function substringAfterLastSeparator(separator: string, text: string): string {
        if (isNullOrEmpty(text)) {
            return text;
        }
        return text.substring(text.lastIndexOf(separator) + separator.length, text.length);
    }
    export function substringAfterFirstSeparator(separator: string, text: string): string {
        if (isNullOrEmpty(text)) {
            return text;
        }
        return text.substring(text.indexOf(separator) + separator.length, text.length);
    }
    export function capitalizeFirstLetter(text: string): string {
        if (isNullOrEmpty(text)) {
            return text;
        }
        return text[0].toUpperCase() + text.slice(1);
    }
    export function isEmptyOrNullOrUndefined(s: string | undefined | null): boolean {
        if (s === undefined || s === null) {
            return true;
        }
        return isNullOrEmpty(s!);
    }
}
