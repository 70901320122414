import { mdiAlertCircle, mdiCheckboxMarkedCircle } from '@mdi/js';
import { Col, Row } from 'react-bootstrap';
import { TextWithIcon } from '../../../UIExtensions/Text';

interface ConsolidationConfirmationRowProps {
    label: string;
    oldValue?: string;
    newValue?: string;
}

export const ConsolidationConfirmationRow = ({ label, oldValue, newValue }: ConsolidationConfirmationRowProps) => {
    const match = oldValue === newValue;
    const className = match ? 'icon-green' : 'icon-orange';
    const icon = match ? mdiCheckboxMarkedCircle : mdiAlertCircle;
    return (
        <Row>
            <Col className="col-2">{label}:</Col>
            <Col className="col-5">{oldValue}</Col>
            <Col className="col-5">
                <TextWithIcon icon={icon} iconClass={className} size={0.7} text={newValue ?? ''} />
            </Col>
        </Row>
    );
};
