import { AttributeType, CodeType } from '../../../../../api/models';
import { ActionButton } from '../../UIExtensions/Buttons';
import { AttributeTypeModal } from './AttributeTypeModal';
import { useState } from 'react';
import { DefaultAttributeType } from './DefaultAttributeType';

export interface AttributeTypeEditProps {
    title: string;
    mdiIcon: string;
    variant: string;
    disabled: boolean;
    type?: AttributeType;
    allTypes: AttributeType[];
    attributeValueTypes: CodeType[];
    onConfirm: (item: AttributeType) => Promise<Map<string, string[]> | undefined>;
}

export const AttributeTypeEdit = ({ title, mdiIcon, variant, disabled, type, allTypes, attributeValueTypes, onConfirm }: AttributeTypeEditProps) => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [apiErrors, setApiErrors] = useState<Map<string, string[]>>();

    const onConfirmInternal = async (type: AttributeType) => {
        let errors = await onConfirm(type);
        if (!errors) {
            setShowEditModal(false);
        } else {
            setApiErrors(errors);
        }
    };

    return (
        <>
            <ActionButton
                disabled={disabled}
                mdiIcon={mdiIcon}
                variant={variant}
                title={title}
                onClick={() => {
                    setShowEditModal(true);
                }}
            />
            <AttributeTypeModal
                show={showEditModal}
                onConfirm={onConfirmInternal}
                onHide={() => {
                    setApiErrors(undefined);
                    setShowEditModal(false);
                }}
                attribType={type ?? new DefaultAttributeType()}
                allTypes={allTypes}
                attributeValueTypes={attributeValueTypes}
                apiErrors={apiErrors}
            />
        </>
    );
};
