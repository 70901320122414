import { Table } from 'react-bootstrap';
import { Loading } from '../Shared/Loading';
import { useCallback, useEffect, useMemo, useState } from 'react';
import AppRegistryClient from '../../../../api/AppRegistryClient';
import { Filter } from '../Shared/Filter';
import { Department } from '../../../../api/models';
import { DepartmentsTableRow } from './DepartmentTableRow';

export interface DepartmentsTableProps {
    onSelectedChanged: (type: Department) => void;
}

export const DepartmentsTable = ({ onSelectedChanged }: DepartmentsTableProps) => {
    const [departments, setDepartments] = useState<Department[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState('');

    const onSelectedDepartmentChanged = useCallback(
        (selected: Department) => {
            onSelectedChanged(selected);
            setSelectedDepartment(selected.id);
        },
        [onSelectedChanged]
    );

    const getDepartmentsWithTeams = useCallback(async () => {
        try {
            const result = await AppRegistryClient.departments.get({ expand: 'teams($select=id,name,isActive)', select: 'id,name,isActive,teams' });
            setDepartments(result);
            if (result.length > 0) {
                onSelectedChanged(result[0]);
            }
        } catch {
            setDepartments([]);
        } finally {
            setIsLoading(false);
        }
    }, [onSelectedChanged]);

    useEffect(() => {
        getDepartmentsWithTeams();
    }, [getDepartmentsWithTeams]);

    const nameSort = (x: Department, y: Department) => {
        return x.name.localeCompare(y.name);
    };

    const sortedDepartments = useMemo(() => {
        return departments.filter((dept) => dept.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())).sort(nameSort);
    }, [departments, filter]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <Filter onUpdate={setFilter} />
            <div className="table-wrapper">
                <Table striped={true} className="table-fixed-header table-selectable">
                    <thead className="thead-white">
                        <tr>
                            <th scope="col">Department</th>
                            <th scope="col">Teams</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedDepartments?.map(function (dept) {
                            return (
                                <DepartmentsTableRow
                                    key={'department-' + dept.id}
                                    allDepartments={departments}
                                    department={dept}
                                    onClick={() => onSelectedDepartmentChanged(dept)}
                                    selected={dept.id === selectedDepartment}
                                />
                            );
                        })}
                    </tbody>
                </Table>
            </div>
        </>
    );
};
