import { useCallback, useEffect, useState } from 'react';
import appRegistryClient from '../../../../api/AppRegistryClient';
import { getApiErrors, tryCallApiCatchErrors } from '../../../../api/Helpers';
import { CreateApplicationRequest, Application, AppType, AppState, Team } from '../../../../api/models';
import { useNavigate } from 'react-router';
import { UserPrincipal } from '../Types/UserPrincipal';
import { Permission } from '../Permissions/permission';
import ApplicationEdit from './ApplicationEdit';
import AppRegistryClient from '../../../../api/AppRegistryClient';
import { SelectOptionString } from '../Types/SelectOption';
import { DefaultApplication } from './DefaultApplication';
import { Loading } from '../Shared/Loading';

interface ApplicationCreateProps {}

function ApplicationCreate(props: ApplicationCreateProps) {
    const [userPrincipal, setUserPrincipal] = useState<UserPrincipal>(new UserPrincipal());
    const getUserPermissions = async () => {
        var tempUserPermissions = await AppRegistryClient.userPermissions.getRootPermissions();
        var user = new UserPrincipal(tempUserPermissions);
        setUserPrincipal(user);
    };

    useEffect(() => {
        getUserPermissions();
    }, []);

    const createEnabled = userPrincipal.hasPermission(Permission.Application_Create);

    const [isLoading, setIsLoading] = useState(true);
    const [apiErrors, setApiErrors] = useState<Map<string, string[]>>();
    const [appTypes, setAppTypes] = useState<SelectOptionString[]>();
    const [appStates, setAppStates] = useState<SelectOptionString[]>();
    const [teams, setTeams] = useState<SelectOptionString[]>();
    const [systems, setSystems] = useState<SelectOptionString[]>();

    const app = new DefaultApplication();

    const navigate = useNavigate();
    const createHandler = async (application: Application, ownerGroupId: string) => {
        const request = {
            uniqueName: application.uniqueName,
            displayName: application.displayName,
            description: application.description,
            type: application.type,
            ownerGroupId: ownerGroupId,
            deliveryTeamId: application.deliveryTeamId,
            systemId: application.systemId,
        } as CreateApplicationRequest;
        try {
            await appRegistryClient.applications.post(request);
            const appName = request.uniqueName;
            const url = `/applications/${appName}`;
            navigate(url);
        } catch (error) {
            const errorMap = getApiErrors(error);
            setApiErrors(errorMap);
        }
    };

    const getAppTypes = async () => {
        await tryCallApiCatchErrors(
            async () => await AppRegistryClient.getAllAppTypes(),
            (result) => setAppTypes(result?.filter((t: AppType) => t.isActive).map((t: AppType) => new SelectOptionString(t.name, t.id)))
        );
    };

    const getAppStates = async () => {
        await tryCallApiCatchErrors(
            async () => await AppRegistryClient.getAllAppStates(),
            (result) => setAppStates(result?.filter((t: AppState) => t.isActive).map((t: AppState) => new SelectOptionString(t.name, t.id)))
        );
    };
    const getDeliveryTeams = async () => {
        await tryCallApiCatchErrors(
            async () => await AppRegistryClient.getAllTeams(),
            (result) => setTeams(result?.filter((t: Team) => t.isActive).map((t: Team) => new SelectOptionString(t.name, t.id!)))
        );
    };
    const getSystems = async () => {
        await tryCallApiCatchErrors(
            async () => await AppRegistryClient.systems.get({ select: 'id, displayName' }),
            (result) => setSystems(result?.map((system) => new SelectOptionString(system.displayName, system.id)))
        );
    };

    const getAllData = useCallback(async () => {
        await getAppTypes();
        await getAppStates();
        await getDeliveryTeams();
        await getSystems();

        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (isLoading) {
            getAllData();
        }
    }, [getAllData, isLoading]);

    if (isLoading) {
        return <Loading></Loading>;
    }

    if (!createEnabled) {
        return <>You are not authorized to perform app creation.</>;
    }
    return (
        <div>
            <ApplicationEdit
                isCreateMode={true}
                app={app}
                onCancel={() => {
                    const url = `/applications`;
                    navigate(url);
                }}
                saveHandler={createHandler}
                appTypes={appTypes}
                appStates={appStates}
                teams={teams}
                apiErrors={apiErrors}
                systems={systems}
            />
        </div>
    );
}

export default ApplicationCreate;
