import { useState } from 'react';
import { ActionAlert } from '../Shared/ActionAlert';
import { ValidatableInput } from '../Shared/ValidatableInput';
import { AppRegistrationActionElementProps } from './AppRegistrationActionTypes';

export function AppRegistrationDelete({ appRegistration, setEnable }: AppRegistrationActionElementProps) {
    const [confirmationInputValue, SetConfirmationInputValue] = useState('');

    const keyAlert = `delete-app-registration-alert-${appRegistration.id}`;
    return (
        <>
            <ActionAlert key={keyAlert} variant="danger">
                To proceed with &nbsp;
                <b>{appRegistration.displayName}</b> &nbsp; removal please confirm with app registration name below.
                <br />
                The App Registration will be &nbsp;<b>deleted</b>&nbsp; in Entra ID. <b>It may impact Production</b> and cause application to stop.
            </ActionAlert>
            <br />
            <ValidatableInput
                fieldName={'Name*'}
                disabled={false}
                value={confirmationInputValue}
                placeholder={"Name e.g. 'DEV - Test'"}
                onChange={SetConfirmationInputValue}
                validate={(value: string) => {
                    let errors = [];
                    if (value === undefined || value.length === 0 || value !== appRegistration.displayName) {
                        errors.push('Name does not match.');
                    }
                    const noErrors = errors.length === 0;
                    setEnable(noErrors);
                    return errors;
                }}
            />
        </>
    );
}
