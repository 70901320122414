import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { ConfirmationModal, VerbiageType } from '../../Shared/ConfirmationModal';
import { ValidatableInput } from '../../Shared/ValidatableInput';
import { AttributeType, CodeType } from '../../../../../api/models';
import { nameof } from 'ts-simple-nameof';
import { setField } from '../../../../../helpers/FieldSetter';
import { SwitchButton } from '../../Shared/SwitchButton';
import { StringUtils } from '../../Shared/StringUtils';
import { ValidatableSelect } from '../../Shared/ValidatableSelect';
import { SelectOption } from '../../Types/SelectOption';

interface AttributeTypeModalProps {
    attribType: AttributeType;
    allTypes: AttributeType[];
    attributeValueTypes: CodeType[];
    show: boolean;
    apiErrors?: Map<string, string[]> | undefined;
    onHide: () => void;
    onConfirm: (item: AttributeType) => void;
}
export function AttributeTypeModal({ attribType, allTypes, attributeValueTypes, show, apiErrors, onHide, onConfirm }: AttributeTypeModalProps) {
    const [attributeType, setAttributeType] = useState(attribType);
    const [fieldsValidity, setFieldsValidity] = useState(new Map<string, boolean>());
    const [isValid, setIsValid] = useState(false);
    const [allowRequiredRight, setAllowedRequiredRight] = useState(!StringUtils.isEmptyOrNullOrUndefined(attribType?.requiredRight));
    const isNew = !attribType?.id;
    const valueTypeOptions = attributeValueTypes.map((type) => new SelectOption(type.name, type.id));

    const idProperty = nameof<AttributeType>((type) => type.id);
    const nameProperty = nameof<AttributeType>((type) => type.name);
    const requiredRightProperty = nameof<AttributeType>((type) => type.requiredRight);
    const allowMultipleProperty = nameof<AttributeType>((type) => type.allowMultiple);
    const valueTypeProperty = nameof<AttributeType>((type) => type.valueType);

    const onFieldChanged = function <T>(fieldName: string, value: T) {
        const typeCopy = structuredClone(attributeType);
        if (setField(typeCopy, fieldName, value)) {
            setAttributeType(typeCopy);
        }
    };

    const setFieldValidity = function (fieldName: string, valid: boolean) {
        const fieldsValidityCopy = new Map(fieldsValidity);
        fieldsValidityCopy.set(fieldName, valid);
        setFieldsValidity(fieldsValidityCopy);
    };

    useEffect(() => {
        if (!show) {
            setAttributeType(attribType);
        }
    }, [show, attribType]);

    useEffect(() => {
        const valid = Array.from(fieldsValidity.values()).every((valid) => valid);
        setIsValid(valid);
    }, [fieldsValidity]);

    return (
        <>
            <ConfirmationModal
                show={show}
                enabled={isValid}
                title={`${isNew ? 'New' : 'Edit'} Attribute Type`}
                verbiageType={VerbiageType.OkCancel}
                onCancel={() => {
                    const allow = !StringUtils.isEmptyOrNullOrUndefined(attribType.requiredRight);
                    setAllowedRequiredRight(allow);
                    onHide();
                }}
                onConfirm={() => {
                    if (StringUtils.isEmptyOrNullOrUndefined(attributeType.requiredRight)) {
                        attributeType.requiredRight = null;
                    }
                    onConfirm(attributeType!);
                }}
            >
                <Row>
                    <ValidatableInput
                        focus={show}
                        disabled={!isNew}
                        fieldName={'Id'}
                        value={attributeType?.id ?? ''}
                        placeholder={"Id e.g. 'audit-classification'"}
                        onChange={(value: string) => onFieldChanged(idProperty, value)}
                        validate={(value: string) => {
                            const errors = [];
                            if (value === undefined || value.length === 0) {
                                errors.push('Id is required.');
                            } else if (isNew && allTypes.some((t) => t.id === value)) {
                                errors.push('Id already exists.');
                            }

                            setFieldValidity(idProperty, errors.length === 0);
                            return errors;
                        }}
                        apiErrors={apiErrors?.get(idProperty)}
                    />
                    <ValidatableInput
                        fieldName={'Name'}
                        value={attributeType?.name ?? ''}
                        placeholder={"Name e.g. 'Audit Classification'"}
                        onChange={(value: string) => onFieldChanged(nameProperty, value)}
                        validate={(value: string) => {
                            const errors = [];
                            if (value === undefined || value.length === 0) {
                                errors.push('Name is required.');
                            } else if (allTypes.some((t) => t.id !== attribType?.id && t.name === value)) {
                                errors.push('Name already exists.');
                            }

                            setFieldValidity(nameProperty, errors.length === 0);
                            return errors;
                        }}
                        apiErrors={apiErrors?.get(nameProperty)}
                    />
                    <ValidatableSelect
                        label="Value Type"
                        options={valueTypeOptions}
                        selectedValue={attributeType?.valueType ?? ''}
                        onChange={(value) => onFieldChanged(valueTypeProperty, value)}
                        validate={(value) => {
                            const errors = [];
                            if (value === undefined || value.length === 0) {
                                errors.push('Value Type is required.');
                            }

                            setFieldValidity(valueTypeProperty, errors.length === 0);
                            return errors;
                        }}
                        externalErrors={apiErrors?.get(valueTypeProperty)}
                        disabled={false}
                    />
                    <ValidatableInput
                        fieldName={'Required Right'}
                        value={attributeType?.requiredRight ?? ''}
                        disabled={!allowRequiredRight}
                        placeholder={'Required Right Name'}
                        onChange={(value: string) => onFieldChanged(requiredRightProperty, value)}
                        validate={(value: string) => {
                            const errors = [];
                            if (!allowRequiredRight) {
                                return [];
                            }

                            if (value === undefined || value.length === 0) {
                                errors.push('Right is required.');
                            }

                            setFieldValidity(requiredRightProperty, errors.length === 0);
                            return errors;
                        }}
                        apiErrors={apiErrors?.get(requiredRightProperty)}
                    />
                    <SwitchButton
                        label="Allow Required Right"
                        labelSize={4}
                        checked={allowRequiredRight ?? false}
                        onChange={(enabled: boolean) => {
                            setAllowedRequiredRight(enabled);
                            if (enabled === false) {
                                setFieldValidity(requiredRightProperty, true);
                                onFieldChanged(requiredRightProperty, '');
                            }
                        }}
                    />
                    <SwitchButton
                        label="Allow Multiple"
                        checked={attributeType?.allowMultiple ?? false}
                        onChange={(enabled: boolean) => {
                            onFieldChanged(allowMultipleProperty, enabled);
                        }}
                    />
                </Row>
            </ConfirmationModal>
        </>
    );
}
