import { PageHeading } from '@quad/bootstrap-react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import ScrollPageContent from './components/Shared/ScrollPageContent';
import AppRegistryClient from '../../api/AppRegistryClient';
import { useState, useEffect, useCallback } from 'react';
import { System, ODataQuery } from '../../api/models';
import { NavBar, NavBarItem } from './components/Shared/NavBar';
import { UserPrincipal } from './components/Types/UserPrincipal';
import Icon from '@mdi/react';
import { ActionButton } from './components/UIExtensions/Buttons';
import { mdiPlus } from '@mdi/js';
import { Permission } from './components/Permissions/permission';
import { useNavigate } from 'react-router';
import { SystemsTable } from './components/Systems/SystemTable';

enum NavBarItems {
    MySystems = 'my-systems',
    AllSystems = 'all-systems',
}

interface SystemsProps {
    title: string;
    mdiIcon: string;
}

export function Systems({ title, mdiIcon }: SystemsProps) {
    const maxSystemsPerPage = 1000;
    const selectCriteria = 'id,displayName,description';

    const [allSystems, setAllSystems] = useState(Array<System>());
    const [allSystemsAreLoading, setAllSystemsAreLoading] = useState(true);
    const [mySystems, setMySystems] = useState(Array<System>());
    const [mySystemsAreLoading, setMySystemsAreLoading] = useState(true);
    const [userPrincipal, setUserPrincipal] = useState<UserPrincipal>(new UserPrincipal());
    const [selectedTab, setSelectedTab] = useState(NavBarItems.MySystems.toString());

    const loadAllSystems = useCallback(async () => {
        try {
            const systems = await loadSystems(AppRegistryClient.systems.get);
            setAllSystems(systems);
        } finally {
            setAllSystemsAreLoading(false);
        }
    }, []);

    const loadUserSystems = useCallback(async () => {
        try {
            const systems = await loadSystems(AppRegistryClient.mySystems.get);
            setMySystems(systems);
        } finally {
            setMySystemsAreLoading(false);
        }
    }, []);

    const loadSystems = async (getSystems: (criteria: ODataQuery) => Promise<System[]>) => {
        var systems: System[] = [];
        var skipIndex = 0;
        var hasMoreSystems = true;

        while (hasMoreSystems) {
            const newSystems = await getSystems({
                select: selectCriteria,
                top: maxSystemsPerPage,
                skip: skipIndex,
                expand: `itOwner($select=displayName)`,
                orderBy: 'displayName,id',
            });
            systems = systems.concat(newSystems);
            skipIndex += maxSystemsPerPage;

            if (newSystems.length !== maxSystemsPerPage) {
                hasMoreSystems = false;
            }
        }

        return systems;
    };

    useEffect(() => {
        if (allSystemsAreLoading && selectedTab === NavBarItems.AllSystems) {
            loadAllSystems();
        }
    }, [allSystemsAreLoading, loadAllSystems, selectedTab]);

    useEffect(() => {
        if (mySystemsAreLoading && selectedTab === NavBarItems.MySystems) {
            loadUserSystems();
        }
    }, [loadUserSystems, mySystemsAreLoading, selectedTab]);

    const getUserPermissions = async () => {
        var tempUserPermissions = await AppRegistryClient.userPermissions.getRootPermissions();
        var user = new UserPrincipal(tempUserPermissions);
        setUserPrincipal(user);
    };

    const createEnabled = userPrincipal?.hasPermission(Permission.System_Create);

    const navigate = useNavigate();

    useEffect(() => {
        getUserPermissions();
    }, []);

    return (
        <ScrollPageContent>
            <PageHeading>
                <div className="col">
                    <h1>
                        <Icon path={mdiIcon} size={1.6} /> {title}
                    </h1>
                </div>
            </PageHeading>

            <Container>
                <Row>
                    <Col className="col-11">
                        <NavBar>
                            <NavBarItem target={NavBarItems.MySystems.toString()} active={true} onClick={setSelectedTab}>
                                <i className="fa fa-star"></i>
                                <span> My Systems </span>
                                <Badge className="row-count">{mySystemsAreLoading ? '?' : mySystems.length}</Badge>
                            </NavBarItem>
                            <NavBarItem target={NavBarItems.AllSystems.toString()} onClick={setSelectedTab}>
                                <span> All Systems </span>
                                <Badge className="row-count">{allSystemsAreLoading ? '?' : allSystems.length}</Badge>
                            </NavBarItem>
                        </NavBar>
                    </Col>
                    <Col className="col-1 center">
                        <Container>
                            <ActionButton
                                disabled={!createEnabled}
                                title={'Add system'}
                                outlined={true}
                                size={1.25}
                                mdiIcon={mdiPlus}
                                variant={'btn-green container-fluid'}
                                onClick={async () => {
                                    const url = `/systems/new`;
                                    navigate(url);
                                }}
                            />
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="tab-content" id="pills-tabContent">
                            <div
                                className="tab-pane fade show active container"
                                id={NavBarItems.MySystems.toString()}
                                role="tabpanel"
                                aria-labelledby={`${NavBarItems.MySystems.toString()}-tab`}
                            >
                                <SystemsTable isLoading={mySystemsAreLoading} data={mySystems} />
                            </div>
                            <div
                                className="tab-pane fade container"
                                id={NavBarItems.AllSystems.toString()}
                                role="tabpanel"
                                aria-labelledby={`${NavBarItems.AllSystems.toString()}-tab`}
                            >
                                <SystemsTable isLoading={allSystemsAreLoading} data={allSystems} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </ScrollPageContent>
    );
}
