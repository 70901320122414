import { useState, useEffect, useCallback } from 'react';
import { AppSource, Application } from '../../../../api/models';
import { AppSourcesEnhancedTable } from './AppSourcesEnhancedTable';
import { tryCallApi } from '../../../../api/Helpers';
import appRegistryClient from '../../../../api/AppRegistryClient';

interface AppSourcesTabProps {
    app?: Application;
    isSelected: boolean;
}
export function AppSourcesTab({ app, isSelected }: AppSourcesTabProps) {
    const [appSources, setAppSources] = useState(Array<AppSource>());
    const [dataIsLoading, setDataIsLoading] = useState(true);

    const loadAppSources = useCallback(async () => {
        let appSourcesResult = new Array<AppSource>();
        const success = await tryCallApi(async () => {
            appSourcesResult = await appRegistryClient.appSources.getByApplicationId(app!.id);
        });
        if (success) {
            setAppSources(appSourcesResult);
        }
        setDataIsLoading(false);
    }, [app]);

    useEffect(() => {
        if (isSelected && dataIsLoading && app?.id !== undefined) {
            loadAppSources();
        }
    }, [isSelected, dataIsLoading, app?.id, loadAppSources]);

    return <AppSourcesEnhancedTable isLoading={dataIsLoading} data={appSources} />;
}
