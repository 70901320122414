import { Col, Row } from 'react-bootstrap';
import { useCallback, useEffect, useState } from 'react';
import { CodeTypeSettings } from '../../../../api/models';
import { UserPrincipal } from '../Types/UserPrincipal';
import AppRegistryClient from '../../../../api/AppRegistryClient';
import { CodeTypesTable } from './Types/CodeTypesTable';
import { CodeValuesTable } from './Values/CodeValuesTable';
import { Loading } from '../Shared/Loading';
import { CodeTypeSort } from '../Attributes/CodeTypeSort';
import { CodeTypeId } from './CodeTypeId';

interface CodeTypesDetailsProps {}

export const CodeTypesDetails = (props: CodeTypesDetailsProps) => {
    const [userPrincipal, setUserPrincipal] = useState<UserPrincipal>(new UserPrincipal());
    const [codeTypes, setCodeTypes] = useState(new Array<CodeTypeSettings>());
    const [selectedType, setSelectedType] = useState({} as CodeTypeSettings);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getUserPermissions = useCallback(async () => {
        var tempUserPermissions = await AppRegistryClient.userPermissions.getRootPermissions();
        var user = new UserPrincipal(tempUserPermissions);
        setUserPrincipal(user);
    }, []);

    const getCodeTypes = useCallback(async () => {
        const codeTypes = (
            [
                { id: CodeTypeId.ApplicationState, name: 'Application State', isActive: true, permissionPrefix: 'ApplicationState' },
                { id: CodeTypeId.ApplicationType, name: 'Application Type', isActive: true, permissionPrefix: 'ApplicationType' },
                { id: CodeTypeId.AppRegistrationType, name: 'App Registration Types', isActive: true, permissionPrefix: 'AppRegistrationType' },
                { id: CodeTypeId.DeploymentSource, name: 'Deployment Sources', isActive: true, permissionPrefix: 'DeploymentSource' },
                { id: CodeTypeId.DiscoverySource, name: 'Discovery Sources', isActive: true, permissionPrefix: 'DiscoverySource' },
                { id: CodeTypeId.EnvironmentType, name: 'Environment Type', isActive: true, permissionPrefix: 'EnvironmentType' },
                { id: CodeTypeId.AttributeValueType, name: 'Attribute Value Type', isActive: true, permissionPrefix: 'AttributeValueTypes' },
            ] as CodeTypeSettings[]
        ).sort(CodeTypeSort);

        setCodeTypes(codeTypes);
        setSelectedType(codeTypes[0]);
        setIsLoading(false);
    }, []);

    useEffect(() => {
        getCodeTypes();
    }, [getCodeTypes]);

    useEffect(() => {
        getUserPermissions();
    }, [getUserPermissions]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Row>
            <Col>
                <CodeTypesTable userPrincipal={userPrincipal} codeTypes={codeTypes} selectedType={selectedType} onSelectedChanged={setSelectedType} />
            </Col>
            <Col>
                <CodeValuesTable userPrincipal={userPrincipal} selectedType={selectedType} />
            </Col>
        </Row>
    );
};
