import { Col, Row } from 'react-bootstrap';
import { AppSourceExpanded, AppSource } from '../../../../api/models';
import appRegistryClient from '../../../../api/AppRegistryClient';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Filter } from '../Shared/Filter';
import { AppSourcesTable } from './AppSourcesTable';
import { CodeTypeUtils } from '../Shared/CodeTypeUtils';

export interface AppSourcesEnhancedTableProps {
    isLoading: boolean;
    data: AppSource[];
}

export const AppSourcesEnhancedTable = ({ isLoading, data }: AppSourcesEnhancedTableProps) => {
    const [applicationSources, setApplicationSources] = useState<AppSourceExpanded[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [dataIsLoading, setDataIsLoading] = useState(true);

    const handleSearch = (searchValue: string) => {
        setSearchTerm(searchValue);
    };

    const searchedData = useMemo(() => {
        const searchString = searchTerm.trim().toLowerCase();

        if (searchString.length === 0) {
            return applicationSources;
        }

        return applicationSources.filter((applicationSource: AppSourceExpanded) => {
            return JSON.stringify(applicationSource).toLowerCase().includes(searchString);
        });
    }, [searchTerm, applicationSources]);

    const loadApplicationSources = useCallback(async () => {
        const appSourceTypesTemp = await appRegistryClient.appSourceTypes.getAll();

        const applicationSources = data.map((appSource) => {
            const appSourceType = CodeTypeUtils.findCodeType(appSourceTypesTemp, appSource.source);
            return {
                ...appSource,
                sourceType: appSourceType,
            } as AppSourceExpanded;
        });

        setApplicationSources(applicationSources);
        setDataIsLoading(false);
    }, [data]);

    useEffect(() => {
        if (!isLoading) {
            loadApplicationSources();
        }
    }, [isLoading, loadApplicationSources]);

    return (
        <div>
            <Row>
                <Col>
                    <Filter onUpdate={handleSearch} />
                </Col>
            </Row>
            <AppSourcesTable data={searchedData} isLoading={dataIsLoading} />
        </div>
    );
};
