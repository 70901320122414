import { Row } from 'react-bootstrap';
import { useState } from 'react';
import { AppRegistration, EnvironmentType } from '../../../../api/models';
import { Loading } from '../Shared/Loading';
import { AppRegistrationSearch } from './AppRegistrationSearch';
import { ConfirmationModal, VerbiageType } from '../Shared/ConfirmationModal';
import { ValidatableSelect } from '../Shared/ValidatableSelect';
import { SelectOptionString } from '../Types/SelectOption';
import { DisplayText } from '../Shared/DisplayText';
import { DisplayCheckbox } from '../Shared/DisplayCheckbox';

interface AppRegistrationLinkProps {
    showLink: boolean;
    environmentTypes: EnvironmentType[];
    closeModal: () => void;
    onLink: (appRegistration: AppRegistration, environment: string) => void;
}

export function AppRegistrationLink({ showLink, environmentTypes, closeModal, onLink }: AppRegistrationLinkProps) {
    const [loading, setLoading] = useState(false);
    const [environment, setEnvironment] = useState<string>('');
    const [registryValidity, setRegistryValidity] = useState(false);
    const [environmentValidity, setEnvironmentValidity] = useState(false);
    const [appRegistration, setAppRegistration] = useState<AppRegistration>();

    const checkRegistrationErrors = (appRegName: string, registration: AppRegistration): string[] => {
        let errors = [];
        const isValid = !(appRegName === undefined || registration === undefined || registration.clientId === '' || registration.clientId === null);
        const isAlreadyLinked = !(registration.applicationId === undefined || registration.applicationId === '' || registration.applicationId === null);
        if (!isValid) {
            errors.push('App registration is required');
        }
        if (isAlreadyLinked) {
            errors.push('App registration is already linked to an app');
        }
        return errors;
    };

    const environmentOptions = environmentTypes.map((type: EnvironmentType) => new SelectOptionString(type.name, type.id));
    const enabled = environmentOptions?.length > 0;

    return (
        <>
            <ConfirmationModal
                show={showLink}
                enabled={registryValidity && environmentValidity}
                title={'Link App Registration'}
                verbiageType={VerbiageType.SaveCancel}
                onCancel={() => closeModal()}
                onConfirm={async () => {
                    setLoading(true);
                    await onLink(appRegistration!, environment!);
                    setLoading(false);
                    closeModal();
                }}
            >
                <Row>{loading ? <Loading text={'Saving...'}></Loading> : <></>}</Row>
                <Row>
                    <AppRegistrationSearch
                        onChange={(appRegName: string, registration: AppRegistration) => {
                            setAppRegistration(registration);
                        }}
                        validate={(appRegName: string, registration: AppRegistration) => {
                            let errors = checkRegistrationErrors(appRegName, registration);
                            setRegistryValidity(errors.length === 0);
                            return errors;
                        }}
                        focus={false}
                    />
                </Row>
                <Row>
                    <ValidatableSelect
                        disabled={!enabled}
                        label={'Environment*'}
                        options={environmentOptions}
                        onChange={(value) => setEnvironment(value || '')}
                        validate={(value) => {
                            let errors = [];
                            if (!value || value.length === 0) {
                                errors.push('Environment must be selected.');
                            }
                            setEnvironmentValidity(errors.length === 0);
                            return errors;
                        }}
                        selectedValue={environment}
                    />
                </Row>
                <Row>
                    <DisplayText labelSize={3} label="App Registration Client Id:" value={appRegistration?.clientId} />
                </Row>
                <Row>
                    <DisplayCheckbox
                        labelSize={3}
                        label="Already Linked:"
                        checked={appRegistration?.applicationId !== null && appRegistration?.applicationId !== '' ? true : false}
                    />
                </Row>
            </ConfirmationModal>
        </>
    );
}
