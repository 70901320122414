import { Table } from 'react-bootstrap';
import { AppState, AppType, Application } from '../../../../../api/models';
import { useCallback, useEffect, useState } from 'react';
import AppRegistryClient from '../../../../../api/AppRegistryClient';
import { SystemApplicationsTableRow } from './SystemApplicationsTableRow';
import { tryCallApi } from '../../../../../api/Helpers';

export interface SystemApplicationsTableProps {
    applications?: Application[];
}

const SystemApplicationsTable = (props: SystemApplicationsTableProps) => {
    const [appTypes, setAppTypes] = useState<AppType[]>([]);
    const [appStates, setAppStates] = useState<AppState[]>([]);

    const getAppTypes = useCallback(async () => {
        await tryCallApi(async () => {
            const result = await AppRegistryClient.getAllAppTypes();
            setAppTypes(result);
        });
    }, []);

    const getAppStates = useCallback(async () => {
        let result = new Array<AppState>();
        const callResult = await tryCallApi(async () => {
            result = await AppRegistryClient.getAllAppStates();
        });
        if (callResult) {
            setAppStates(result);
        }
    }, []);

    useEffect(() => {
        getAppTypes();
        getAppStates();
    }, [getAppTypes, getAppStates]);

    const assignTypeName = (typeId: string) => {
        const type = appTypes.find((appType) => appType.id === typeId);
        return type ? type.name : '';
    };

    const assignStateName = (stateId: string) => {
        const state = appStates.find((appState) => appState.id === stateId);
        return state ? state.name : '';
    };

    return (
        <div className="table-wrapper">
            <Table striped={true} className="table-fixed-header">
                <thead className="thead-white">
                    <tr>
                        <th scope="col" className="col-3">
                            Application Unique Name
                        </th>
                        <th scope="col" className="col-3">
                            Application Display Name
                        </th>
                        <th scope="col" className="col-3">
                            Application Type
                        </th>
                        <th scope="col" className="col-3">
                            Application State
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {props.applications?.map(function (d) {
                        return <SystemApplicationsTableRow uniqueName={d.uniqueName} displayName={d.displayName} type={assignTypeName(d.type)} state={assignStateName(d.state)} />;
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default SystemApplicationsTable;
