import { Col, Row } from 'react-bootstrap';
import { System, UpdateSystemRequest } from '../../../../api/models';
import { useState } from 'react';
import appRegistryClient from '../../../../api/AppRegistryClient';
import { getApiErrors } from '../../../../api/Helpers';
import SystemEdit from './SystemEdit';

interface SystemEditInfoProps {
    system: System;
    clearEditCallback: (app: System | undefined) => void;
}

export const SystemEditInfo = ({ system, clearEditCallback }: SystemEditInfoProps) => {
    const [apiErrors, setApiErrors] = useState<Map<string, string[]>>();

    const updateHandler = async (system: System) => {
        const request = {
            displayName: system.displayName,
            description: system.description,
            itOwnerId: system.itOwner.id,
        } as UpdateSystemRequest;
        try {
            await appRegistryClient.systems.put(system.id, request);
            clearEditCallback(system);
        } catch (error) {
            const errorMap = getApiErrors(error);
            setApiErrors(errorMap);
        }
    };

    return (
        <>
            <Row>
                <Col md={7}>
                    <SystemEdit
                        isCreateMode={false}
                        sys={system}
                        saveHandler={updateHandler}
                        onCancel={() => {
                            clearEditCallback(undefined);
                        }}
                        apiErrors={apiErrors}
                    ></SystemEdit>
                </Col>
            </Row>
        </>
    );
};
