import { forwardRef, useImperativeHandle, useRef } from 'react';
import { ConsolidationStepProps } from '../ConsolidationStepProps';
import { DuplicateAppsEdit } from './DuplicateAppsEdit';
import { ConsolidationWizardModel } from '../../ConsolidationWizard';

interface DuplicateAppsStepProps extends ConsolidationStepProps {}

export const DuplicateAppsStep = forwardRef<(() => Promise<boolean>) | undefined, DuplicateAppsStepProps>(
    ({ formModel, setFormModel, setCurrentStatus }: DuplicateAppsStepProps, ref) => {
        const childRef = useRef<() => Promise<boolean>>();

        const setForm = (formValue: Record<string, any>) => {
            const subRequestValue = formValue as ConsolidationWizardModel;
            setFormModel(subRequestValue);
        };

        const validate = async () => {
            let isValid = false;
            if (childRef?.current) {
                isValid = await childRef.current();
            }
            if (!isValid) {
                setCurrentStatus('error');
            }
            return isValid;
        };

        useImperativeHandle(ref, () => validate);

        return <DuplicateAppsEdit formModel={formModel} setFormModel={setForm} ref={childRef} />;
    }
);
