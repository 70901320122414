import { toast } from 'react-toastify';
import AppRegistryClient from '../../../../../../api/AppRegistryClient';
import { Application } from '../../../../../../api/models';

export const findConsolidationCandidates = async (duplicateIds: string[], searchTerm: string, matchScore: number = 85): Promise<Application[] | undefined> => {
    try {
        const oData = {
            filter: `id in ('${duplicateIds.join("','")}') eq false`,
            top: 30,
            expand: 'deliveryTeam,system,attributes,appRegistrations,deployments,sources',
        };

        const candidates = await AppRegistryClient.consolidationCandidates(oData, [searchTerm], matchScore);
        if (candidates.length === 0) {
            toast.warning('No candidates found');
        }
        return candidates;
    } catch {
        toast.error('Error while finding candidates');
    }
};
