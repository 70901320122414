import MicrosoftGraphClient from './MicrosoftGraphClient';
import { msalInstance } from '..';

export const msGraphClient = new MicrosoftGraphClient();

const loadActiveAccount = () => {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        const allAccounts = msalInstance.getAllAccounts();
        if (allAccounts && allAccounts.length > 0) {
            msalInstance.setActiveAccount(allAccounts[0]);
        }
    }
};

export const getAccessToken = async (scopes: string[]) => {
    loadActiveAccount();
    var account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    }

    const request = { scopes, account: account };
    var token = '';
    await msalInstance
        .acquireTokenSilent(request)
        .then((response) => {
            token = response.accessToken;
        })
        .catch(async (e) => {
            await msalInstance.acquireTokenPopup(request).then((response) => {
                token = response.accessToken;
            });
        });

    return token;
};
