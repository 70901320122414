import { ApiAbortedError, ApiError, ApiNonSuccessError, ApiRequest, BadRequestApiError, BadRequestValidationError } from './models';

const appFetchJsonNullable = async <T>(request: ApiRequest): Promise<T | undefined> => {
    const response = await appFetch(request).catch((e) => {
        throw e;
    });

    if (!response || response.status === 204) {
        return undefined;
    }

    const json = await response.json();

    return json;
};

const appFetchJson = async <T>(request: ApiRequest): Promise<T> => {
    const json = await appFetchJsonNullable<T>(request);

    if (json === undefined) {
        throw new Error();
    }

    return json;
};

function appFetch(request: ApiRequest) {
    var abortablePromise = new Promise<Response>((resolve, reject) => {
        _appFetch(request)
            .then(resolve)
            .catch((reason) => {
                reject(reason);
            });
    });

    return abortablePromise;
}

function getBadRequestError(response: any) {
    let result = {
        title: 'Wrong type of error - Bad Request expected',
        errors: new Map<string, string[]>(),
        message: '',
    } as BadRequestValidationError;
    if (!response) {
        return result;
    }
    if (response.title) {
        result.title = response.title;
    }
    if (response.errors) {
        for (const [propertyName, v] of Object.entries(response.errors)) {
            const detailErrors = v as string[];
            if (detailErrors) {
                result.errors.set(propertyName, detailErrors);
            }
        }
    }
    if (response.Message) {
        result.message = response.Message as string;
    }
    return result;
}

async function _appFetch(request: ApiRequest) {
    const requestInit: RequestInit = {
        method: request.method ?? (request.method === undefined && request.body !== undefined ? 'POST' : 'GET'),
        headers: {
            Accept: 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: `Bearer ${request.accessToken}`,
        },
        body: JSON.stringify(request.body),
    };

    try {
        const response = await fetch(request.url, requestInit);

        if (response.ok) {
            return response;
        }

        switch (response.status) {
            case 400: {
                const json = await response.json();
                const error = getBadRequestError(json);
                throw new BadRequestApiError(error);
            }
            case 401:
            case 403: {
                const json = await response.json();
                const apiError = json as ApiError;
                const message = apiError?.Message;
                throw new ApiNonSuccessError(response.status, message);
            }
            default: {
                throw new ApiNonSuccessError(response.status);
            }
        }
    } catch (e) {
        if (e instanceof DOMException && e.code === e.ABORT_ERR) {
            throw new ApiAbortedError(e);
        }

        throw e;
    }
}

abstract class ClientBase {
    constructor(protected baseUrl: string) {}

    protected appFetchJsonNullable = async <T = any>(request: ApiRequest): Promise<T | undefined> => {
        const rewrote = { ...request, url: `${this.baseUrl}/${request.url}` };

        return await appFetchJsonNullable(rewrote);
    };

    protected appFetchJson = async <T = any>(request: ApiRequest): Promise<T> => {
        const rewrote = { ...request, url: `${this.baseUrl}/${request.url}` };

        return await appFetchJson(rewrote);
    };

    protected appFetch = async (request: ApiRequest) => {
        const rewrote = { ...request, url: `${this.baseUrl}/${request.url}` };

        return await appFetch(rewrote);
    };
}

export default ClientBase;
