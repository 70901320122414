import { Table } from 'react-bootstrap';
import { EventLog } from '../../../../api/models';
import { EmptyDataRow } from '../UIExtensions/TableUtilities';
import { EventLogRow } from './EventLogRow';

interface EventLogViewProps {
    objectName: string;
    isLoading: boolean;
    isDataNotFound: boolean;
    eventList: EventLog[];
}

export const EventLogView = (props: EventLogViewProps) => {
    return (
        <div className="table-wrapper">
            <Table striped className="table-sm table-fixed-header small">
                <thead className="thead-white">
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Type</th>
                        <th scope="col">Operation</th>
                        <th scope="col">User</th>
                        <th scope="col">Summary</th>
                    </tr>
                </thead>
                <tbody>
                    {props.eventList !== undefined && props.eventList !== null && props.eventList.length > 0 ? (
                        props.eventList.map(function (d) {
                            return <EventLogRow event={d} key={d.id} />;
                        })
                    ) : (
                        <EmptyDataRow colSpan={6} />
                    )}
                </tbody>
            </Table>
        </div>
    );
};
