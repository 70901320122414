import { useState, useEffect, useCallback } from 'react';
import { Application, EnvironmentType } from '../../../../../../api/models';
import appRegistryClient from '../../../../../../api/AppRegistryClient';
import { tryCallApi } from '../../../../../../api/Helpers';
import { EndpointsTable } from '../../../Endpoints/EndpointsTable';
import { Loading } from '../../../Shared/Loading';

interface ConsolidationEndpointsTableProps {
    app: Application;
}
export function ConsolidationEndpointsTable({ app }: ConsolidationEndpointsTableProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [availableEnvironmentTypes, setAvailableEnvironmentTypes] = useState<Array<EnvironmentType>>();

    const loadAvailableEnvironments = useCallback(async () => {
        let environmentTypes = new Array<EnvironmentType>();
        const success = await tryCallApi(async () => {
            environmentTypes = await appRegistryClient.environmentTypes.getAll();
        });
        if (success) {
            setAvailableEnvironmentTypes(environmentTypes);
        }
    }, []);

    const loadData = useCallback(async () => {
        await loadAvailableEnvironments();
        setIsLoading(false);
    }, [loadAvailableEnvironments]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const emptyHandler = async () => true;

    if (isLoading) {
        return <Loading />;
    }

    return (
        <EndpointsTable
            applicationId={app.id}
            data={app.endpoints!}
            availableEnvironmentTypes={availableEnvironmentTypes}
            actionsVisible={false}
            actionsEnabled={false}
            deleteHandler={emptyHandler}
            updateHandler={emptyHandler}
        />
    );
}
