import { mdiCreditCardSearch, mdiRegisteredTrademark, mdiRocketLaunch, mdiTagMultiple } from '@mdi/js';
import { Application } from '../../../../../api/models';
import { TextWithIcon } from '../../UIExtensions/Text';

interface ConsolidationDependenciesProps {
    application: Application | undefined;
}

export const ConsolidationDependencies = ({ application }: ConsolidationDependenciesProps) => {
    if (!application) {
        return <></>;
    }
    return (
        <>
            {application?.attributes?.length > 0 ? <TextWithIcon size={1.5} icon={mdiTagMultiple} text="" title="Has Attribute(s)" /> : <></>}
            {application?.appRegistrations?.length > 0 ? <TextWithIcon size={1.5} icon={mdiRegisteredTrademark} text="" title="Has App Registration(s)" /> : <></>}
            {application?.sources?.length > 0 ? <TextWithIcon size={1.5} icon={mdiCreditCardSearch} text="" title="Has Source(s)" /> : <></>}
            {application?.deployments !== undefined && application?.deployments?.length > 0 ? (
                <TextWithIcon size={1.5} icon={mdiRocketLaunch} text="" title="Has Deployment(s)" />
            ) : (
                <></>
            )}
        </>
    );
};
