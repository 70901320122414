import { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { System, CreateSystemRequest } from '../../../../api/models';
import { ValidatableInput } from '../Shared/ValidatableInput';
import { nameof } from 'ts-simple-nameof';
import { setField } from '../../../../helpers/FieldSetter';
import { UserSearch } from '../Shared/Search/UserSearch';
import { GroupSearch } from '../Shared/Search/GroupSearch';
import { SelectOptionString } from '../Types/SelectOption';

interface SystemEditProps {
    isCreateMode: boolean;
    sys: System;
    saveHandler: (sys: System, systemOwnerId: string) => void;
    onCancel: () => void;
    apiErrors?: Map<string, string[]> | undefined;
}

function SystemEdit({ isCreateMode, sys, saveHandler, onCancel, apiErrors }: SystemEditProps) {
    const [systemOwnerId, setSystemOwnerId] = useState('');
    const [system, setSystem] = useState(sys);

    const [enabled, setEnabled] = useState(false);

    const [fieldsValidityState, setFieldsValidityState] = useState(new Map<string, boolean>());
    const fieldsValidity = useRef(new Map<string, boolean>());

    const onFieldChanged = function <T>(fieldName: string, value: T) {
        const copy = structuredClone(system);
        if (setField(copy, fieldName, value)) {
            setSystem(copy);
        }
    };

    const setFieldValidity = function (fieldName: string, valid: boolean) {
        fieldsValidity.current.set(fieldName, valid);
        const fieldsValidityCopy = new Map(fieldsValidity.current);
        setFieldsValidityState(fieldsValidityCopy);
    };

    useEffect(() => {
        const valid = Array.from(fieldsValidityState.values()).every((valid) => valid);
        setEnabled(valid);
    }, [fieldsValidityState]);

    const displayNamePropertyName = nameof<System>((r) => r.displayName);
    const descriptionPropertyName = nameof<System>((r) => r.description);
    const itOwnerPropertyName = nameof<System>((r) => r.itOwner);
    const systemOwnerIdsPropertyName = nameof<CreateSystemRequest>((r) => r.owners);

    return (
        <>
            <Row>
                <ValidatableInput
                    fieldName={'Display Name*'}
                    disabled={false}
                    value={system.displayName}
                    placeholder={"Name e.g. 'System Name'"}
                    onChange={(value: string) => {
                        onFieldChanged(displayNamePropertyName, value);
                    }}
                    validate={(value: string) => {
                        let errors = [];
                        if (value === undefined || value.length === 0) {
                            errors.push('Display name is a required field.');
                        }
                        setFieldValidity(displayNamePropertyName, errors.length === 0);
                        return errors;
                    }}
                />
            </Row>
            <Row>
                <ValidatableInput
                    fieldName={'Description'}
                    disabled={false}
                    value={system.description}
                    placeholder={"Description e.g. 'Longer description of this system'"}
                    onChange={(value: string) => {
                        onFieldChanged(descriptionPropertyName, value);
                    }}
                />
            </Row>
            <Row>
                <div className="form-group">
                    <label>It Owner*</label>
                    <UserSearch
                        value={new SelectOptionString(system.itOwner.displayName, system.itOwnerId)}
                        focus={false}
                        onChange={(userName: string, userId: string) => {
                            onFieldChanged(itOwnerPropertyName, { id: userId, displayName: userName });
                        }}
                        validate={(userName: string, userId: string) => {
                            let errors = [];
                            const isValid = !(userId === undefined || userId.length === 0);
                            setFieldValidity(itOwnerPropertyName, isValid);
                            if (!isValid) {
                                errors.push('IT Owner is required');
                            }
                            return errors;
                        }}
                        apiErrors={apiErrors?.get(itOwnerPropertyName)}
                    />
                </div>
            </Row>
            <Row>
                {isCreateMode ? (
                    <div className="form-group">
                        <label>System Owner*</label>
                        <GroupSearch
                            focus={false}
                            onChange={(userName: string, userId: string) => {
                                setSystemOwnerId(userId);
                            }}
                            validate={(userName: string, userId: string) => {
                                let errors = [];
                                const isValid = !(userId === undefined || userId.length === 0);
                                setFieldValidity(systemOwnerIdsPropertyName, isValid);
                                if (!isValid) {
                                    errors.push('System Owner is required');
                                }
                                return errors;
                            }}
                            apiErrors={apiErrors?.get(systemOwnerIdsPropertyName)}
                        />
                    </div>
                ) : (
                    ''
                )}
            </Row>
            <Row>
                <Col md={2}>
                    <Button
                        variant="light"
                        onClick={() => {
                            onCancel();
                        }}
                    >
                        Cancel
                    </Button>
                </Col>
                <Col>
                    <Button
                        variant="primary"
                        disabled={!enabled}
                        onClick={() => {
                            saveHandler(system, systemOwnerId);
                        }}
                    >
                        Save Changes
                    </Button>
                </Col>
            </Row>
        </>
    );
}
export default SystemEdit;
