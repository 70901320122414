import { useState } from 'react';
import { ActionButton } from '../UIExtensions/Buttons';
import { ConfirmationModal, VerbiageType } from '../Shared/ConfirmationModal';
import { Permission } from '../../../../api/models';
import { ActionAlert } from '../Shared/ActionAlert';
import { mdiTrashCan } from '@mdi/js';

interface PermissionDeleteProps {
    id: string;
    data: Permission;
    disabled: boolean;
    deleteHandler: (id: string, groupId: string, role: string) => Promise<boolean>;
}

function PermissionsDelete({ id, data, disabled, deleteHandler }: PermissionDeleteProps) {
    const groupId = data.groupId;
    const groupName = data.group?.displayName ?? 'Unloaded';
    const role = data.role;
    const [showDelete, setShowDelete] = useState(false);

    const key = `delete-permission-alert-${data.id}`;
    return (
        <>
            <ActionButton
                outlined={false}
                disabled={disabled}
                mdiIcon={mdiTrashCan}
                title={`Delete Permission`}
                variant="btn-red"
                onClick={async () => {
                    setShowDelete(true);
                }}
            />
            <ConfirmationModal
                show={showDelete}
                enabled={true}
                title={'Delete Permission'}
                verbiageType={VerbiageType.YesNo}
                onCancel={() => setShowDelete(false)}
                onConfirm={async () => {
                    if (await deleteHandler(id, groupId, role)) {
                        setShowDelete(false);
                    }
                }}
            >
                <ActionAlert key={key} variant="danger">
                    Are you sure you want to remove group&nbsp;<b>{groupName}</b>
                    &nbsp;from role&nbsp;<b>{role}</b>?
                </ActionAlert>
            </ConfirmationModal>
        </>
    );
}

export default PermissionsDelete;
