import { Col } from 'react-bootstrap';

export interface DisplayTextProps {
    labelSize: number;
    label: string;
    value: string | undefined;
}
export const DisplayText = ({ labelSize, label, value }: DisplayTextProps) => {
    return (
        <>
            <Col md={labelSize} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                {label}
            </Col>
            <Col>{value}</Col>
        </>
    );
};
