import { mdiPencil } from '@mdi/js';
import { AttributeTypeEdit } from './AttributeTypeEdit';
import { AttributeType, CodeType } from '../../../../../api/models';
import { CodeTypeDelete } from '../CodeTypeDelete';
import { CodeTypeActiveChange } from '../CodeTypeActiveChange';
import { UserPrincipal } from '../../Types/UserPrincipal';
import { Permission } from '../../Permissions/permission';
import { CodeTypePermissions } from '../../CodeTypes/CodeTypePermissions';

interface AttributeTypesTableRowProps {
    userPrincipal: UserPrincipal;
    attributeType: AttributeType;
    allTypes: AttributeType[];
    attributeValueTypes: CodeType[];
    selected: boolean;
    onClick: () => void;
    onEdited: (type: AttributeType) => Promise<Map<string, string[]> | undefined>;
    onActiveChanged: (type: CodeType) => Promise<Map<string, string[]> | undefined>;
    onDelete: (type: CodeType) => Promise<Map<string, string[]> | undefined>;
}

export const AttributeTypesTableRow = ({
    userPrincipal,
    attributeType,
    allTypes,
    attributeValueTypes,
    selected,
    onClick,
    onEdited,
    onActiveChanged,
    onDelete,
}: AttributeTypesTableRowProps) => {
    const className = selected ? 'selected' : '';

    const canUpdate = userPrincipal.hasPermission(CodeTypePermissions.canUpdate(Permission.AttributeTypesPermissionPrefix));
    const canDelete = userPrincipal.hasPermission(CodeTypePermissions.canDelete(Permission.AttributeTypesPermissionPrefix));
    const canDisable = userPrincipal.hasPermission(CodeTypePermissions.canDisable(Permission.AttributeTypesPermissionPrefix));
    const canEnable = userPrincipal.hasPermission(CodeTypePermissions.canEnable(Permission.AttributeTypesPermissionPrefix));
    const activeChangeEnabled = attributeType.isActive ? canDisable : canEnable;

    const nameClassName = attributeType.isActive ? '' : 'line-through';
    return (
        <tr className={className}>
            <td onClick={() => onClick()}>
                <div className={nameClassName}>{attributeType.name}</div>
            </td>
            <td>
                <div>
                    <AttributeTypeEdit
                        disabled={!canUpdate}
                        mdiIcon={mdiPencil}
                        variant="btn-dark-blue"
                        title="Edit"
                        type={attributeType}
                        allTypes={allTypes}
                        attributeValueTypes={attributeValueTypes}
                        onConfirm={onEdited}
                    />
                    |
                    <CodeTypeActiveChange disabled={!activeChangeEnabled} codeName="attribute-type" codeType={attributeType} onActiveChanged={onActiveChanged} />
                    |
                    <CodeTypeDelete disabled={!canDelete} codeName="attribute-type" codeType={attributeType} onDelete={onDelete} />
                </div>
            </td>
        </tr>
    );
};
