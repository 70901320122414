import { ActionAlert } from '../Shared/ActionAlert';
import { AppRegistrationActionElementProps } from './AppRegistrationActionTypes';

export function AppRegistrationUnlink({ appRegistration }: AppRegistrationActionElementProps) {
    const keyWarn = `unlink-app-registration-warn-${appRegistration.id}`;
    return (
        <>
            <ActionAlert key={keyWarn} variant="warning">
                Are you sure to unlink &nbsp; <b>{appRegistration.displayName}</b> &nbsp; from current application?
                <br />
                The App Registration will not be deleted in Entra ID.
            </ActionAlert>
        </>
    );
}
