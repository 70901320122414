import { ElementType, useCallback, useEffect, useRef, useState } from 'react';
import { ValidationErrors } from './ValidationErrors';
import { Form, Overlay, Tooltip } from 'react-bootstrap';

interface ValidatableInputProps {
    type?: ElementType<any>;
    fieldName: string;
    value: string;
    placeholder?: string;
    checkValidation?: boolean;
    validate?: (value: string) => string[];
    onChange?: (value: string) => void;
    disabled?: boolean;
    focus?: boolean;
    apiErrors?: string[];
    id?: string;
    rows?: number;
    tooltipText?: string;
}
export const ValidatableInput = (props: ValidatableInputProps) => {
    const [errors, setErrors] = useState(new Array<string>());
    const inputRef = useRef<HTMLInputElement>(null);
    const divRef = useRef(null);
    const [showTooltip, setShowTooltip] = useState(false);

    const onValueChanged = useCallback(
        async function (value: string) {
            if (props.validate) {
                setErrors(props.validate(value));
            }

            if (props.onChange) {
                props.onChange(value);
            }
        },
        [props]
    );

    useEffect(() => {
        onValueChanged(props.value);
    }, [props.value, onValueChanged]);

    useEffect(() => {
        if (props.checkValidation && props.validate) {
            setErrors(props.validate(props.value));
        }
    }, [props, onValueChanged]);

    useEffect(() => {
        if (props.validate) {
            let errors = props.validate(props.value);
            if (props.apiErrors) {
                errors = errors.concat(props.apiErrors);
            }
            setErrors(errors);
        }
    }, [props, onValueChanged]);

    useEffect(() => {
        if (props.focus) {
            inputRef.current?.focus();
        }
    }, [props.focus]);

    const tooltipId = `${props.id}-tooltip`;

    return (
        <>
            <div className="form-group" ref={divRef}>
                {props.fieldName === '' ? <></> : <Form.Label htmlFor={props.id}>{props.fieldName}</Form.Label>}
                <Form.Control
                    ref={inputRef}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    id={props.id}
                    aria-describedby={tooltipId}
                    onFocus={() => {
                        if (props.tooltipText) setShowTooltip(true);
                    }}
                    onBlur={() => {
                        if (props.tooltipText) setShowTooltip(false);
                    }}
                    rows={props.rows}
                    as={props.type ?? 'input'}
                    className={`form-control required ${errors.length === 0 ? '' : 'is-invalid'}`}
                    aria-required="true"
                    value={props.value}
                    onChange={async (e) => await onValueChanged(e.target.value)}
                />
                <ValidationErrors errors={errors} />
                {showTooltip ? (
                    <Overlay target={divRef.current} show={showTooltip} placement="right">
                        <Tooltip id={tooltipId}>{props.tooltipText}</Tooltip>
                    </Overlay>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
};
