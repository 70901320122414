import { useEffect, useState } from 'react';
import { AppRegConfigFormProps } from './AppRegTypes';
import appRegistryClient from '../../../../../api/AppRegistryClient';
import { ValidatableInput } from '../../Shared/ValidatableInput';

export function WebAppConfigForm({ setCallback, setIsValid }: AppRegConfigFormProps) {
    const [identifierUri, setIdentifierUri] = useState('');
    const [redirectUris, setRedirectUris] = useState('');

    useEffect(() => {
        setCallback(() => (appId: string, appRegId: string) => {
            return appRegistryClient.appRegistrationConfigurations.toWebApp(appId, appRegId, identifierUri, redirectUris.split('\n'));
        });
    }, [setCallback, identifierUri, redirectUris]);

    return (
        <>
            <ValidatableInput
                fieldName="Identifier URI:"
                value={identifierUri}
                tooltipText="Enter a globally unique URI used to identify this web app. Usually identical to your application URL."
                id="identifierUri"
                placeholder="e.g. https://beta.myapp.quad.com"
                validate={(v) => {
                    const errors = [];
                    if (!v) {
                        errors.push('Identifier URI is required');
                        setIsValid(false);
                    } else {
                        setIsValid(true);
                    }
                    return errors;
                }}
                onChange={(v) => {
                    setIdentifierUri(v);
                }}
            />
            <ValidatableInput
                type="textarea"
                fieldName="Redirect URLs:"
                tooltipText="Enter redirect URLs on separate lines"
                rows={5}
                value={redirectUris}
                id="redirectUris"
                placeholder="Example:&#10;https://beta.myapp.quad.com/signin-oidc&#10;https://localhost:3002/signin-oidc"
                validate={(v) => {
                    const errors = [];
                    if (!v) {
                        errors.push('At least one URL is required');
                        setIsValid(false);
                    } else {
                        setIsValid(true);
                    }
                    return errors;
                }}
                onChange={(v) => {
                    setRedirectUris(v);
                }}
            />
            Click <strong>Assign</strong> to configure this app registration as a <strong>web application</strong>.
        </>
    );
}
