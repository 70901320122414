import { Action } from '../shared/models';
import { ThemeState } from './models';
import types from './types';

const initialState: ThemeState = {};

const reducer = (state: ThemeState = initialState, action: Action<any>): ThemeState => {
    switch (action.type) {
        case types.USER_INFO_LOADED:
            return { ...state, userInfo: action.payload };
        case types.USER_NO_LONGER_AUTHENTICATED:
            return state.userInfo ? { ...state, userInfo: { ...state.userInfo } } : state;
        case types.APPLICATION_INFO_LOADED:
            return { ...state, applicationInfo: action.payload };
    }

    return state;
};

export default reducer;
