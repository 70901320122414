import { useEffect, useState } from 'react';
import { System } from '../../../../api/models';
import { SystemsBasicInfo } from './SystemsBasicInfo';

import { Loading } from '../Shared/Loading';
import { SystemEditInfo } from './SystemEditInfo';

interface SystemInfoWrapperProps {
    isLoading: boolean;
    system?: System;
    canEdit: boolean;
    isEditMode: boolean;
    setEditCallback: (isEdit: boolean) => void;
}

export const SystemInfoWrapper = ({ isLoading, system, canEdit, isEditMode, setEditCallback }: SystemInfoWrapperProps) => {
    const [sys, setSys] = useState(system);

    useEffect(() => {
        setSys(system);
    }, [system]);

    if (isLoading) {
        return <Loading />;
    }
    return (
        <>
            {isEditMode ? (
                <>
                    <SystemEditInfo
                        system={sys!}
                        clearEditCallback={(system) => {
                            if (system) {
                                setSys(system);
                            }
                            setEditCallback(false);
                        }}
                    />
                </>
            ) : (
                <>
                    <SystemsBasicInfo system={sys} canEdit={canEdit} showEditCallback={() => setEditCallback(true)} />
                </>
            )}
        </>
    );
};
