import { Col, Row } from 'react-bootstrap';
import { AppState, AppType, Application, System, Team, UpdateApplicationRequest } from '../../../../api/models';
import { useState } from 'react';
import appRegistryClient from '../../../../api/AppRegistryClient';
import { getApiErrors } from '../../../../api/Helpers';
import ApplicationEdit from './ApplicationEdit';
import { SelectOptionString } from '../Types/SelectOption';

interface ApplicationEditInfoProps {
    application: Application;
    appTypes?: AppType[];
    appStates?: AppState[];
    teams?: Team[];
    clearEditCallback: (app: Application | undefined) => void;
    systems?: System[];
}

export const ApplicationEditInfo = ({ application, appTypes, appStates, teams, clearEditCallback, systems }: ApplicationEditInfoProps) => {
    const [apiErrors, setApiErrors] = useState<Map<string, string[]>>();

    const updateHandler = async (application: Application) => {
        const request = {
            displayName: application.displayName,
            description: application.description,
            state: application.state,
            type: application.type,
            deliveryTeamId: application.deliveryTeamId,
            systemId: application.systemId,
        } as UpdateApplicationRequest;
        try {
            await appRegistryClient.updateApplication(application.id, request);
            clearEditCallback(application);
        } catch (error) {
            const errorMap = getApiErrors(error);
            setApiErrors(errorMap);
        }
    };

    const appTypeOptions = appTypes?.map((appType) => new SelectOptionString(appType.name, appType.id));
    const appStateOptions = appStates?.map((appState) => new SelectOptionString(appState.name, appState.id));
    const teamOptions = teams?.map((team) => new SelectOptionString(team.name, team.id!));
    const systemOptions = systems?.map((system) => new SelectOptionString(system.displayName, system.id!));
    return (
        <>
            <Row>
                <Col md={7}>
                    <ApplicationEdit
                        appTypes={appTypeOptions}
                        appStates={appStateOptions}
                        teams={teamOptions}
                        isCreateMode={false}
                        app={application}
                        saveHandler={updateHandler}
                        onCancel={() => {
                            clearEditCallback(undefined);
                        }}
                        apiErrors={apiErrors}
                        systems={systemOptions}
                    ></ApplicationEdit>
                </Col>
            </Row>
        </>
    );
};
