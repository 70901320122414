import { useState } from 'react';
import { AppRegistration, AppRegistrationResponse } from '../../../../../api/models';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { ClientConfigForm } from './ClientConfigForm';
import { WebApiConfigForm } from './WebApiConfigForm';
import { ApiCallback } from './AppRegTypes';
import { SpaConfigForm } from './SpaConfigForm';
import { ButtonWithIcon } from '../../UIExtensions/Buttons';
import { WebAppConfigForm } from './WebAppConfigForm';

interface AppRegistrationConfigurationProps {
    disabled: boolean;
    appRegistration: AppRegistration;
    onConfigurationAssgigned: (response: AppRegistrationResponse | undefined) => void;
}

export function AppRegistrationConfiguration({ disabled, appRegistration, onConfigurationAssgigned }: AppRegistrationConfigurationProps) {
    const [showModal, setShowModal] = useState(false);
    const [form, setForm] = useState<JSX.Element>();
    const [clientCallback, setClientCallback] = useState<ApiCallback>();
    const [isModalValid, setIsModalValid] = useState(true);
    const [submitting, setSubmitting] = useState(false);

    if (appRegistration.applicationType) {
        return <>{appRegistration.applicationType}</>;
    }

    interface ConfigMetadata {
        text: string;
        form: JSX.Element;
    }

    const configurations: ConfigMetadata[] = [
        {
            text: 'Client',
            form: <ClientConfigForm setCallback={setClientCallback} setIsValid={setIsModalValid} />,
        },
        {
            text: 'Single-Page App',
            form: <SpaConfigForm setCallback={setClientCallback} setIsValid={setIsModalValid} />,
        },
        {
            text: 'Web API',
            form: <WebApiConfigForm setCallback={setClientCallback} setIsValid={setIsModalValid} />,
        },
        {
            text: 'Web App',
            form: <WebAppConfigForm setCallback={setClientCallback} setIsValid={setIsModalValid} />,
        },
    ];

    async function assignConfig() {
        if (clientCallback) {
            setSubmitting(true);
            const result = await clientCallback(appRegistration.applicationId, appRegistration.id);
            setSubmitting(false);
            setShowModal(false);
            onConfigurationAssgigned(result);
        }
    }

    function popupModal(config: ConfigMetadata) {
        setSubmitting(false);
        setClientCallback(undefined);
        setForm(config.form);
        setShowModal(true);
    }

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle disabled={disabled} variant="outline-primary">
                    Assign
                </Dropdown.Toggle>
                <Dropdown.Menu popperConfig={{ strategy: 'fixed' }} renderOnMount>
                    {configurations.map((config) => (
                        <Dropdown.Item onClick={() => popupModal(config)} key={`appreg-${config.text}`}>
                            {config.text}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
            <Modal centered show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Assign Configuration</Modal.Title>
                </Modal.Header>
                <Modal.Body>{form}</Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <ButtonWithIcon variant="primary" disabled={!isModalValid} onClick={() => assignConfig()} spinning={submitting} text="&nbsp;&nbsp;Assign" icon="fa-thumbs-up" />
                </Modal.Footer>
            </Modal>
        </>
    );
}
