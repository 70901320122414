import BasePage from './components/BasePage';

const Home = () => {
    return (
        <BasePage name="App Registry Home">
            <h2>Welcome Home!</h2>
        </BasePage>
    );
};

export default Home;
