import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './areas/app/App';
import { msalConfig } from './appConfig';
import './scss/index.scss';
import { store } from './store';
import 'bootstrap';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const baseUrl = process.env.PUBLIC_URL;
export const msalInstance = new PublicClientApplication(msalConfig);

root.render(
    <React.StrictMode>
        {
            <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
                    <Provider store={store}>
                        <BrowserRouter basename={baseUrl}>
                            <App />
                        </BrowserRouter>
                    </Provider>
                </MsalAuthenticationTemplate>
            </MsalProvider>
        }
    </React.StrictMode>
);
