import { Col, Row } from 'react-bootstrap';
import appRegistryClient from '../../../../api/AppRegistryClient';
import { EventLog, System } from '../../../../api/models';
import { EventLogView } from '../EventLog/EventLogView';
import React, { useEffect, useMemo } from 'react';
import { mdiRefresh } from '@mdi/js';
import { ActionButton } from '../UIExtensions/Buttons';
import { tryCallApiCatchErrors } from '../../../../api/Helpers';
import { Filter } from '../Shared/Filter';
import { DateSort } from '../Shared/DateSort';

interface SystemEventLogProps {
    sys: System | undefined;
}

export const SystemEventLog = ({ sys }: SystemEventLogProps) => {
    const [eventList, setEventList]: [EventLog[], Function] = React.useState([]);
    const [isLoading, setLoadingStatus] = React.useState(true);
    const [isSpinning, setIsSpinning] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');

    const getEventLogs = async () => {
        if (sys) {
            setIsSpinning(true);
            await tryCallApiCatchErrors(
                async () =>
                    await appRegistryClient.eventLogs.get({
                        select: 'id,createdTimestamp,type,summary,correlationId',
                        expand: 'User($select=displayName)',
                        orderBy: 'createdTimestamp desc',
                        filter: `contains(Details,'"SystemId":"${sys.id}"')`,
                    }),
                (result) => {
                    if (result) {
                        result.sort((a, b) => {
                            return DateSort(a.createdTimestamp, b.createdTimestamp);
                        });
                        setEventList(result);
                    }
                }
            );
            setIsSpinning(false);
        }
        setLoadingStatus(false);
    };

    const handleSearch = (searchValue: string) => {
        setSearchTerm(searchValue);
    };

    const searchedEventsList = useMemo(() => {
        const searchString = searchTerm.trim().toLowerCase();

        if (searchString.length <= 2) {
            return eventList;
        }

        return eventList.filter((event: EventLog) => {
            return JSON.stringify(event).toLowerCase().includes(searchString);
        });
    }, [searchTerm, eventList]);

    useEffect(() => {
        getEventLogs();
    }, [sys]);

    return (
        <>
            <Row>
                <Col>{<Filter onUpdate={handleSearch} />}</Col>
                <Col md="1">
                    <ActionButton
                        spin={isSpinning}
                        outlined={true}
                        mdiIcon={mdiRefresh}
                        size={1}
                        variant="btn-blue container-fluid"
                        title="Refresh Event Log"
                        onClick={getEventLogs}
                    />
                </Col>
            </Row>
            <EventLogView eventList={searchedEventsList} isLoading={isLoading} isDataNotFound={false} objectName={sys?.displayName ? sys.displayName : ''} />
        </>
    );
};
