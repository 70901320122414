import { CloseButton, Col, Container, Modal, Row } from 'react-bootstrap';
import { ActionButton } from '../../../UIExtensions/Buttons';
import { ValidatableInput } from '../../../Shared/ValidatableInput';
import { DuplicatesSearchCheckboxTable } from './DuplicatesSearchCheckboxTable';
import { mdiCheckBold, mdiStoreSearch } from '@mdi/js';
import { useState } from 'react';
import { Application } from '../../../../../../api/models';
import { findConsolidationCandidates } from './FindConsolidationCandidates';

export interface DuplicatesSearchModalProps {
    app: Application;
    duplicateAppIds: string[];
    duplicatesSelected: (apps: Application[]) => void;
}

export const DuplicatesSearchModal = ({ app, duplicateAppIds, duplicatesSelected }: DuplicatesSearchModalProps) => {
    const defaultMatchScoreValue = 85;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState(app.displayName);
    const [matchScore, setMatchScore] = useState(defaultMatchScoreValue);
    const [duplicateCandidates, setDuplicateCandidates] = useState<Application[]>([]);
    const [duplicateApplications, setDuplicateApplications] = useState<Application[]>([]);
    const isSearchButtonDisabled = searchTerm.length > 0;
    const isCheckboxTableEmpty = duplicateApplications.length === 0;

    const handleValidatableRangeInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value ? parseFloat(event.target.value) : undefined;
        setMatchScore(newValue!);
    };

    const findCandidates = async () => {
        const duplicateIds = [...duplicateAppIds];
        duplicateIds.push(app?.id);
        const candidates = await findConsolidationCandidates(duplicateIds, searchTerm, matchScore);
        if (candidates) {
            setDuplicateCandidates(candidates!);
        }
    };

    return (
        <>
            <ActionButton
                disabled={false}
                title={'Search candidates for consolidation'}
                outlined={true}
                size={1.0}
                text={'Search candidates'}
                mdiIcon={mdiStoreSearch}
                variant={'btn-dark-blue container-fluid'}
                onClick={() => {
                    setIsModalOpen(true);
                }}
            />
            {isModalOpen ? (
                <Modal size="xl" centered show={true} onHide={() => setIsModalOpen(false)}>
                    <Modal.Header>
                        <Modal.Title>Choose applications to consolidate</Modal.Title>
                        <CloseButton onClick={() => setIsModalOpen(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col className="col-4">
                                    <Row>
                                        <Col className="col-4" style={{ marginTop: '8px' }}>
                                            Search term:
                                        </Col>
                                        <Col className="col-8">
                                            <ValidatableInput
                                                fieldName={''}
                                                value={searchTerm}
                                                disabled={false}
                                                onChange={(newValue: string) => {
                                                    setSearchTerm(newValue);
                                                }}
                                                validate={(newValue: string) => {
                                                    let errors = [];
                                                    if (newValue === undefined || newValue.length < 2) {
                                                        errors.push('Searchterm minimum length is 3 characters');
                                                    }
                                                    return errors;
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="col-5">
                                    <div>
                                        <div>
                                            <label>Match score: {matchScore}</label>
                                        </div>
                                        <input type="range" disabled={false} value={matchScore} min={50} max={100} step={1} onChange={handleValidatableRangeInputChange} />
                                    </div>
                                </Col>
                                <Col className="col-3 center">
                                    <ActionButton
                                        disabled={!isSearchButtonDisabled}
                                        title={'Search candidates (limit 30 apps)'}
                                        outlined={true}
                                        size={1.0}
                                        text={'Search candidates'}
                                        mdiIcon={mdiStoreSearch}
                                        variant={'btn-blue container-fluid'}
                                        onClick={async () => {
                                            await findCandidates();
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Container>
                        <DuplicatesSearchCheckboxTable isLoading={false} data={duplicateCandidates} arrayFillingFunction={setDuplicateApplications} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row>
                                <Col className="col-9"></Col>
                                <Col className="col-3 center">
                                    <ActionButton
                                        disabled={isCheckboxTableEmpty}
                                        title={'Add selected candidates to consolidation list'}
                                        outlined={true}
                                        text={'Add candidates'}
                                        size={1.0}
                                        mdiIcon={mdiCheckBold}
                                        variant={'btn-dark-blue container-fluid'}
                                        onClick={async () => {
                                            duplicatesSelected(duplicateApplications);
                                            await setDuplicateApplications([]);
                                            setDuplicateCandidates([]);
                                            setIsModalOpen(false);
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            ) : (
                <></>
            )}
        </>
    );
};
