export interface ApiRequest {
    url: string;
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    body?: object;
    accessToken?: string;
}

export interface ODataQuery {
    filter?: string;
    select?: string;
    skip?: number;
    top?: number;
    orderBy?: string;
    expand?: string;
}

export class ApiNonSuccessError extends Error {
    constructor(public status: number, message?: string) {
        super(message ? message : `Api Call returned non-success status code: ${status}.`);
    }
}

export class ApiAbortedError extends Error {
    constructor(public abortError: Error) {
        super('Api Call failed to being aborted');
    }
}

export interface ApiError {
    ActivityId: string;
    StatusCode: number;
    StatusDescription: string;
    Message: string;
    Timestamp: string;
}

export interface BadRequestValidationError {
    title: string;
    message: string;
    errors: Map<string, string[]>;
}

export class BadRequestApiError extends ApiNonSuccessError {
    constructor(public error: BadRequestValidationError) {
        const message = error.message ? error.message : 'Bad Request';
        super(400, message);
        this.internal = error;
    }
    internal: BadRequestValidationError;
}

export interface Application {
    id: string;
    uniqueName: string;
    displayName: string;
    description: string;
    state: string;
    type: string;
    deliveryTeamId?: string;
    deliveryTeam?: DeliveryTeam;
    appRegistrations: AppRegistration[];
    sources: AppSource[];
    attributes: ApplicationAttribute[];
    systemId?: string;
    system?: System;
    deployments?: ApplicationDeployment[];
    endpoints?: ApplicationEndpoint[];
}

export interface Department {
    id: string;
    name: string;
    isActive: boolean;
    impactName: string;
    impactId: number;
    teams: DeliveryTeam[];
}

export interface DeliveryTeam {
    id: string;
    name: string;
    departmentId?: string;
    isActive?: boolean;
    impactName?: string;
    impactId?: number;
}

export interface CodeType {
    id: string;
    name: string;
    isActive: boolean;
}

export interface CodeTypeSettings extends CodeType {
    permissionPrefix: string;
}

export interface AppType extends CodeType {}

export interface AppState extends CodeType {}

export interface AppRegistrationType extends CodeType {}

export interface EnvironmentType extends CodeType {}

export interface AppSourceType extends CodeType {}

export interface AttributeType extends CodeType {
    requiredRight?: string | null;
    allowMultiple: boolean;
    valueType: string;
}

export interface AttributeKey {
    value: string;
    type: string;
}

export interface AttributeValue extends CodeType, AttributeKey {}

export interface ApplicationAttribute extends AttributeKey {
    id: string;
    ApplicationId: string;
}

export interface ApplicationDeployment {
    id: string;
    startTimestamp: Date;
    endTimestamp: Date;
    applicationId: string;
    userId: string;
    environment: string;
    source: string;
    sourceId: string;
    version: string;
    user: UserInfo;
    details: string;
}

export interface Team {
    id?: string;
    departmentId: string;
    name: string;
    isActive: boolean;
    impactName: string;
    impactId: number;
}

export interface ApplicationRequest {
    displayName: string;
    description: string;
    type: string;
    state: string;
    systemId: string | null;
}

export interface UpdateApplicationRequest extends ApplicationRequest {
    deliveryTeamId: string;
}

export interface CreateApplicationRequest extends ApplicationRequest {
    uniqueName: string;
    ownerGroupId: string;
    deliveryTeamId: string | null;
}

export interface ConsolidateApplicationRequest {
    primaryApp: ConsolidatePrimaryApplicationRequest;
    duplicateAppIds: string[];
    whatIf: boolean;
}

export interface ConsolidatePrimaryApplicationRequest {
    id: string;
    displayName: string;
    description: string;
    type: string;
    deliveryTeamId: string;
    systemId: string;
}

export interface ConsolidateApplicationResponse {
    combinedPrimaryApp: Application;
    deletingApps: ConsolidateApplicationDeletingApp[];
    errors: ConsolidateApplicationError[];
}

export interface ConsolidateApplicationDeletingApp extends Application {
    attributes: ApplicationAttribute[];
    deploymentCount: number;
    lastDeploymentDate: Date | null;
}

export interface ConsolidateApplicationError {
    message: string;
}

export interface Code {
    value?: string;
    label: string;
}

export interface Permission {
    id: string;
    groupId: string;
    role: string;
    group: Group;
}

export interface ApplicationPermission extends Permission {
    applicationId: string;
}

export interface SystemPermission extends Permission {
    systemId: string;
}

export interface Group {
    id: string;
    displayName: string;
    type: string;
}

export interface AppRegistration {
    id: string;
    applicationId: string;
    applicationType: string;
    type: string;
    tenantId: string;
    clientId: string;
    providerUniqueId: string;
    displayName: string;
    environment: string;
}

export interface AppRegistrationExpanded extends AppRegistration {
    appRegistrationType?: AppRegistrationType;
    environmentType?: EnvironmentType;
}

export interface CreateAppRegistrationRequest {
    environment: string;
    displayName: string;
}

export interface StepResult {
    name: string;
    failed: boolean;
    message: string;
    details: string;
    started: boolean;
    skipped: boolean;
    applied: boolean;
}

export interface AppRegistrationResponse {
    appRegistration: AppRegistration;
    azureApp: AzureApp;
    applicationId: string;
    failed: boolean;
    stepResults: StepResult[];
}

export interface AzureApp {
    appId: string;
    displayName: string;
    description: string;
    publisherDomain: string;
    signInAudience: string;
    spa: AzureSpaApp;
    publicClient: AzurePublicClient;
    tags: string[];
    web: AzureWebApp;
    id: string;
}

export interface AzureSpaApp {
    redirectUris: string[];
}
export interface AzurePublicClient {
    redirectUris: string[];
}

export interface AzureWebApp {
    logoutUrl: string;
    redirectUris: string[];
}

export interface LinkAppRegistrationRequest {
    environment: string;
    objectId: string;
}

export interface PageSelect {
    selected: number;
}

export interface EventLog {
    id: string;
    applicationName: string;
    applicationId: string;
    machineName: string;
    correlationId: string;
    type: string;
    userId: string;
    createdTimestamp: Date;
    summary: string;
    details: string;
    user: UserInfo;
}

export interface UserInfo {
    id: string;
    employeeId: number;
    displayName: string;
    upn: string;
    firstName: string;
    lastName: string;
    email: string;
    createdTimestamp: Date;
    lastLoginTimestamp: Date;
    primarySid: string;
}

export interface AppSource {
    id: string;
    applicationId: string;
    source: string;
    sourceId: string;
    name: string;
}

export interface AppSourceExpanded extends AppSource {
    sourceType?: AppSourceType;
}

export interface System {
    id: string;
    displayName: string;
    description: string;
    itOwnerId: string;
    itOwner: UserInfo;
    applications: Application[];
}

export interface SystemRequest {
    displayName: string;
    itOwnerId: string;
    description: string;
}

export interface UpdateSystemRequest extends SystemRequest {}

export interface CreateSystemRequest extends SystemRequest {
    owners: string[];
}

export interface SearchResult {
    id: string;
    displayName: string;
}

export interface ApplicationEndpoint {
    id: string;
    applicationId: string;
    environment: string;
    endpoint: string;
}

export interface ApplicationEndpointRequest {
    environment: string;
    endpoint: string;
}
