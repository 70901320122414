interface ValidationErrorsProps {
    errors: string[];
}
export function ValidationErrors(props: ValidationErrorsProps) {
    return (
        <>
            <p className="text-danger">
                {props.errors.map((error: string) => (
                    <span style={{ display: 'inline-block', width: '100%' }} key={error}>
                        {error}
                    </span>
                ))}
            </p>
        </>
    );
}
