import { DeliveryTeam } from '../../../../api/models';

export interface TeamsTableRowProps {
    team: DeliveryTeam;
}

export const TeamsTableRow = ({ team }: TeamsTableRowProps) => {
    return (
        <tr>
            <td className={`col-5 ${team.isActive ? '' : 'line-through'}`}>{team.name}</td>
        </tr>
    );
};
