import { Link } from 'react-router-dom';

interface EntraIdAppRegistrationLinkProps {
    clientId: string | undefined;
    displayName: string | undefined;
}

export const EntraIdAppRegistrationLink = ({ clientId: appId, displayName }: EntraIdAppRegistrationLinkProps) => {
    if (appId && displayName) {
        return (
            <Link to={`https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Overview/appId/${appId}/isMSAApp~/false`} target="_blank">
                {displayName}
            </Link>
        );
    }
    return null;
};
