import { useState, useEffect, useCallback } from 'react';
import { Application, ApplicationEndpoint, EnvironmentType } from '../../../../api/models';
import appRegistryClient from '../../../../api/AppRegistryClient';
import { EndpointsEnhancedTable } from './EndpointsEnhancedTable';
import { tryCallApi } from '../../../../api/Helpers';

interface EndpointsTabProps {
    app?: Application;
    canPerformActions: boolean;
    isSelected: boolean;
}
export function EndpointsTab({ app, canPerformActions, isSelected }: EndpointsTabProps) {
    const [dataIsLoading, setDataIsLoading] = useState(true);
    const [endpoints, setEndpoints] = useState(Array<ApplicationEndpoint>());
    const [availableEnvironmentTypes, setAvailableEnvironmentTypes] = useState<Array<EnvironmentType>>();

    const loadEndpoints = useCallback(async () => {
        let endpointResult = new Array<ApplicationEndpoint>();
        const success = await tryCallApi(async () => {
            endpointResult = await appRegistryClient.applicationsEndpoints.get(app!.id);
        });
        if (success) {
            setEndpoints(endpointResult);
        }
    }, [app]);

    const loadAvailableEnvironments = useCallback(async () => {
        let environmentTypes = new Array<EnvironmentType>();
        const success = await tryCallApi(async () => {
            environmentTypes = await appRegistryClient.environmentTypes.getAll();
        });
        if (success) {
            setAvailableEnvironmentTypes(environmentTypes);
        }
    }, []);

    const loadData = useCallback(async () => {
        await loadEndpoints();
        await loadAvailableEnvironments();
        setDataIsLoading(false);
    }, [loadEndpoints, loadAvailableEnvironments]);

    useEffect(() => {
        if (isSelected && dataIsLoading && app?.id !== undefined) {
            loadData();
        }
    }, [isSelected, dataIsLoading, app?.id, loadData]);

    const changeHandler = async () => {
        setDataIsLoading(true);
        await loadEndpoints();
        setDataIsLoading(false);
    };

    const createHandler = async (applicationId: string, environment: string, endpoint: string): Promise<boolean> => {
        const success = await tryCallApi(async () => {
            await appRegistryClient.applicationsEndpoints.post(applicationId, { environment, endpoint });
        });
        changeHandler();
        return success;
    };

    const updateHandler = async (applicationId: string, environment: string, endpoint: string, endpointId?: string): Promise<boolean> => {
        const success = await tryCallApi(async () => {
            await appRegistryClient.applicationsEndpoints.put(applicationId, endpointId!, { environment, endpoint });
        });
        changeHandler();
        return success;
    };

    const deleteHandler = async (applicationId: string, endpointId: string): Promise<boolean> => {
        const success = await tryCallApi(async () => {
            await appRegistryClient.applicationsEndpoints.delete(applicationId, endpointId);
        });
        changeHandler();
        return success;
    };

    return (
        <EndpointsEnhancedTable
            applicationId={app?.id}
            data={endpoints}
            canPerformActions={canPerformActions}
            isLoading={dataIsLoading}
            availableEnvironmentTypes={availableEnvironmentTypes}
            deleteHandler={deleteHandler}
            createHandler={createHandler}
            updateHandler={updateHandler}
        />
    );
}
