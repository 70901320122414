import { mdiLoading } from '@mdi/js';
import Icon from '@mdi/react';
import { Col, Container, Row, Stack } from 'react-bootstrap';

interface LoadingProps {
    text?: string;
}

export const Loading = (props: LoadingProps) => {
    const text = props.text ?? 'Loading';
    return (
        <Stack gap={3}>
            <Container className="text-center">
                <Row>
                    <Col>
                        <h3>
                            <Icon path={mdiLoading} size={1} spin={true}></Icon>
                            {<>{text}...</>}
                        </h3>
                    </Col>
                </Row>
            </Container>
        </Stack>
    );
};
