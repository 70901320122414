import { AppSourceExpanded } from '../../../../api/models';

interface AppSourcesTableRowProps {
    appSource: AppSourceExpanded;
}

export const AppSourcesTableRow = ({ appSource }: AppSourcesTableRowProps) => {
    return (
        <tr>
            <td>{appSource.sourceType?.name}</td>
            <td>{appSource.sourceId}</td>
            <td>{appSource.name}</td>
        </tr>
    );
};
