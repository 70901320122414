import { Card, Container } from 'react-bootstrap';
import BasePage from '../BasePage';
import { useParams } from 'react-router';
import appRegistryClient from '../../../../api/AppRegistryClient';
import { Application } from '../../../../api/models';
import { useEffect, useState } from 'react';
import { ApplicationDetailsInner } from './ApplicationDetailsInner';
import { Loading } from '../Shared/Loading';

export const ApplicationDetails = () => {
    const appUniqueName = useParams<'uniqueName'>().uniqueName!;
    const [app, setApp] = useState<Application>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getApp = async (appUniqueName: string) => {
            try {
                const appResult = await appRegistryClient.applications.get({
                    filter: `uniqueName eq '${appUniqueName}'`,
                    top: 1,
                    expand: `deliveryTeam($select=name),appRegistrations,attributes,system($select=displayName)`,
                });
                if (appResult && appResult.length !== 0) {
                    const application = appResult[0];
                    setApp(application);
                }
            } finally {
                setIsLoading(false);
            }
        };
        getApp(appUniqueName);
    }, [appUniqueName]);

    if (isLoading) {
        return <Loading />;
    }
    return (
        <BasePage name={`${app?.displayName} Application Details`}>
            <Container fluid={true}>
                <Card>
                    <Card.Body>
                        <ApplicationDetailsInner app={app} isLoading={isLoading} isReadOnly={false} />
                    </Card.Body>
                </Card>
            </Container>
        </BasePage>
    );
};
