import { AppRegistrationExpanded, AppRegistrationResponse, AppRegistrationType, EnvironmentType } from '../../../../api/models';
import { AppRegistrationAction } from './AppRegistrationAction';
import { AppRegistrationConfiguration } from './Configurations/AppRegistrationConfiguration';
import { EntraIdAppRegistrationLink } from './EntraIdAppRegistrationLink';

interface AppRegistrationsTableRowProps {
    appRegistration: AppRegistrationExpanded;
    canPerformActions: boolean;
    onDelete: (appRegistration: AppRegistrationExpanded) => Promise<Map<string, string[]> | undefined>;
    onUnlink: (appRegistration: AppRegistrationExpanded) => Promise<Map<string, string[]> | undefined>;
    environmentTypes: EnvironmentType[];
    appRegistrationTypes: AppRegistrationType[];
    onConfigurationAssgigned: (response: AppRegistrationResponse | undefined) => void;
}

export const AppRegistrationsTableRow = ({
    appRegistration,
    canPerformActions,
    onDelete,
    onUnlink,
    environmentTypes,
    appRegistrationTypes,
    onConfigurationAssgigned,
}: AppRegistrationsTableRowProps) => {
    return (
        <tr>
            <td>
                <EntraIdAppRegistrationLink clientId={appRegistration.clientId} displayName={appRegistration.displayName} />
            </td>
            <td>{appRegistration.clientId}</td>
            <td>{appRegistration.providerUniqueId}</td>
            <td>{appRegistrationTypes.find((i) => i.id === appRegistration.type)?.name || appRegistration.type}</td>
            <td>{environmentTypes.find((i) => i.id === appRegistration.environment)?.name || appRegistration.environment}</td>
            <td>
                <AppRegistrationConfiguration disabled={!canPerformActions} appRegistration={appRegistration} onConfigurationAssgigned={onConfigurationAssgigned} />
            </td>
            <td>
                <AppRegistrationAction disabled={!canPerformActions} appRegistration={appRegistration} onDelete={onDelete} onUnlink={onUnlink} />
            </td>
        </tr>
    );
};
