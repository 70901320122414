import { Application, AppType, AppState } from '../../../../api/models';
import { useEffect, useState, useCallback, useMemo } from 'react';
import AppRegistryClient from '../../../../api/AppRegistryClient';
import { ReactTable } from '../Shared/ReactTable/ReactTable';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import { Link, useNavigate } from 'react-router-dom';
import { Loading } from '../Shared/Loading';
import { mdiInformation, mdiSetMerge } from '@mdi/js';
import { ActionButton } from '../UIExtensions/Buttons';

export interface ApplicationsTableProps {
    isLoading: boolean;
    data: Application[];
    consolidation: boolean;
}

interface ApplicationExpanded extends Application {
    appType?: AppType;
    appState?: AppState;
}

export const ApplicationsTable = ({ isLoading, data, consolidation }: ApplicationsTableProps) => {
    const [dataIsLoading, setDataIsLoading] = useState(true);
    const [appTypes, setAppTypes] = useState<AppType[]>([]);
    const [appStates, setAppStates] = useState<AppState[]>([]);
    const [applications, setApplications] = useState<ApplicationExpanded[]>([]);

    const getAppTypes = useCallback(async () => {
        try {
            const result = await AppRegistryClient.getAllAppTypes();
            setAppTypes(result);
        } catch {
            setAppTypes([]);
        }
    }, []);

    const getAppStates = useCallback(async () => {
        try {
            const result = await AppRegistryClient.getAllAppStates();
            setAppStates(result);
        } catch {
            setAppStates([]);
        }
    }, []);

    const loadData = useCallback(async () => {
        await getAppTypes();
        await getAppStates();
        setDataIsLoading(false);
    }, [getAppTypes, getAppStates]);

    const setExtendedProperties = useCallback(
        function (applications: Application[]): ApplicationExpanded[] {
            return applications.map((application) => {
                const appType = appTypes.find((appType) => appType.id === application.type);
                const appState = appStates.find((appState) => appState.id === application.state);
                return {
                    ...application,
                    appType: appType,
                    appState: appState,
                };
            });
        },
        [appStates, appTypes]
    );

    useEffect(() => {
        if (dataIsLoading) {
            loadData();
        }
    }, [dataIsLoading, loadData]);

    useEffect(() => {
        if (!isLoading && !dataIsLoading) {
            setApplications(setExtendedProperties(data));
        }
    }, [isLoading, dataIsLoading, setExtendedProperties, data]);

    const navigate = useNavigate();
    const columns = useMemo<ColumnDef<ApplicationExpanded, string>[]>(
        () => [
            {
                accessorKey: 'uniqueName',
                id: 'uniqueName',
                header: 'Name',
                cell: (info) => <Link to={`/applications/${info.getValue()}`}>{info.getValue()}</Link>,
                meta: {
                    filterVariant: 'text',
                    className: 'col-3',
                },
            },
            {
                accessorKey: 'displayName',
                id: 'displayName',
                header: 'Display Name',
                cell: (info) => info.getValue(),
                meta: {
                    filterVariant: 'text',
                    className: 'col-2',
                },
            },
            {
                accessorFn: (row) => ((row.appState?.name ?? '') !== '' ? row.appState?.name : ' '),
                id: 'state',
                header: 'State',
                cell: (info) => info.getValue(),
                meta: {
                    filterVariant: 'select',
                    className: 'col-1',
                },
                filterFn: 'equals',
            },
            {
                accessorFn: (row) => ((row.appType?.name ?? '') !== '' ? row.appType?.name : ' '),
                id: 'type',
                header: 'Type',
                cell: (info) => info.getValue(),
                meta: {
                    filterVariant: 'select',
                    className: 'col-2',
                },
                filterFn: 'equals',
            },
            {
                accessorFn: (row) => ((row.deliveryTeam?.name ?? '') !== '' ? row.deliveryTeam?.name : ' '),
                id: 'deliveryTeam',
                header: 'Delivery Team',
                cell: (info) => info.getValue(),
                meta: {
                    filterVariant: 'select',
                    className: 'col-2',
                },
                filterFn: 'equals',
            },
            {
                accessorKey: 'uniqueName',
                id: 'actions',
                header: 'Actions',
                cell: (info) => {
                    const uniqueName = info.getValue();
                    return (
                        <div>
                            {consolidation ? (
                                <ActionButton
                                    disabled={false}
                                    title={`Consolidate with ${uniqueName} as root`}
                                    outlined={false}
                                    size={1}
                                    mdiIcon={mdiSetMerge}
                                    variant={'btn-blue container-fluid'}
                                    onClick={async () => {
                                        const url = `/consolidation/${uniqueName}`;
                                        navigate(url);
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                            <ActionButton
                                disabled={false}
                                title={`Details of ${uniqueName}`}
                                outlined={false}
                                size={1}
                                mdiIcon={mdiInformation}
                                variant={'btn-dark-blue container-fluid'}
                                onClick={async () => {
                                    const url = `/applications/${uniqueName}`;
                                    navigate(url);
                                }}
                            />
                        </div>
                    );
                },
                meta: {
                    filterVariant: 'none',
                    className: 'col-1',
                },
                enableSorting: false,
            },
        ],
        [consolidation, navigate]
    );

    const initialSorting = [{ id: 'uniqueName', desc: false }] as SortingState;

    if (isLoading || dataIsLoading) {
        return <Loading />;
    }
    return <ReactTable columns={columns} initialSorting={initialSorting} tableData={applications} usePagination={true} />;
};
