import { System } from '../../../../api/models';
import { useMemo } from 'react';
import { ColumnDef, SortingState, VisibilityState } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { Loading } from '../Shared/Loading';
import { ReactTable } from '../Shared/ReactTable/ReactTable';
import { InfoLink } from '../UIExtensions/InfoLink';
import { mdiInformation } from '@mdi/js';

export interface SystemsTableProps {
    isLoading: boolean;
    data: System[];
}

export const SystemsTable = ({ isLoading, data }: SystemsTableProps) => {
    const columns = useMemo<ColumnDef<System, string>[]>(
        () => [
            {
                accessorKey: 'id',
                id: 'id',
                cell: (info) => info.getValue(),
            },
            {
                accessorKey: 'displayName',
                id: 'displayName',
                header: 'Display Name',
                cell: (info) => <Link to={`/systems/${info.row.getValue('id')}`}>{info.getValue()}</Link>,
                meta: {
                    filterVariant: 'text',
                    className: 'col-3',
                },
            },
            {
                accessorKey: 'description',
                id: 'description',
                header: 'Description',
                cell: (info) => info.getValue(),
                meta: {
                    filterVariant: 'text',
                    className: 'col-6',
                },
            },
            {
                accessorFn: (row) => row.itOwner.displayName,
                id: 'itOwner',
                header: 'IT Owner',
                cell: (info) => info.getValue(),
                meta: {
                    filterVariant: 'select',
                    className: 'col-2',
                },
                filterFn: 'equals',
            },
            {
                accessorKey: 'displayName',
                id: 'actions',
                header: 'Actions',
                cell: (info) => <InfoLink to={`/systems/${info.row.getValue('id')}`} mdiIcon={mdiInformation} title={`Details of ${info.getValue()}`} />,
                meta: {
                    filterVariant: 'none',
                    className: 'col-1',
                },
                enableSorting: false,
            },
        ],
        []
    );

    const initialSorting = [{ id: 'displayName', desc: false }] as SortingState;

    const columnsVisibility = { id: false } as VisibilityState;

    if (isLoading) {
        return <Loading />;
    }
    return <ReactTable columns={columns} columnsVisibility={columnsVisibility} initialSorting={initialSorting} tableData={data} usePagination={true} />;
};
