import { useEffect, useState } from 'react';
import { AppRegConfigFormProps } from './AppRegTypes';
import appRegistryClient from '../../../../../api/AppRegistryClient';
import { ValidatableInput } from '../../Shared/ValidatableInput';

export function WebApiConfigForm({ setCallback, setIsValid }: AppRegConfigFormProps) {
    const [identifierUri, setIdentifierUri] = useState('');

    useEffect(() => {
        setCallback(() => (appId: string, appRegId: string) => {
            return appRegistryClient.appRegistrationConfigurations.toWebapi(appId, appRegId, identifierUri);
        });
    }, [setCallback, identifierUri]);

    return (
        <>
            <ValidatableInput
                fieldName="Identifier URI:"
                value={identifierUri}
                tooltipText="Enter a globally unique URI used to identify this web API. Usually identical to your application URL."
                id="identifierUri"
                placeholder="e.g. https://beta.myapp.quad.com"
                validate={(v) => {
                    const errors = [];
                    if (!v) {
                        errors.push('Identifier URI is required');
                        setIsValid(false);
                    } else {
                        setIsValid(true);
                    }
                    return errors;
                }}
                onChange={(v) => {
                    setIdentifierUri(v);
                }}
            />
            Click <strong>Assign</strong> to configure this app registration as a <strong>web API</strong>.
        </>
    );
}
