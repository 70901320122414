import { mdiAccount, mdiAccountKey, mdiAccountMultiple, mdiAlert, mdiApplication, mdiCheck, mdiCog, mdiHelp, mdiLink, mdiMinus, mdiRegisteredTrademark, mdiUpdate } from '@mdi/js';
import { EventLog } from '../../../../api/models';
import { DateDisplay } from '../Shared/DateDisplay';
import { StringUtils } from '../Shared/StringUtils';
import { TextDisplay } from '../Shared/TextDisplay';
import { ITextWithIcon, TextWithIcon } from '../UIExtensions/Text';

interface EventLogRowProps {
    event: EventLog;
}

export const EventLogRow = (props: EventLogRowProps) => {
    const event = props.event;
    const date = new Date(event.createdTimestamp + 'Z');

    const getRowColor = (text: string) => {
        const operationType = StringUtils.substringAfterFirstSeparator('.', text);
        switch (operationType) {
            case 'created':
                return 'table-success';
            case 'deleted':
            case 'decommissioned':
                return 'table-danger';
            default:
                return '';
        }
    };

    const GetEventTypeProps = (eventType: string): ITextWithIcon => {
        const event = StringUtils.substringBeforeFirstSeparator('.', eventType);
        switch (event) {
            case 'permission':
                return {
                    text: 'Permission',
                    icon: mdiAccountMultiple,
                };
            case 'service-principal':
                return {
                    text: 'Service Principal',
                    icon: mdiAccountKey,
                };
            case 'application':
                return {
                    text: 'Application',
                    icon: mdiApplication,
                };
            case 'app-registration':
                return {
                    text: 'App Registration',
                    icon: mdiRegisteredTrademark,
                };
            case 'system':
                return {
                    text: 'System',
                    icon: mdiCog,
                };
            default:
                return {
                    text: event,
                    icon: mdiHelp,
                };
        }
    };

    const GetEventOperationProps = (eventType: string): ITextWithIcon => {
        const event = StringUtils.substringAfterFirstSeparator('.', eventType);
        switch (event) {
            case 'decommissioned':
                return {
                    text: 'Decommissioned',
                    icon: mdiAlert,
                };
            case 'created':
                return {
                    text: 'Created',
                    icon: mdiCheck,
                };
            case 'deleted':
                return {
                    text: 'Deleted',
                    icon: mdiMinus,
                };
            case 'updated':
                return {
                    text: 'Updated',
                    icon: mdiUpdate,
                };
            case 'owner.added':
                return {
                    text: 'Owner Added',
                    icon: mdiAccount,
                };
            case 'linked':
                return {
                    text: 'Linked',
                    icon: mdiLink,
                };
            default:
                return {
                    text: event,
                    icon: mdiHelp,
                };
        }
    };

    return (
        <tr className={getRowColor(event.type)}>
            <td>
                <DateDisplay date={date} />
            </td>
            <td>
                <TextWithIcon {...GetEventTypeProps(event.type)} />
            </td>
            <td title={event.correlationId}>
                <TextWithIcon {...GetEventOperationProps(event.type)} />
            </td>
            <td>
                <TextDisplay title={event.user.displayName} text={event.user.displayName} />
            </td>
            <td>
                <TextDisplay className="event-log-summary" title={event.summary} text={event.summary} />
            </td>
        </tr>
    );
};
