import { AttributeValue } from '../../../../../api/models';

export class DefaultAttributeValue implements AttributeValue {
    id: string;
    name: string;
    isActive: boolean;
    value: string;
    type: string;

    constructor(type: string) {
        this.id = '';
        this.name = '';
        this.isActive = true;
        this.value = '';
        this.type = type;
    }
}
