import { CSSProperties } from 'react';
import { Col, FormLabel, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

interface SwitchButtonProps {
    disabled?: boolean;
    checked: boolean;
    title?: string;
    label?: string;
    labelSize?: number;
    onChange: (checked: boolean) => void;
}
export const SwitchButton = ({ disabled, checked, title, label, labelSize, onChange }: SwitchButtonProps) => {
    if (label && label.length) {
        return (
            <>
                <Row>
                    <Col md={labelSize == null ? 2 : labelSize}>
                        <FormLabel className="text-right">{label}</FormLabel>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <Form.Switch className={'btn'} disabled={disabled} checked={checked} title={title} onChange={(args) => onChange(args.currentTarget.checked)} />
                    </Col>
                </Row>
            </>
        );
    }
    return <Form.Switch className={'btn'} disabled={disabled} checked={checked} title={title} onChange={(args) => onChange(args.currentTarget.checked)} />;
};
