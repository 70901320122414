import { CodeTypeSettings } from '../../../../../api/models';

interface CodeTypesTableRowProps {
    codeType: CodeTypeSettings;
    selected: boolean;
    onClick: (codeType: CodeTypeSettings) => void;
}

export const CodeTypesTableRow = ({ codeType, selected, onClick }: CodeTypesTableRowProps) => {
    const className = selected ? 'selected' : '';

    return (
        <tr className={className}>
            <td onClick={() => onClick(codeType)} style={{ paddingBottom: '13px' }}>
                <div>{codeType.name}</div>
            </td>
        </tr>
    );
};
