import { RootState } from '../../store';

export const selectUserInfo = (rootState: RootState) => {
    const themeState = rootState.theme;
    return themeState.userInfo;
};

export const selectorApplicationInfo = (rootState: RootState) => {
    const themeState = rootState.theme;
    return themeState.applicationInfo;
};
