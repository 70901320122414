import { forwardRef, useImperativeHandle, useRef } from 'react';
import { ConsolidationStepProps } from '../ConsolidationStepProps';
import { Review } from './Review';
import { ConsolidationWizardModel } from '../../ConsolidationWizard';

interface ReviewStepProps extends ConsolidationStepProps {}

export const ReviewStep = forwardRef<(() => Promise<boolean>) | undefined, ReviewStepProps>(({ formModel, setFormModel, setCurrentStatus }: ReviewStepProps, ref) => {
    const childRef = useRef<() => Promise<boolean>>();

    const setForm = (formValue: Record<string, any>) => {
        const subRequestValue = formValue as ConsolidationWizardModel;
        setFormModel(subRequestValue);
    };

    const validate = async () => {
        let isValid = false;
        if (childRef?.current) {
            isValid = await childRef.current();
        }
        if (!isValid) {
            setCurrentStatus('error');
        }
        return isValid;
    };

    useImperativeHandle(ref, () => validate);

    return <Review formModel={formModel} setFormModel={setForm} ref={childRef} />;
});
