import { PageHeading } from '@quad/bootstrap-react';
import { Container } from 'react-bootstrap';
import ScrollPageContent from './components/Shared/ScrollPageContent';
import Icon from '@mdi/react';
import { CodeTypesDetails } from './components/CodeTypes/CodeTypesDetails';

interface CodeTypesProps {
    title: string;
    mdiIcon: string;
}

export const CodeTypes = ({ title, mdiIcon }: CodeTypesProps) => {
    return (
        <ScrollPageContent>
            <PageHeading>
                <div className="col">
                    <h1>
                        <Icon path={mdiIcon} size={1.8} /> {title}
                    </h1>
                </div>
            </PageHeading>

            <Container>
                <CodeTypesDetails />
            </Container>
        </ScrollPageContent>
    );
};
