import { mdiPencil } from '@mdi/js';
import { AttributeValue, CodeType } from '../../../../../api/models';
import { CodeTypeActiveChange } from '../CodeTypeActiveChange';
import { CodeTypeDelete } from '../CodeTypeDelete';
import { AttributeValueEdit } from './AttributeValueEdit';
import { UserPrincipal } from '../../Types/UserPrincipal';
import { CodeTypePermissions } from '../../CodeTypes/CodeTypePermissions';

interface AttributeValuesTableRowProps {
    userPrincipal: UserPrincipal;
    permissionPrefix: string;
    attributeValue: AttributeValue;
    allValues: AttributeValue[];
    onEdited: (type: AttributeValue) => Promise<Map<string, string[]> | undefined>;
    onActiveChanged: (type: CodeType) => Promise<Map<string, string[]> | undefined>;
    onDelete: (type: CodeType) => Promise<Map<string, string[]> | undefined>;
}

export const AttributeValuesTableRow = ({ userPrincipal, permissionPrefix, attributeValue, allValues, onEdited, onActiveChanged, onDelete }: AttributeValuesTableRowProps) => {
    const canUpdate = userPrincipal.hasPermission(CodeTypePermissions.canUpdate(permissionPrefix));
    const canDelete = userPrincipal.hasPermission(CodeTypePermissions.canDelete(permissionPrefix));
    const canEnable = userPrincipal.hasPermission(CodeTypePermissions.canEnable(permissionPrefix));
    const canDisable = userPrincipal.hasPermission(CodeTypePermissions.canDisable(permissionPrefix));
    const activeChangeEnabled = attributeValue.isActive ? canDisable : canEnable;

    const nameClassName = attributeValue.isActive ? '' : 'line-through';
    return (
        <tr>
            <td>
                <div className={nameClassName}>{attributeValue.name}</div>
            </td>
            <td>
                <div>
                    <AttributeValueEdit
                        allValues={allValues}
                        disabled={!canUpdate}
                        mdiIcon={mdiPencil}
                        variant="btn-dark-blue"
                        title="Edit"
                        value={attributeValue}
                        onConfirm={onEdited}
                    />
                    |
                    <CodeTypeActiveChange disabled={!activeChangeEnabled} codeName="attribute-type" codeType={attributeValue} onActiveChanged={onActiveChanged} />
                    |
                    <CodeTypeDelete disabled={!canDelete} codeName="attribute-value" codeType={attributeValue} onDelete={onDelete} />
                </div>
            </td>
        </tr>
    );
};
