import { PageHeading } from '@quad/bootstrap-react';
import { Container } from 'react-bootstrap';
import ScrollPageContent from './components/Shared/ScrollPageContent';
import Icon from '@mdi/react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { getAccessToken } from '../../api';
import appConfig from '../../appConfig';

interface SwaggerProps {
    title: string;
    mdiIcon: string;
}

export const Swagger = ({ title, mdiIcon }: SwaggerProps) => {
    const requestInterceptor = async (req: any) => {
        return {
            ...req,
            headers: {
                Accept: 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json;charset=utf-8',
                Authorization: `Bearer ${await getAccessToken([appConfig.apiScope])}`,
            },
        };
    };

    const onComplete = () => {
        var authBtnContainer = document.getElementsByClassName('scheme-container');
        if (authBtnContainer.length > 0) {
            // hide the auth button
            authBtnContainer[0].setAttribute('hidden', 'true');
        }
    };

    return (
        <ScrollPageContent>
            <PageHeading>
                <div className="col">
                    <h1>
                        <Icon path={mdiIcon} size={1.8} /> {title}
                    </h1>
                </div>
            </PageHeading>

            <Container>
                <SwaggerUI
                    url={`${appConfig.apiBaseUrl}/swagger/v1/swagger.json`}
                    requestInterceptor={requestInterceptor}
                    onComplete={onComplete}
                    tryItOutEnabled={true}
                ></SwaggerUI>
            </Container>
        </ScrollPageContent>
    );
};
