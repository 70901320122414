import { useEffect, useState, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import { AppRegistration } from '../../../../api/models';
import appRegistryClient from '../../../../api/AppRegistryClient';
import { SelectOptionAppRegistration } from '../Types/SelectOption';
import { tryCallApi } from '../../../../api/Helpers';
import { ValidationErrors } from '../Shared/ValidationErrors';

interface AppRegistrationSearchProps {
    onChange: (name: string, registration: AppRegistration) => void;
    validate: (name: string, registration: AppRegistration) => string[];
    focus: boolean;
    apiErrors?: string[];
}
export function AppRegistrationSearch(props: AppRegistrationSearchProps) {
    const emptyAppRegistration: AppRegistration = {
        id: '',
        applicationId: '',
        applicationType: '',
        type: '',
        tenantId: '',
        clientId: '',
        providerUniqueId: '',
        displayName: '',
        environment: '',
    };
    const [getOptionsCall, setGetOptionsCall] = useState<any>('');
    const [errors, setErrors]: [string[], Function] = useState([]);
    const [selected, setSelected] = useState<SelectOptionAppRegistration>(new SelectOptionAppRegistration('', emptyAppRegistration));
    const select: any = useRef(null);

    const onValueChanged = async (name: string, value: AppRegistration) => {
        setSelected(new SelectOptionAppRegistration(name, value));
        const tempErrors = props.validate(name, value);
        setErrors(tempErrors);
        props.onChange(name, value);
    };

    useEffect(() => {
        if (props.focus) {
            select.current.focus();
        }
    }, [props.focus]);

    useEffect(() => {
        onValueChanged('', emptyAppRegistration);
    }, []);

    useEffect(() => {
        let errors = props.validate(selected.label, selected.value);
        if (props.apiErrors) {
            errors = errors.concat(props.apiErrors);
            setErrors(errors);
        }
    }, [props.apiErrors]);

    const apiGetOptions = async (inputValue: string): Promise<Array<SelectOptionAppRegistration> | undefined> => {
        let appRegistrations = new Array<AppRegistration>();
        if (
            inputValue &&
            inputValue.length > 2 &&
            (await tryCallApi(async () => {
                appRegistrations = await appRegistryClient.appRegistrations.getFromAzure(inputValue!);
            }))
        ) {
            const options = appRegistrations.map((appRegistration) => new SelectOptionAppRegistration(appRegistration.displayName, appRegistration));
            return options;
        }
        return undefined;
    };
    const promiseOptions = (searchString: string) =>
        new Promise<any>((resolve) => {
            clearTimeout(getOptionsCall);
            const call = setTimeout(() => {
                resolve(apiGetOptions(searchString));
            }, 500);
            setGetOptionsCall(call);
        });

    return (
        <>
            <div>App Registration*</div>
            <AsyncSelect
                className={`required ${errors.length === 0 ? '' : 'validation-error'}`}
                ref={select}
                defaultOptions
                loadOptions={promiseOptions}
                onChange={(newValue: any) => {
                    onValueChanged(newValue.label, newValue.value);
                }}
            />
            <ValidationErrors errors={errors} />
        </>
    );
}
