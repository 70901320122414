import { Link } from 'react-router-dom';

interface SystemApplicationsTableRowProps {
    uniqueName: string;
    displayName: string;
    type: string;
    state: string;
}

export function SystemApplicationsTableRow(props: SystemApplicationsTableRowProps) {
    return (
        <tr>
            <td>
                <Link to={`/applications/${props.uniqueName}`}>{props.uniqueName}</Link>
            </td>
            <td>{props.displayName}</td>
            <td>{props.type}</td>
            <td>{props.state}</td>
        </tr>
    );
}
