import { BadRequestApiError } from './models';

export const tryCallApi = async (funct: Function): Promise<boolean> => {
    try {
        await funct();
        return true;
    } catch {
        return false;
    }
};

export async function tryCallApiCatchErrors<T>(funct: () => Promise<T>, out: (result?: T) => void): Promise<Map<string, string[]> | undefined> {
    try {
        out(await funct());
        return undefined;
    } catch (error) {
        return getApiErrors(error);
    }
}

export const toFirstLower = (input: string): string => {
    if (!input || input.length === 0) {
        return input;
    }
    const first = input[0];
    const lower = first.toLowerCase();
    const result = `${lower}${input.substring(1)}`;
    return result;
};

export const getApiErrors = (error: unknown): Map<string, string[]> => {
    let result = new Map<string, string[]>();
    const badRequestApiError = error as BadRequestApiError;
    if (!badRequestApiError || badRequestApiError.status !== 400) {
        return result;
    }
    const internalErrors = badRequestApiError.error.errors;
    internalErrors.forEach((errors: string[], fieldName: string) => {
        if (errors) {
            let propertyName = toFirstLower(fieldName);
            result.set(propertyName, errors);
        }
    });
    return result;
};
