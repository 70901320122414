import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { mdiAccountGroup, mdiApplication, mdiCodeJson, mdiCog, mdiFormatListBulletedType, mdiHome, mdiTagMultiple } from '@mdi/js';
import Icon from '@mdi/react';
import {
    EnvironmentBanner,
    LayoutContext,
    LoadingOverlay,
    SiteNavExpandCollapseSection,
    SiteNavSection,
    StandardLayout,
    TopNav,
    TopNavDropdownMenuSignoutAnchor,
    TopNavQuadSiteLogo,
    TopNavUserMenu,
    TopNavUserMenuIcon,
    useDefaultLayoutContext,
} from '@quad/bootstrap-react';
import { DefaultSiteNav, SiteNavLinkItem } from '@quad/bootstrap-react.react-router';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { msGraphClient } from '../../api';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import * as themeActions from '../../store/theme/actions';
import * as themeSelectors from '../../store/theme/selectors';
import Home from './Home';
import { Applications } from './Applications';
import { ApplicationDetails } from './components/Applications/ApplicationDetails';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Attributes } from './Attributes';
import { ApplicationCreatePage } from './components/Applications/ApplicationCreatePage';
import { CodeTypes } from './CodeTypes';
import { Departments } from './Departments';
import { Systems } from './Systems';
import { SystemCreatePage } from './components/Systems/SystemCreatePage';
import { SystemDetails } from './components/Systems/SystemDetails';
import { Swagger } from './Swagger';
import { ConsolidationWizard } from './components/Consolidation/ConsolidationWizard';

const SiteNavItemIcon = ({ path }: { path: string }) => <Icon size="1.5em" path={path} />;

const AppLoad = () => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(themeSelectors.selectUserInfo);
    const applicationInfo = useAppSelector(themeSelectors.selectorApplicationInfo);

    useEffect(() => {
        themeActions.loadUserInfo().then((action) => dispatch(action));
        themeActions.loadApplicationInfo().then((action) => dispatch(action));
    }, [dispatch]);

    if (!userInfo || !applicationInfo) {
        return <LoadingOverlay />;
    }

    return <AppRender />;
};

const SignOut = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, inProgress } = useMsal();

    if (inProgress === InteractionStatus.None && isAuthenticated) {
        instance
            .logoutRedirect({
                postLogoutRedirectUri: '/',
            })
            .catch((e) => {
                console.error(e);
            });
    }

    return <LoadingOverlay />;
};

const AppRender = () => {
    const layoutContext = useDefaultLayoutContext();
    const userInfo = useAppSelector(themeSelectors.selectUserInfo)!;
    const applicationInfo = useAppSelector(themeSelectors.selectorApplicationInfo)!;

    const [imageUrl, setImageUrl] = useState<string>();

    useEffect(() => {
        msGraphClient.getMyPhoto().then((response) => {
            response.blob().then((data) => {
                const reader = new FileReader();
                reader.readAsDataURL(data);
                reader.onload = () => {
                    const base64data = reader.result;

                    if (typeof base64data === 'string') {
                        setImageUrl(base64data);
                    }
                };
            });
        });
    }, []);

    const topNav = (
        <TopNav
            siteLogo={<TopNavQuadSiteLogo />}
            userMenu={
                <TopNavUserMenu
                    displayName={userInfo.displayName}
                    icon={<TopNavUserMenuIcon src={imageUrl} />}
                    signout={<TopNavDropdownMenuSignoutAnchor anchorProps={{ href: '/signout' }} />}
                />
            }
        />
    );

    const siteNav = (
        <DefaultSiteNav>
            <SiteNavSection>
                <SiteNavLinkItem icon={<SiteNavItemIcon path={mdiHome} />} label="Home" to="/home" />
                <SiteNavLinkItem icon={<SiteNavItemIcon path={mdiApplication} />} label="Applications" to="/applications" />
                <SiteNavLinkItem icon={<SiteNavItemIcon path={mdiTagMultiple} />} label="Attributes" to="/attributes" />
                <SiteNavLinkItem icon={<SiteNavItemIcon path={mdiFormatListBulletedType} />} label="Code Types" to="/code-types" />
                <SiteNavLinkItem icon={<SiteNavItemIcon path={mdiAccountGroup} />} label="Departments" to="/departments" />
                <SiteNavLinkItem icon={<SiteNavItemIcon path={mdiCog} />} label="Systems" to="/systems" />
                <SiteNavLinkItem icon={<SiteNavItemIcon path={mdiCodeJson} />} label="Swagger" to="/swagger" />
            </SiteNavSection>
            <SiteNavExpandCollapseSection />
        </DefaultSiteNav>
    );

    return (
        <>
            {/* <I18nContext.Provider value={{ signOut: 'Sign Out', copyright: 'Copyright', termsOfUse: 'Terms Of Use', privacyPolicy: 'Privacy Policy', version: 'Version' }}> */}
            <LayoutContext.Provider value={layoutContext}>
                <EnvironmentBanner environment={applicationInfo.environment} />
                <StandardLayout topNav={topNav} siteNav={siteNav}>
                    <Routes>
                        <Route path="home" element={<Home />} />
                        <Route path="applications/*">
                            <Route index element={<Applications title="Applications" mdiIcon={mdiApplication} />} />
                            <Route path="new" element={<ApplicationCreatePage />} />
                            <Route path=":uniqueName" element={<ApplicationDetails />} />
                        </Route>
                        <Route path="departments/*">
                            <Route index element={<Departments title="Departments & Teams" mdiIcon={mdiAccountGroup} />} />
                        </Route>
                        <Route path="attributes/*">
                            <Route index element={<Attributes title="Attributes" mdiIcon={mdiTagMultiple} />} />
                        </Route>
                        <Route path="code-types/*">
                            <Route index element={<CodeTypes title="Code Types" mdiIcon={mdiFormatListBulletedType} />} />
                        </Route>
                        <Route path="systems/*">
                            <Route index element={<Systems title="Systems" mdiIcon={mdiCog} />} />
                            <Route path="new" element={<SystemCreatePage />} />
                            <Route path=":id" element={<SystemDetails />} />
                        </Route>
                        <Route path="swagger/*">
                            <Route index element={<Swagger title="Swagger" mdiIcon={mdiCodeJson} />} />
                        </Route>
                        <Route path="consolidation/*">
                            <Route path=":uniqueName" element={<ConsolidationWizard />} />
                        </Route>
                        <Route path="signout" element={<SignOut />} />
                        <Route path="*" element={<Navigate to="/home" />} />
                    </Routes>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </StandardLayout>
            </LayoutContext.Provider>
            {/* </I18nContext.Provider> */}
        </>
    );
};

export default AppLoad;
