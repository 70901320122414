import { PublicClientApplication } from '@azure/msal-browser';
import { combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { msalConfig } from './appConfig';
import rootReducer from './reducers';
import { Action } from './store/shared/models';
import { ThemeState } from './store/theme/models';
import { UserPrincipalState } from './store/theme/models';

const combinedReducers = combineReducers({
    ...rootReducer,
});

const store = configureStore({
    reducer: combinedReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production',
});

export interface RootState {
    theme: ThemeState;
    userPrincipal: UserPrincipalState;
}

export type AppDispatch = typeof store.dispatch;
export type AppThunkAction<TPayload = any> = ThunkAction<Promise<void>, RootState, unknown, Action<TPayload>>;
const msalInstance = new PublicClientApplication(msalConfig);

export { store, msalInstance };
