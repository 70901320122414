import { useEffect } from 'react';
import appRegistryClient from '../../../../../api/AppRegistryClient';
import { AppRegConfigFormProps } from './AppRegTypes';

export function ClientConfigForm({ setCallback, setIsValid }: AppRegConfigFormProps) {
    useEffect(() => {
        setCallback(() => (appId: string, appRegId: string) => {
            return appRegistryClient.appRegistrationConfigurations.toClient(appId, appRegId);
        });
        setIsValid(true);
    }, [setCallback, setIsValid]);

    return (
        <>
            Click <strong>Assign</strong> to configure this app registration as a client application.
        </>
    );
}
