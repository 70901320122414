import { useEffect, useMemo, useState } from 'react';
import Select, { MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ValidationErrors } from './ValidationErrors';
import { SelectOption } from '../Types/SelectOption';

interface ValidatableMultiSelectProps<T> {
    disabled: boolean;
    label: string;
    options: SelectOption<T>[];
    selectedValues: T[];
    externalErrors?: string[];
    creatable?: boolean;
    validate?: (values: T[]) => string[];
    onChange: (values: T[]) => void;
}
export function ValidatableMultiSelect<T>({ disabled, label, options, selectedValues, externalErrors, creatable, validate, onChange }: ValidatableMultiSelectProps<T>) {
    const [errors, setErrors] = useState(new Array<string>());

    const onChangeInternal = async function (values: T[]) {
        if (validate) {
            setErrors(validate(values));
        }
        onChange(values);
    };

    const selectedOptions = useMemo(() => {
        return options.filter((option: SelectOption<T>) => selectedValues.indexOf(option.value) !== -1);
    }, [options, selectedValues]);

    useEffect(() => {
        if (externalErrors) {
            setErrors(externalErrors);
        }
    }, [externalErrors]);

    const className = `required ${errors.length === 0 ? '' : 'validation-error'}`;
    const SelectComponent = creatable ? CreatableSelect : Select;
    return (
        <>
            <div className="form-group">
                <label>{label}</label>
                <SelectComponent
                    isDisabled={disabled}
                    isMulti={true}
                    className={className}
                    options={options}
                    onChange={async (values: MultiValue<SelectOption<T>>) => {
                        var selectedValues = values.map((item: SelectOption<T>) => {
                            return item.value;
                        });

                        await onChangeInternal(selectedValues);
                    }}
                    value={selectedOptions}
                />
                <ValidationErrors errors={errors} />
            </div>
        </>
    );
}
