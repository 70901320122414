import { useCallback, useEffect, useState } from 'react';
import appRegistryClient from '../../../../api/AppRegistryClient';
import { tryCallApi } from '../../../../api/Helpers';
import { CreateSystemRequest, System } from '../../../../api/models';
import { useNavigate } from 'react-router';
import { UserPrincipal } from '../Types/UserPrincipal';
import { Permission } from '../Permissions/permission';
import SystemEdit from './SystemEdit';
import AppRegistryClient from '../../../../api/AppRegistryClient';
import { DefaultSystem } from './DefaultSystem';
import { Loading } from '../Shared/Loading';

function SystemCreate() {
    const [isLoading, setIsLoading] = useState(true);
    const [userPrincipal, setUserPrincipal] = useState<UserPrincipal>(new UserPrincipal());
    const getUserPermissions = async () => {
        var tempUserPermissions = await AppRegistryClient.userPermissions.getRootPermissions();
        var user = new UserPrincipal(tempUserPermissions);
        setUserPrincipal(user);
    };

    const createEnabled = userPrincipal.hasPermission(Permission.System_Create);

    const sys = new DefaultSystem();

    const navigate = useNavigate();
    const createHandler = async (system: System, systemOwnerId: string) => {
        const request = {
            displayName: system.displayName,
            description: system.description,
            itOwnerId: system.itOwner.id,
            owners: [systemOwnerId],
        } as CreateSystemRequest;

        await tryCallApi(async () => {
            await appRegistryClient.systems.post(request);
            const url = `/systems/${system.id}`;
            navigate(url);
        });
    };

    const getAllData = useCallback(async () => {
        await getUserPermissions();

        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (isLoading) {
            getAllData();
        }
    }, [getAllData, isLoading]);

    if (isLoading) {
        return <Loading />;
    }

    if (!createEnabled) {
        return <>You are not authorized to perform system creation.</>;
    }
    return (
        <div>
            <SystemEdit
                isCreateMode={true}
                sys={sys}
                onCancel={() => {
                    const url = `/systems`;
                    navigate(url);
                }}
                saveHandler={createHandler}
            />
        </div>
    );
}

export default SystemCreate;
