import { Table } from 'react-bootstrap';
import { CodeTypeSettings } from '../../../../../api/models';
import { useCallback, useMemo, useState } from 'react';
import { UserPrincipal } from '../../Types/UserPrincipal';
import { Filter } from '../../Shared/Filter';
import { CodeTypeSort } from '../../Attributes/CodeTypeSort';
import { CodeTypesTableRow } from './CodeTypesTableRow';

export interface CodeTypesTableProps {
    userPrincipal: UserPrincipal;
    codeTypes: CodeTypeSettings[];
    selectedType: CodeTypeSettings;
    onSelectedChanged: (type: CodeTypeSettings) => void;
}

export const CodeTypesTable = ({ codeTypes, selectedType, onSelectedChanged }: CodeTypesTableProps) => {
    const [filter, setFilter] = useState('');

    const onSelectedTypeChanged = useCallback(
        (selectedType: CodeTypeSettings) => {
            onSelectedChanged(selectedType);
        },
        [onSelectedChanged]
    );

    const sortedAttributeTypes = useMemo(() => {
        return codeTypes.filter((codeType) => codeType.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())).sort(CodeTypeSort);
    }, [codeTypes, filter]);

    return (
        <>
            <Filter onUpdate={setFilter} />
            <div className="table-wrapper">
                <Table striped={true} className="table-fixed-header table-selectable">
                    <thead className="thead-white">
                        <tr>
                            <th scope="col" className="col-9" style={{ paddingBottom: '10px' }}>
                                Types
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedAttributeTypes?.map(function (d) {
                            return <CodeTypesTableRow key={'code-type-' + d.id} codeType={d} onClick={onSelectedTypeChanged} selected={d.id === selectedType.id} />;
                        })}
                    </tbody>
                </Table>
            </div>
        </>
    );
};
