import { Card, Container } from 'react-bootstrap';
import BasePage from '../BasePage';
import ApplicationCreate from './ApplicationCreate';

export const ApplicationCreatePage = () => (
    <BasePage name={`Create Application`}>
        <Container>
            <Card>
                <Card.Body>
                    <ApplicationCreate />
                </Card.Body>
            </Card>
        </Container>
    </BasePage>
);
