import { useState, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Loading } from '../Shared/Loading';
import { ApplicationEndpoint, EnvironmentType } from '../../../../api/models';
import { Filter } from '../Shared/Filter';
import { EndpointsTable } from './EndpointsTable';
import { EndpointsCreateEditModal } from './EndpointsCreateEditModal';

export interface EndpointsEnhancedTableProps {
    applicationId?: string;
    data: ApplicationEndpoint[];
    canPerformActions: boolean;
    isLoading: boolean;
    availableEnvironmentTypes?: Array<EnvironmentType>;
    deleteHandler: (applicationId: string, endpointId: string) => Promise<boolean>;
    createHandler: (applicationId: string, environment: string, endpoint: string) => Promise<boolean>;
    updateHandler: (applicationId: string, environment: string, endpoint: string, endpointId?: string) => Promise<boolean>;
}

export function EndpointsEnhancedTable({
    applicationId,
    data,
    canPerformActions,
    isLoading,
    availableEnvironmentTypes,
    deleteHandler,
    createHandler,
    updateHandler,
}: EndpointsEnhancedTableProps) {
    const [searchTerm, setSearchTerm] = useState('');

    const searchedData = useMemo(() => {
        const searchString = searchTerm.trim().toLowerCase();

        if (searchString.length === 0) {
            return data;
        }

        return data.filter((endpoint: ApplicationEndpoint) => {
            return JSON.stringify(endpoint).toLowerCase().includes(searchString);
        });
    }, [searchTerm, data]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div>
            <Row>
                <Col>{<Filter onUpdate={setSearchTerm} />}</Col>
                <Col className="col-1">
                    <EndpointsCreateEditModal
                        applicationId={applicationId!}
                        availableEnvironmentTypes={availableEnvironmentTypes}
                        handler={createHandler}
                        canPerformActions={canPerformActions}
                    />
                </Col>
            </Row>
            <EndpointsTable
                applicationId={applicationId!}
                data={searchedData}
                availableEnvironmentTypes={availableEnvironmentTypes}
                deleteHandler={deleteHandler}
                updateHandler={updateHandler}
                actionsEnabled={canPerformActions}
                actionsVisible={canPerformActions}
            />
        </div>
    );
}
