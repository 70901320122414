import { AttributeValue } from '../../../../../api/models';
import { ActionButton } from '../../UIExtensions/Buttons';
import { AttributeValueModal } from './AttributeValueModal';
import { useState } from 'react';
import { DefaultAttributeValue } from './DefaultAttributeValue';

export interface AttributeValueEditProps {
    title: string;
    mdiIcon: string;
    variant: string;
    disabled: boolean;
    value?: AttributeValue;
    allValues: AttributeValue[];
    type?: string;
    onConfirm: (item: AttributeValue) => Promise<Map<string, string[]> | undefined>;
}

export const AttributeValueEdit = ({ title, mdiIcon, variant, disabled, value, allValues, type, onConfirm }: AttributeValueEditProps) => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [apiErrors, setApiErrors] = useState<Map<string, string[]>>();

    const onConfirmInternal = async (value: AttributeValue) => {
        let errors = await onConfirm(value);
        if (!errors) {
            setShowEditModal(false);
        } else {
            setApiErrors(errors);
        }
    };

    return (
        <>
            <ActionButton
                disabled={disabled}
                mdiIcon={mdiIcon}
                variant={variant}
                title={title}
                onClick={() => {
                    setShowEditModal(true);
                }}
            />
            <AttributeValueModal
                show={showEditModal}
                onConfirm={onConfirmInternal}
                onHide={() => {
                    setApiErrors(undefined);
                    setShowEditModal(false);
                }}
                attribValue={value ?? new DefaultAttributeValue(type!)}
                allValues={allValues}
                apiErrors={apiErrors}
            />
        </>
    );
};
